import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, readCookie, successMsg } from "./customFn";

export const setCartCount = (data) => {
  return {
    type: "SET_CART_COUNT",
    payload: data,
  };
};

//   ===================API call for add to cart starts=================
export const addToCart = (
  avilableOptionObj,
  languageReducer,
  navigate,
  totalAmount,
  cartCount,
  isBookNow,
  setAvailabeOptionFlag,
  setButtonDisable,
  setIsAvailable,
  setFilterLoading,
  setNotAvailableOptionIDs,
  setTimeSlotAvailableMSG
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        ...avilableOptionObj,
        totalAmount: totalAmount,
        language: languageReducer.id,
        currency: currency.id,
        token: token,
      };

      const response = await axios.post("add-to-cart", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
        dispatch(setCartCount(cartCount + 1));
     
       
        setAvailabeOptionFlag((status) => !status); // To call available option API after successfull add to cart
        setButtonDisable(false);
        if (isBookNow === true) {
          navigate("/checkout");
        }else{ 
          // navigate("/cart");
          navigate("/cart-details");
          
        }
      } else {
        if (response.data.not_available == undefined) {
          setIsAvailable(true);
          setNotAvailableOptionIDs((prevIDs) =>
            prevIDs.filter((id) => id !== avilableOptionObj.option_id)
          );
        } else {
          setNotAvailableOptionIDs((prevIds) => [
            ...prevIds,
            avilableOptionObj.option_id,
          ]);
          setIsAvailable(false);
        }
        setFilterLoading(false);
        // errorMsg(response.data.message);
        setButtonDisable(false);
        setTimeSlotAvailableMSG({
          option_id: avilableOptionObj.option_id,
          message: response.data.message,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
      setButtonDisable(false);
    }
  };
};
// ===================API call for add to cart ends===================

//   ===================API call to get cart items starts=================
export const getCartData = (
  languageReducer,
  setCartData,
  navigate,
  setRecordStatus,
  setCartLoader,
  coupon = ""
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        // coupon:'PRO123'
        coupon: coupon,
        affilliate_code: readCookie("affilliate_code"),
      };

      const response = await axios.post("cart-list", params);
      if (response.data.status === true) {
        setCartData(response.data.data);
        dispatch(setCartCount(response.data.data.detail.length));

        // if (setMetaData !== '') {
        //     setMetaData(response.data.data.meta_data)
        // }
        setRecordStatus(true);
        if (setCartLoader) {
          setTimeout(() => {
            setCartLoader(false);
          }, 1000);
        }
      } else {
        // navigate("/cart");
        setCartData({});
        dispatch(setCartCount(0));
        setRecordStatus(false);
        if (setCartLoader) {
          setTimeout(() => {
            setCartLoader(false);
          }, 1000);
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        // errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //     setCartLoader(false);
    // }, 1000);
  };
};
// ===================API call to get cart items ends===================

//   ===================API Remove item starts=================
export const removeCartItem = (
  languageReducer,
  setCartData,
  navigate,
  setCartLoader,
  setRecordStatus,
  id,
  setIsRemoved
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        cart_id: id,
        language: languageReducer.id,
        currency: currency.id,
        token: token,
      };

      const response = await axios.post("delete-cart", params);
      if (response.data.status === true) {
        // setCartData(response.data.data);
        // setCartData((current) => current.filter((data) => data.id !== id));
        setIsRemoved((state) => !state);
        setRecordStatus(true);
        successMsg(response.data.message);
        setTimeout(function () {
          setCartLoader(true);
        }, 1000);
      } else {
        setRecordStatus(false);
        setIsRemoved((state) => !state);
        setTimeout(function () {
          setCartLoader(true);
        }, 1000);
        // errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(function () {
    //     setCartLoader(true);
    // }, 1000);
  };
};
// ===================API call Remove items ends===================

//   ===================API call to get cart items starts=================
export const checkProduct = async (languageReducer, navigate, dispatch) => {
  // return async (dispatch) => {
  try {
    const currency = JSON.parse(localStorage.getItem("currency"));
    const token = localStorage.getItem("_token");
    const params = {
      user_id: localStorage.getItem("userId"),
      password: localStorage.getItem("password"),
      language: languageReducer.id,
      currency: currency.id,
      token: token,
    };

    const response = await axios.post("check-product", params);
    if (response.data.status === true) {
      return response;
    } else {
      // navigate("/cart", { state: { alertMessage: response.data.message } });
      return response;
    }
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(getLogout(navigate));
      errorMsg("Somthing went wrong...");
    } else {
      errorMsg(error.response.data.message);
    }
  }
  // setTimeout(() => {
  //     setCartLoader(false);
  // }, 1000);
  // };
};
// ===================API call to get cart items ends===================

//   ===================API call to reservation time ends=================
export const reservationTimeEndsAPI = (
  cart_id,
  languageReducer,
  navigate,
  setToggle
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        cart_id: cart_id,
      };

      const response = await axios.post("remove-reserve-product", params);
      if (response.data.status === true) {
        setToggle((status) => !status);
        // if (setMetaData !== '') {
        //     setMetaData(response.data.data.meta_data)
        // }
      } else {
        // navigate("/cart");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //     setCartLoader(false);
    // }, 1000);
  };
};
