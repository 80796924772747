import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import { profileData } from "../../actions/profileAction";
import { MetaData, errorMsg, successMsg } from "../../actions/customFn";
import validator from "validator";
import { getStateList } from "../../actions/stateAction";
import { getCityList } from "../../actions/cityAction";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { images } from "../../actions/customFn";
import LoadingBar from "../Loading/LoadingBar";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import ImageCrop from "../ImageCrop/ImageCrop";
import ImageCropProvider from "../ImageCrop/providers/ImageCropProvider";

const ProfileProvider = () => {
  const { t } = useTranslation();
  const profileReducer = useSelector((state) => state.profileReducer);
  const phoneCodeReducer = useSelector((state) => state.phoneCodeReducer);
  const languageReducer = useSelector((state) => state.languageReducer);
  const homeData = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [countryFlag, setCountryFlag] = useState("");
  const [myProfile, setMyProfile] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    image: "",
    country: "",
    state: "",
    city: "",
    address: "",
    zipcode: "",
    change_password: 0,
    old_password: "",
    new_password: "",
    confirm_password: "",
    phone_code: "",
    paid_amount_days_type: "",
  });
  const [phoneCodeList, setPhoneCodeList] = useState("");
  const [formErrors, setFormError] = useState({});
  const errors = {};
  const [taxMessage, setTextMessage] = useState("Tax of €15 will be charged"); //Need to make dynamic later
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  // console.log(new Date())

  useEffect(() => {
    setIsLoading(true);
    if (profileReducer !== "") {
      setMyProfile({
        first_name: profileReducer.first_name,
        last_name: profileReducer.last_name,
        phone_number: profileReducer.phone_number,
        email: profileReducer.email,
        country: profileReducer.country,
        state: profileReducer.state,
        city: profileReducer.city,
        address: profileReducer.address,
        zipcode: profileReducer.zipcode,
        image: profileReducer.image,
        phone_code: profileReducer.phone_code,
        paid_amount_days_type: parseInt(profileReducer.paid_amount_days_type),
        change_password: 0,
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
      setProfileImage(profileReducer.image);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [profileReducer]);

  const changePassword = (e) => {
    const { checked } = e.target;
    setMyProfile((prev) => ({
      ...prev,
      change_password: checked === true ? 1 : 0,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyNumbers = /^[0-9]*$/;
    // const zipcodePattern = /^[a-d]*$/
    const zipcodePattern = /^[0-9-]*$/;

    if (name === "image") {
      setMyProfile((prev) => ({ ...prev, [name]: e.target.files[0] }));
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    } else if (name === "phone_number") {
      if (onlyNumbers.test(value) === true) {
        setMyProfile((prev) => ({ ...prev, [name]: value }));
      }
    } else if (name === "zipcode") {
      if (zipcodePattern.test(value) === true) {
        setMyProfile((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setMyProfile((prev) => ({ ...prev, [name]: value }));
    }
  };

  const updateProfile = async () => {
    try {
      validateFormData();
      if (Object.keys(errors).length === 0) {
        const formdata = new FormData();
        const currency = JSON.parse(localStorage.getItem("currency"));

        formdata.append("user_id", localStorage.getItem("userId"));
        formdata.append("password", localStorage.getItem("password"));
        formdata.append("language", languageReducer.id);
        formdata.append("currency", currency.id);
        formdata.append("token", localStorage.getItem("_token"));
        formdata.append("first_name", myProfile.first_name);
        formdata.append("last_name", myProfile.last_name);
        formdata.append("phone_number", myProfile.phone_number);
        formdata.append("country", myProfile.country);
        formdata.append("state", myProfile.state);
        formdata.append("city", myProfile.city);
        formdata.append("zipcode", myProfile.zipcode);
        formdata.append("address", myProfile.address);
        formdata.append("change_password", myProfile.change_password);
        formdata.append("old_password", myProfile.old_password);
        formdata.append("new_password", myProfile.new_password);
        formdata.append("confirm_password", myProfile.confirm_password);
        formdata.append("image", myProfile.image);
        formdata.append("phone_code", myProfile.phone_code);
        formdata.append(
          "paid_amount_days_type",
          myProfile.paid_amount_days_type
        );

        const response = await axios.post("/user-profile-update", formdata);
        if (response.data.status === true) {
          localStorage.setItem("userId", response.data.user_id);
          localStorage.setItem("password", response.data.password);
          dispatch(profileData(languageReducer, navigate));
          successMsg(response.data.message);
          setMyProfile((prev) => ({ ...prev, change_password: 0 }));
        } else {
          errorMsg(response.data.message);
        }
      }
    } catch (error) {
      errorMsg(error.response.data.message);
      console.log(error);
    }
  };

  //=================Form Validation starts===============
  const validateFormData = () => {
    if (myProfile.first_name === "") {
      errors.first_name = "Enter first name";
    }

    if (myProfile.last_name === "") {
      errors.last_name = "Enter last name";
    }

    if (myProfile.phone_code === "") {
      errors.phone_code = "Select Phone Code";
    }

    if (myProfile.zipcode === "") {
      errors.zipcode = "Enter zipcode";
    }

    if (myProfile.phone_number === "") {
      errors.phone_number = "Enter phone number";
    } else if (!validator.isNumeric(myProfile.phone_number)) {
      errors.phone_number = "Phone number must be numeric";
    } else if (
      myProfile.phone_number.length < 7 ||
      myProfile.phone_number.length > 15
    ) {
      errors.phone_number = "Phone no must be of 6 to 15 digit";
    }

    if (myProfile.country === "") {
      errors.country = "Select country";
    }
    if (myProfile.state === "") {
      errors.state = "Select state";
    }
    if (myProfile.city === "") {
      errors.city = "Select city";
    }

    if (myProfile.change_password === 1) {
      if (myProfile.old_password === "") {
        errors.old_password = "Enter old Password";
      }

      if (myProfile.new_password === "") {
        errors.new_password = "Enter new Password";
      } else if (myProfile.new_password.length < 8) {
        errors.new_password = "Password has atleast 8 charcter";
      }

      if (myProfile.confirm_password === "") {
        errors.confirm_password = "Enter confirm Password";
      } else if (myProfile.confirm_password.length < 8) {
        errors.confirm_password = "Password has atleast 8 charcter";
      }

      if (myProfile.new_password !== myProfile.confirm_password) {
        errors.confirm_password = "Confirm Password does not match";
      }
    }
    setFormError(errors);
  };
  //=================Form Validation ends===============

  useEffect(() => {
    setCountryList(homeData.countries);
    if (myProfile.country !== "") {
      dispatch(getStateList(setStateList, myProfile.country));
    }
  }, [homeData, myProfile.country]);

  useEffect(() => {
    if (myProfile.state !== "") {
      dispatch(getCityList(setCityList, myProfile.state));
    }
  }, [myProfile.state]);

  const handleCountry = (event, data) => {
    if (data) {
      const { id } = data;
      setMyProfile((prev) => ({ ...prev, country: id }));
      setMyProfile((prev) => ({ ...prev, state: "" }));
      setMyProfile((prev) => ({ ...prev, city: "" }));
      // setCityList([]);
      dispatch(getStateList(setStateList, id));
    }
  };

  const handleState = (event, data) => {
    if (data) {
      const { id } = data;
      setMyProfile((prev) => ({ ...prev, state: id }));
      setMyProfile((prev) => ({ ...prev, city: "" }));
      dispatch(getCityList(setCityList, id));
    }
  };

  const handleCity = (event, data) => {
    if (data) {
      const { id } = data;
      setMyProfile((prev) => ({ ...prev, city: id }));
    }
  };

  // ============PhoneCode code starts===========
  useEffect(() => {
    if (phoneCodeReducer) {
      setPhoneCodeList(phoneCodeReducer);
    }
  }, [phoneCodeReducer]);

  // ===============Setting country flag image on initial load starts=============
  useEffect(() => {
    if (myProfile.phone_code && phoneCodeList && phoneCodeList.length > 0) {
      setFlag();
    }
  }, [myProfile.phone_code, phoneCodeList]);

  const setFlag = () => {
    if (phoneCodeList) {
      let object = phoneCodeList.find(
        (option) => option.code == myProfile.phone_code
      );
      setCountryFlag(object.flag);
    }
  };
  // ===============Setting country flag image on initial load ends==============

  const handlePhoneCode = (event, data) => {
    if (data) {
      const { code } = data;
      setMyProfile((prev) => ({ ...prev, phone_code: code }));
      setCountryFlag(data.flag);
    } else {
      setCountryFlag("");
      setMyProfile((prev) => ({ ...prev, phone_code: "" }));
    }
  };

  // ============PhoneCode code ends===========

  const amountPaidOption = [
    { label: "1 Month", value: 1 },
    { label: "15th of month", value: 0 },
  ];

  const handleAmountPaidOption = (e, item, key) => {
    if (item) {
      setMyProfile((prev) => ({ ...prev, paid_amount_days_type: item.value }));
    } else {
      setMyProfile((prev) => ({ ...prev, paid_amount_days_type: "" }));
    }
  };

  let metaData = {
    title: t("_my_account"),
  };

  return (
    <>
      {metaData && <MetaData data={metaData} />}
      <div className="profile-provider-main">
        <div className="text_banner">
          <span>{t("_my_account")}</span>
        </div>
        {isLoading === true ? (
          <div className="blinking-bar-loader">
            <LoadingBar />
          </div>
        ) : (
          <div className="user_edit_main">
            <div className="input_all_edit">
              <div className="User_sidebar_main User_sidebar_main-new">
                <div className="pro_img">
                  <div className="pro_inner_img">
                    {" "}
                    {/* <img src={profileImage} alt="" />
                    <button
                      className="btn_image_change"
                      onClick={() => inputFile.current.click()}
                    >
                      <img src={images["changeimage.png"]} alt="" />{" "}
                    </button>
                    <input
                      type="file"
                      name="image"
                      id="image"
                      ref={inputFile}
                      className="d-none"
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <ImageCropProvider>
                      <ImageCrop
                        profileImage={profileImage}
                        setProfileImage={setProfileImage}
                        setMyProfile={setMyProfile}
                      />
                    </ImageCropProvider>
                  </div>
                </div>
                <hr className="btm_hr" />
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label className="label_all mb-2">{t("_first_name")} </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={myProfile.first_name}
                    placeholder={t("_enter_first_name")}
                    // className="form-control"
                    className={
                      `form-control  ` +
                      (formErrors.first_name ? " is-invalid" : "")
                    }
                    onChange={handleChange}
                  />
                  {formErrors.first_name && (
                    <div className="invalid-feedback">
                      {formErrors.first_name}
                    </div>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                  <label className="label_all mb-2">{t("_last_name")} </label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={myProfile.last_name}
                    placeholder={t("_enter_last_name")}
                    // className="form-control"
                    className={
                      `form-control ` +
                      (formErrors.last_name ? " is-invalid" : "")
                    }
                    onChange={handleChange}
                  />
                  {formErrors.last_name && (
                    <div className="invalid-feedback">
                      {formErrors.last_name}
                    </div>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label className="label_all mb-2">{t("_email")}</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    readOnly
                    value={myProfile.email}
                    placeholder={t("_enter_email")}
                    // className={
                    //   `form-control ` +
                    //   (formErrors.email ? " is-invalid" : "")
                    // }
                    // onChange={handleChange}
                  />
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label className="label_all mb-2">
                        {t("_country_code")}
                      </label>
                      {/* <Select
                          sx={{
                            width: "100%",
                          }}
                          className="new_filter_select"
                          id="phone_code"
                          name="phone_code"
                          placeholder="Select Phone Code"
                          displayEmpty
                          defaultValue={parseInt(myProfile.phone_code)}
                          onChange={handleChange}
                          
                        >
                          {
                            phoneCodeList.length > 0 &&
                            phoneCodeList.map((value, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={value.code}
                                >
                                  <img src={value.flag} style={{ width: 25 }} alt="" />{" "} +
                                  {value.code} {" "}
                                  {value.label}
                                </MenuItem>
                              )
                            })
                          }
                        </Select> */}
                      {phoneCodeList && (
                        <Autocomplete
                          id="phone_code"
                          name="phone_code"
                          className="new_filter_select"
                          sx={{ width: 200 }}
                          options={phoneCodeList}
                          onChange={(event, data) =>
                            handlePhoneCode(event, data)
                          }
                          autoHighlight
                          getOptionLabel={(option) =>
                            option.code + " " + option.label
                          }
                          value={phoneCodeList.find(
                            (obj) => obj.code == parseInt(myProfile.phone_code)
                          )}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                src={option.flag}
                                style={{ width: 25 }}
                                alt=""
                              />
                              {option.label} +({option.code})
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t("_select_country_code")}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <>
                                    {countryFlag && (
                                      <img
                                        src={countryFlag}
                                        alt=""
                                        style={{ width: 25 }}
                                      />
                                    )}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                      {formErrors.phone_code && (
                        <div className="invalid-feedback">
                          {formErrors.phone_code}
                        </div>
                      )}
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label className="label_all mb-2">
                        {t("_phone_number")}
                      </label>
                      <input
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        value={myProfile.phone_number}
                        placeholder={t("_enter_phone_number")}
                        // className="form-control"
                        className={
                          `form-control ` +
                          (formErrors.phone_number ? " is-invalid" : "")
                        }
                        onChange={handleChange}
                      />
                      {formErrors.phone_number && (
                        <div className="invalid-feedback">
                          {formErrors.phone_number}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label className="label_all mb-2">{t("_country")} </label>
                  {countryList !== undefined && countryList.length > 0 && (
                    <div className="filter_drop_down">
                      <Autocomplete
                        className="new_filter_select"
                        disablePortal
                        id="country"
                        name="country"
                        options={countryList}
                        value={countryList.find(
                          (obj) => obj.id == myProfile.country
                        )}
                        onChange={(event, data) => handleCountry(event, data)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  )}
                  {formErrors.country && (
                    <div className="invalid-feedback">{formErrors.country}</div>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label className="label_all mb-2">{t("_state")} </label>
                  {/* {
                      stateList.length > 0 && */}
                  <div className="filter_drop_down">
                    <Autocomplete
                      className="new_filter_select"
                      disablePortal
                      id="state"
                      name="state"
                      options={stateList}
                      value={
                        stateList.length > 0 &&
                        stateList.find((obj) => obj.id == myProfile.state) !==
                          undefined
                          ? stateList.find((obj) => obj.id == myProfile.state)
                          : ""
                      }
                      onChange={(event, data) => handleState(event, data)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                  {/* } */}
                  {formErrors.state && (
                    <div className="invalid-feedback">{formErrors.state}</div>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label className="label_all mb-2">{t("_city")} </label>
                  {/* {
                cityList.length > 0 && */}
                  <div className="filter_drop_down">
                    <Autocomplete
                      className="new_filter_select"
                      disablePortal
                      id="city"
                      name="city"
                      options={myProfile.state === "" ? [] : cityList}
                      value={
                        cityList.length > 0 &&
                        cityList.find((obj) => obj.id === myProfile.city) !=
                          undefined
                          ? cityList.find((obj) => obj.id === myProfile.city)
                          : ""
                      }
                      onChange={(event, data) => handleCity(event, data)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                  {formErrors.city && (
                    <div className="invalid-feedback">{formErrors.city}</div>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label className="label_all mb-2">{t("_zipcode")} </label>
                  <input
                    type="text"
                    id="zipcode"
                    name="zipcode"
                    placeholder="Zip Code"
                    value={myProfile.zipcode}
                    // className="form-control"
                    className={
                      `form-control ` +
                      (formErrors.zipcode ? " is-invalid" : "")
                    }
                    onChange={handleChange}
                  />
                  {formErrors.zipcode && (
                    <div className="invalid-feedback">{formErrors.zipcode}</div>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label className="label_all mb-2">
                    {t("_street_address")}{" "}
                  </label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={myProfile.address}
                    placeholder="Street Address"
                    // className="form-control"
                    className={
                      `form-control ` +
                      (formErrors.address ? " is-invalid" : "")
                    }
                    onChange={handleChange}
                  />
                  {formErrors.address && (
                    <div className="invalid-feedback">{formErrors.address}</div>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label className="label_all mb-2">
                    {t("_Amount_Paid_In")}{" "}
                  </label>
                  <Autocomplete
                    className="new_filter_select"
                    disablePortal
                    options={amountPaidOption}
                    value={amountPaidOption.find(
                      (value) => value.value == myProfile.paid_amount_days_type
                    )}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, value) =>
                      handleAmountPaidOption(e, value, "paid_amount_days_type")
                    }
                  />
                  {formErrors.paid_amount_days_type && (
                    <div className="invalid-feedback">
                      {formErrors.paid_amount_days_type}
                    </div>
                  )}

                  {myProfile.paid_amount_days_type == 0 && (
                    <p className="mt-2">
                      <span className="tax-msg ">{t("_note")}: </span>
                      {taxMessage}
                    </p>
                  )}
                </div>

                {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <label className="label_all mb-2">Company Name </label>
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                  placeholder="Company Name"
                  className="input_first"
                />
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                <label className="label_all mb-2">Website URl </label>
                <input
                  type="text"
                  id="website_url"
                  name="website_url"
                  placeholder="Website URl"
                  className="input_first"
                />
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 btn_file_input">
                <label className="label_all mb-2">Company Document </label>
                <input type="text" disabled />

                <input
                  type="file"
                  className="d-none"
                  name="image"
                  id="image"
                  ref={inputFile}
                  //   className="d-none"
                />

                <button onClick={() => inputFile.current.click()}>
                  choose file
                </button>
              </div> */}

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 supplier-password-fileds">
                  <div className=" change_password_popup mt-4">
                    {/*  */}
                    {myProfile.change_password === 1 && (
                      <div className="password-show">
                        <h2> {t("_change_password")} </h2>
                        <div className="row">
                          {
                            // (profileReducer.login_type == "facebook" || profileReducer.login_type == "facebook") &&
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                              <label className="label_all mb-2">
                                {t("_old_password")}
                              </label>
                              <input
                                type={
                                  showOldPassword == true ? "text" : "password"
                                }
                                id="old_password"
                                name="old_password"
                                value={myProfile.old_password}
                                placeholder={t("_enter_your_old_password")}
                                // className="form-control"
                                className={
                                  `form-control ` +
                                  (formErrors.old_password ? " is-invalid" : "")
                                }
                                onChange={handleChange}
                              />
                              {showOldPassword === true ? (
                                <svg
                                  onClick={() =>
                                    setShowOldPassword(!showOldPassword)
                                  }
                                  className="paassword-eye-hide"
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="VisibilityOffIcon"
                                >
                                  <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path>
                                </svg>
                              ) : (
                                <svg
                                  onClick={() =>
                                    setShowOldPassword(!showOldPassword)
                                  }
                                  className="paassword-eye-show"
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="VisibilityIcon"
                                >
                                  <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                                </svg>
                              )}
                              {formErrors.old_password && (
                                <div className="invalid-feedback">
                                  {formErrors.old_password}
                                </div>
                              )}
                            </div>
                          }
                          {/* <div className="col-xl-6 col-lg-5 col-md-0 col-sm-0 "></div> */}

                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                            <label className="label_all mb-2">
                              {t("_new_password")}
                            </label>
                            <input
                              type={
                                showNewPassword == true ? "text" : "password"
                              }
                              id="new_password"
                              name="new_password"
                              value={myProfile.new_password}
                              placeholder={t("_enter_your_new_password")}
                              // className="form-control"
                              className={
                                `form-control ` +
                                (formErrors.new_password ? " is-invalid" : "")
                              }
                              onChange={handleChange}
                            />
                            {showNewPassword == true ? (
                              <svg
                                onClick={() =>
                                  setShowNewPassword(!showNewPassword)
                                }
                                className="paassword-eye-hide signup-password-eye"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="VisibilityOffIcon"
                              >
                                <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path>
                              </svg>
                            ) : (
                              <svg
                                onClick={() =>
                                  setShowNewPassword(!showNewPassword)
                                }
                                className="paassword-eye-show signup-password-eye"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="VisibilityIcon"
                              >
                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                              </svg>
                            )}
                            {formErrors.new_password && (
                              <div className="invalid-feedback">
                                {formErrors.new_password}
                              </div>
                            )}
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-4">
                            <label className="label_all mb-2">
                              {t("_confirm_password")}
                            </label>
                            <input
                              type={
                                showConfirmPassword == true
                                  ? "text"
                                  : "password"
                              }
                              id="confirm_password"
                              name="confirm_password"
                              value={myProfile.confirm_password}
                              placeholder={t("_enter_your__confirm_password")}
                              // className="form-control"
                              className={
                                `form-control ` +
                                (formErrors.confirm_password
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={handleChange}
                            />
                            {showConfirmPassword == true ? (
                              <svg
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                className="paassword-eye-hide signup-password-eye"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="VisibilityOffIcon"
                              >
                                <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path>
                              </svg>
                            ) : (
                              <svg
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                className="paassword-eye-show signup-password-eye"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="VisibilityIcon"
                              >
                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                              </svg>
                            )}
                            {formErrors.confirm_password && (
                              <div className="invalid-feedback">
                                {formErrors.confirm_password}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="change_password">
                    <div className="frm_chechk_box">
                      <input
                        type="checkbox"
                        id="change_password"
                        name="change_password"
                        onClick={changePassword}
                      />
                      <label htmlFor="change_password">
                        {" "}
                        {t("_change_password")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="save_chnages_btn">
                  <button onClick={updateProfile}>{t("_save_changes")}</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileProvider;
