import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { cancelOrder } from "../../actions/orderDetailAction";
import { errorMsg, images } from "../../actions/customFn";
import { PuffLoader } from "react-spinners";

const CancelOrderModel = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cancelData, setIsCancelled, setShowOrderCancelModel } = props;
  const [errors, setErrors] = useState({});
  const languageReducer = useSelector((state) => state.languageReducer);
  const [buttonDisable, setButtonDisable] = useState(false);
  const error = {};
  const [formData, setFormData] = useState({
    cancel_description: "",
    cancel_reason: "",
    other_reason: "",
    proof: "",
  });
  const [isOther, setIsOther] = useState(false);
  const badWeatherRef = useRef();
  const travellersRef = useRef();
  const [proofImage, setProofImage] = useState("");

  const handleChange = (e, key = "") => {
    const { name, value } = e.target;
    key == "SHOW-OTHER" ? setIsOther(true) : setIsOther(false);

    if (name === "proof") {
      let fileName = e.target.files[0].type;
      let fileExtension = fileName.split("/").pop();
      setFormData((prev) => ({ ...prev, [name]: e.target.files[0] }));

      if (fileExtension != "pdf" || fileExtension != "PDF") {
        setProofImage(URL.createObjectURL(e.target.files[0]));
      }
    } else if (name == "other_reason") {
      setFormData({ ...formData, cancel_reason: value });
    } else {
      setFormData({ ...formData, [name]: value, other_reason: "", proof: "" });
      setProofImage("");
    }
  };

  const resetForm = () => {
    setFormData({
      cancel_reason: "",
      cancel_description: "",
    });
  };

  const validation = () => {
    if (formData.cancel_reason === "") {
      error.cancel_reason = t("_Select_the_reason_for_order_cancellation");
      errorMsg(t("_Select_the_reason_for_order_cancellation"));
    } else if (
      formData.proof == "" &&
      (formData.cancel_reason == "NOT ENOUGH TRAVELERS" ||
        formData.cancel_reason == "BAD WEATHER")
    ) {
      // errorMsg("Upload the proof")
      error.proof = "Upload the proof";
    }
    if (formData.cancel_description === "") {
      error.cancel_description = "Enter cancel description";
    }
    setErrors(error);
  };

  const submit = () => {
    validation();
    if (Object.keys(error).length === 0) {
      setButtonDisable(true);
      const data = new FormData();
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");

      data.append("user_id", localStorage.getItem("userId"));
      data.append("password", localStorage.getItem("password"));
      data.append("language", languageReducer.id);
      data.append("currency", currency.id);
      data.append("token", token);

      data.append("order_id", cancelData.order_id);
      data.append("product_id", cancelData.product_id);
      data.append("option_id", cancelData.option_id);
      data.append("order_index", cancelData.order_index);
      data.append("cancel_by", cancelData.cancel_by);
      data.append("cancel_reason", formData.cancel_reason);
      data.append("other_reason", formData.other_reason);
      data.append("cancel_description", formData.cancel_description);
      data.append("proof", formData.proof);
      // data.append("bad_weather_proof", formData.bad_weather_proof);
      // data.append("not_enough_travellers_proof", formData.not_enough_travellers_proof);

      dispatch(
        cancelOrder(
          data,
          navigate,
          setIsCancelled,
          setButtonDisable,
          setShowOrderCancelModel,
          resetForm
        )
      );
    }
  };
  // Function to handle closing the modal
  // const handleClose = () => {
  //   closeModel();
  // };
  return (
    <div className="modal_main">
      <Modal
        className="gift_popup review_modal_"
        show={props.showModal}
        onHide={props.onCloseModal}
      >
        <div className="modal-body">
          <div className="btn-close_main">
            <a type="button" onClick={props.onCloseModal} className="btn-close">
              {" "}
              <svg
                viewport="0 0 12 12"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="1"
                  y1="11"
                  x2="11"
                  y2="1"
                  stroke="#fff"
                  strokeWidth="2"
                />
                <line
                  x1="1"
                  y1="1"
                  x2="11"
                  y2="11"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </svg>
            </a>
          </div>
          <div className="row">
            <div className="entry_cross_btn cursor-pointer">
              <span onClick={props.onCloseModal}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="red"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.27916 12.7208C1.45797 12.8996 1.70044 13 1.95327 13C2.2061 13 2.44857 12.8996 2.62737 12.7208L7 8.34821L11.3726 12.7208C11.5514 12.8996 11.7939 13 12.0467 13C12.2996 13 12.542 12.8996 12.7208 12.7208C12.8996 12.542 13 12.2996 13 12.0467C13 11.7939 12.8996 11.5514 12.7208 11.3726L8.34821 7L12.7208 2.62737C12.8996 2.44857 13 2.2061 13 1.95327C13 1.70044 12.8996 1.45797 12.7208 1.27916C12.542 1.10041 12.2996 1 12.0467 1C11.7939 1 11.5514 1.10041 11.3726 1.27916L7 5.65179L2.62737 1.27916C2.44857 1.10041 2.2061 0.999999 1.95327 0.999999C1.70044 0.999999 1.45797 1.10041 1.27916 1.27916C1.10042 1.45796 1 1.70044 1 1.95327C1 2.20609 1.10042 2.44857 1.27916 2.62737L5.65179 7L1.27916 11.3726C1.10041 11.5514 1 11.7939 1 12.0467C1 12.2996 1.10041 12.542 1.27916 12.7208Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
          <div className="gift_modal_pop">
            <div className="gift_pop_form">
              <h2>{t("_Cancel_Order_Reason")}</h2>
              <div className="row gift_all_frm">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gift_input_all">
                  <div className="main-cancle-order">
                    <FormControl>
                      <label
                        htmlFor="cancel_reason"
                        className="form-label mt-4"
                      >
                        {t("_Let_us_know_why_you_are_cacelling_the_order_?")}
                      </label>
                      <div className="radio_btn_transpoetantion">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="cancel_reason"
                        >
                          {cancelData.cancel_by == "Supplier" ? (
                            <>
                              <FormControlLabel
                                control={<Radio />}
                                checked={
                                  formData.cancel_reason == "BAD WEATHER"
                                    ? true
                                    : false
                                }
                                value="BAD WEATHER"
                                onChange={handleChange}
                                label={t(
                                  "_bad_weather_should_attach_jpg_or_pdf"
                                )}
                              />
                              {formData.cancel_reason == "BAD WEATHER" && (
                                <div className="row">
                                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                    <div className="file_selection_box">
                                      <div className="file_inner_box ">
                                        <img
                                          src={images["file_main.png"]}
                                          alt=""
                                        />
                                        <p>{t("_Upload_Bad_Weather_Proof")}</p>
                                        <button
                                          onClick={() =>
                                            badWeatherRef.current.click()
                                          }
                                          className="btn_select"
                                        >
                                          {t("_select_image")}
                                        </button>
                                        <input
                                          ref={badWeatherRef}
                                          type="file"
                                          className="d-none"
                                          name="proof"
                                          onChange={(e) => handleChange(e)}
                                        />
                                        {errors.proof && (
                                          <div className="invalid-feedback">
                                            {errors.proof}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {formData.proof.name && proofImage && (
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                      <div className="provider_upload_img">
                                        {formData.proof.type.split("/").pop() ==
                                        "pdf" ? (
                                          <img
                                            src={images["PDF-image.png"]}
                                            alt=""
                                          />
                                        ) : (
                                          <img src={proofImage} alt="" />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <FormControlLabel
                                control={<Radio />}
                                checked={
                                  formData.cancel_reason ==
                                  "NOT ENOUGH TRAVELERS"
                                    ? true
                                    : false
                                }
                                value="NOT ENOUGH TRAVELERS"
                                onChange={handleChange}
                                label={t(
                                  "_Not_enough_travelers_Should_attache_jpg_or_PDF"
                                )}
                              />
                              {formData.cancel_reason ==
                                "NOT ENOUGH TRAVELERS" && (
                                <div className="row">
                                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                    <div className="file_selection_box">
                                      <div className="file_inner_box ">
                                        <img
                                          src={images["file_main.png"]}
                                          alt=""
                                        />
                                        <p>
                                          {t(
                                            "_Upload_Not_Enough_Travellers_Proof"
                                          )}
                                        </p>
                                        <button
                                          onClick={() =>
                                            travellersRef.current.click()
                                          }
                                          className="btn_select"
                                        >
                                          {t("_select_image")}
                                        </button>
                                        <input
                                          ref={travellersRef}
                                          type="file"
                                          className="d-none"
                                          name="proof"
                                          onChange={(e) => handleChange(e)}
                                        />
                                        {errors.proof && (
                                          <div className="invalid-feedback">
                                            {errors.proof}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {formData.proof && proofImage && (
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                      <div className="provider_upload_img">
                                        {formData.proof.type.split("/").pop() ==
                                        "pdf" ? (
                                          <img
                                            src={images["PDF-image.png"]}
                                            alt=""
                                          />
                                        ) : (
                                          <img src={proofImage} alt="" />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <FormControlLabel
                                control={<Radio />}
                                checked={
                                  formData.cancel_reason == "BAD REVIEWS"
                                    ? true
                                    : false
                                }
                                value="BAD REVIEWS"
                                onChange={handleChange}
                                label={t(
                                  "_Bad_review_from_friends_relatives_after_ordering_the_product"
                                )}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                checked={
                                  formData.cancel_reason ==
                                  "NOT REQUIRED ANYMORE"
                                    ? true
                                    : false
                                }
                                value="NOT REQUIRED ANYMORE"
                                onChange={handleChange}
                                label={t("_product_not_required_anymore")}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                checked={
                                  formData.cancel_reason ==
                                  "CHEAPER ALTERNATIVE"
                                    ? true
                                    : false
                                }
                                value="CHEAPER ALTERNATIVE"
                                onChange={handleChange}
                                label={t(
                                  "cheaper_alternative_available_for_lesser_price"
                                )}
                              />
                            </>
                          )}
                          <FormControlLabel
                            className="supplier-ask-lable"
                            control={<Radio />}
                            checked={
                              formData.cancel_reason == "SUPPLIER ASK"
                                ? true
                                : false
                            }
                            value="SUPPLIER ASK"
                            onChange={handleChange}
                            label={t("_supplier_ask")}
                          />
                          <FormControlLabel
                            className="other-reason-lable"
                            control={<Radio />}
                            checked={
                              formData.cancel_reason == "OTHER" ||
                              isOther == true
                                ? true
                                : false
                            }
                            value="OTHER"
                            onChange={(e) => handleChange(e, "SHOW-OTHER")}
                            label={t("_Other_Reason")}
                          />
                        </RadioGroup>
                      </div>
                    </FormControl>
                  </div>
                  <div className="other-reson-btn">
                    {(formData.cancel_reason == "OTHER" || isOther == true) && (
                      <>
                        <label htmlFor="cancel_reason" className="form-label">
                          {t("_Other_reason")}
                        </label>
                        <input
                          type="text"
                          onChange={(e) => handleChange(e, "SHOW-OTHER")}
                          className={
                            `form-control  ` +
                            (errors.other_reason ? "is-invalid" : "")
                          }
                          name="other_reason"
                          placeholder={t("_enter_cancel_reason")}
                        />
                        {errors.other_reason && (
                          <div className="invalid-feedback">
                            {errors.other_reason}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gift_input_all mt-4">
                  <div className="cancle-description">
                    <label htmlFor="Description" className="form-label">
                      {t("_description")}
                    </label>
                    <textarea
                      type="text"
                      className={
                        `form-control  ` +
                        (errors.cancel_description ? "is-invalid" : "")
                      }
                      // onClick={(e) => handleChange(e, "")}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          cancel_description: e.target.value,
                        })
                      }
                      id="cancel_description"
                      name="cancel_description"
                      placeholder={t("_enter_description")}
                    ></textarea>
                    {errors.cancel_description && (
                      <div className="invalid-feedback">
                        {errors.cancel_description}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 review_btn">
                  <button
                    className="mt-3"
                    onClick={() => {
                      submit();
                    }}
                    disabled={buttonDisable}
                  >
                    {buttonDisable ? (
                      <div className="order-cancelling-loader-btn">
                        {t("_Cancelling ...")}
                        <PuffLoader size={30} color="#fff" />
                      </div>
                    ) : (
                      t("_Cancel_Order")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelOrderModel;
