import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { ProductBuilderContext } from "../../context";
import {
  getArray,
  startSpinLoader,
  validateField,
} from "../../actions/customFn";
import Map from "../Map";
import { useTranslation } from "react-i18next";
import Geosuggest from "react-geosuggest";
import LocationSearchInput from "../UserProfile/LocationSearchInput";
const libraries = ['places'];

const Meetingpickup = (props) => {
  const { t } = useTranslation();
  const { tour_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const isFirstRender = useRef(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isDragged, setIsDragged] = useState(false);

  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    setTourId,
    getData,
    setDataLoad,
    dataLoad,
    runningDtep,
    productType,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo,
    googleKey,
    languageIdSelected,
  } = useContext(ProductBuilderContext);
  const [meetingData, setData] = useState("");
  const homeData = useSelector((state) => state.home);
  // let googleKey ="AIzaSyCHs0PwFYTtz4BGZvTk2TFinZT5LXvaOrQ"

  const [mapPosition, setMapPosition] = useState({
    center: {
      lat: 13.7563,
      lng: 100.5018,
    },
    zoom: 10,
  });

  const [formData, setFormData] = useState({
    meeting_pickup_id: "",
    tourId: tour_id,
    step: "five",
    product_type: "",
    language: productInfo.productLanguageId,
    traveler_pickup_option: "PICKUP_EVERYONE", //default value
    hotel: "",
    port: "",
    airport: "",
    other: "",
    hotel_pickup_information: "SINGLE_HOTEL",

    hotel_data: [
      {
        hotel_location: "",
        hotel_location_id: "",
        hotel_latitude: "",
        hotel_longitude: "",
        hotel_radius: "1",
        hotel_radius_type: "km",
        hotel_location_obj: "",
      },
    ],

    port_data: [
      {
        port_location: "",
        port_location_id: "",
        port_latitude: "",
        port_longitude: "",
        port_location_obj: "",
      },
    ],

    airport_data: [
      {
        airport_location: "",
        airport_location_id: "",
        airport_latitude: "",
        airport_longitude: "",
        airport_location_obj: "",
      },
    ],

    other_data: [
      {
        other_location: "",
        other_location_id: "",
        other_latitude: "",
        other_longitude: "",
        other_location_obj: "",
      },
    ],

    meeting_point_data: [
      {
        meeting_point_location: "",
        meeting_point_location_id: "",
        meeting_point_latitude: "",
        meeting_point_longitude: "",
        meeting_point_description: "",
        meeting_point_location_obj: "",
        meeting_point_available: "false",
      },
    ],

    end_point_data: [
      {
        end_point_location: "",
        end_point_location_id: "",
        end_point_latitude: "",
        end_point_longitude: "",
        end_point_location_obj: "",
        end_point_available: "false",
      },
    ],

    before_departure_pickup_time: "",
    before_departure_pickup_type: "",
    additional_info: "",
    end_point: "",
  });

  const handleChange = (e, key = "") => {
    const { name, value, checked } = e.target;
    if (key == "pick-up-your-travelers") {
      setFormData((formData) => ({ ...formData, [name]: checked }));
    } else if (
      name == "before_departure_pickup_time" ||
      name == "hotel_radius"
    ) {
      const onlyNumbers = /^[0-9]*$/;
      if (onlyNumbers.test(value) === true) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else if (name == "end_point") {
      setFormData((formData) => ({
        ...formData,
        [name]: value == "true" ? true : false,
      }));
    } else {
      setFormData((formData) => ({ ...formData, [name]: value }));
    }
  };

  const handleMeetingPointDescription = (e, index) => {
    const { name, value } = e.target;
    formData.meeting_point_data[index].meeting_point_description = value;
    const newUpdateData = formData.meeting_point_data;

    setFormData((formData) => ({
      ...formData,
      ["meeting_point_data"]: newUpdateData,
    }));
  };

  const handleEndPointDescription = (e, index) => {
    const { name, value } = e.target;
    formData.end_point_data[index].end_point_description = value;
    const newUpdateData = formData.end_point_data;

    setFormData((formData) => ({
      ...formData,
      ["end_point_data"]: newUpdateData,
    }));
  };

  const handleRadius = (e, index) => {
    const { name, value } = e.target;
    if (name == "hotel_radius") {
      let onlyNumbers = /^[0-9]*$/;
      // formData.hotel_data[index].hotel_radius = value;

      if (onlyNumbers.test(value) == true) {
        formData.hotel_data[index].hotel_radius = value;
      }
      const newUpdateData = formData.hotel_data;
      setFormData((formData) => ({
        ...formData,
        ["hotel_data"]: newUpdateData,
      }));
    }

    if (name == "hotel_radius_type") {
      formData.hotel_data[index].hotel_radius_type = value;
      const newUpdateData = formData.hotel_data;
      setFormData((formData) => ({
        ...formData,
        ["hotel_data"]: newUpdateData,
      }));
    }
  };

  // console.log("formData", formData);

  // ============Autocomplete for google address============
  const handleGoogleAddress = async (place, key, index, dragged = "false") => {
    if (place) {
      const { lat, lng } = place.location
      const address = place.label
      dragged == "true" ? setIsDragged(true) : setIsDragged(false);
      if (key == "hotel") {
        formData.hotel_data[index].hotel_location_id = "";
        formData.hotel_data[index].hotel_location = address;
        formData.hotel_data[index].hotel_latitude = lat;
        formData.hotel_data[index].hotel_longitude = lng;

        const newUpdateData = formData.hotel_data;
        setFormData((formData) => ({
          ...formData,
          ["hotel_data"]: newUpdateData,
        }));
        // setDefaultValue(place);
      }

      if (key == "port") {
        formData.port_data[index].port_location_id = "";
        formData.port_data[index].port_location = address;
        formData.port_data[index].port_latitude = lat;
        formData.port_data[index].port_longitude = lng;

        const newUpdateData = formData.port_data;
        setFormData((formData) => ({
          ...formData,
          ["port_data"]: newUpdateData,
        }));
      }
      if (key == "airport") {
        formData.airport_data[index].airport_location_id = "";
        formData.airport_data[index].airport_location = address;
        formData.airport_data[index].airport_latitude = lat;
        formData.airport_data[index].airport_longitude = lng;

        const newUpdateData = formData.airport_data;
        setFormData((formData) => ({
          ...formData,
          ["airport_data"]: newUpdateData,
        }));
      }
      if (key == "other") {
        formData.other_data[index].other_location_id = "";
        formData.other_data[index].other_location = address;
        formData.other_data[index].other_latitude = lat;
        formData.other_data[index].other_longitude = lng;

        const newUpdateData = formData.other_data;
        setFormData((formData) => ({
          ...formData,
          ["other_data"]: newUpdateData,
        }));
      }

      if (key == "meeting_point") {
        formData.meeting_point_data[index].meeting_point_location_id = "";
        formData.meeting_point_data[index].meeting_point_location = address;
        formData.meeting_point_data[index].meeting_point_latitude = lat;
        formData.meeting_point_data[index].meeting_point_longitude = lng;

        const newUpdateData = formData.meeting_point_data;
        setFormData((formData) => ({
          ...formData,
          ["meeting_point_data"]: newUpdateData,
        }));
      }

      if (key == "end_point") {
        formData.end_point_data[index].end_point_location_id = "";
        formData.end_point_data[index].end_point_location = address;
        formData.end_point_data[index].end_point_latitude = lat;
        formData.end_point_data[index].end_point_longitude = lng;

        const newUpdateData = formData.end_point_data;
        setFormData((formData) => ({
          ...formData,
          ["end_point_data"]: newUpdateData,
        }));
      }
    }
  };

  // console.log("formData", formData.hotel_data);

  const addMore = (key) => {
    if (key == "hotel") {
      const duplicated_hotel_data = {
        hotel_location: "",
        hotel_location_id: "",
        hotel_latitude: "",
        hotel_longitude: "",
        hotel_radius: "1",
        hotel_radius_type: "km",
      };
      setFormData((formData) => ({
        ...formData,
        ["hotel_data"]: [...formData.hotel_data, duplicated_hotel_data],
      }));
    }
    if (key == "port") {
      const duplicated_port_data = {
        port_location: "",
        port_location_id: "",
        port_latitude: "",
        port_longitude: "",
        hotel_radius: "",
        hotel_radius_type: "",
      };
      setFormData((formData) => ({
        ...formData,
        ["port_data"]: [...formData.port_data, duplicated_port_data],
      }));
    }
    if (key == "airport") {
      const duplicated_airport_data = {
        airport_location: "",
        airport_location_id: "",
        airport_latitude: "",
        airport_longitude: "",
      };
      setFormData((formData) => ({
        ...formData,
        ["airport_data"]: [...formData.airport_data, duplicated_airport_data],
      }));
    };

    if (key == "other") {
      const duplicated_other_data = {
        other_location: "",
        other_location_id: "",
        other_latitude: "",
        other_longitude: "",
      };
      setFormData((formData) => ({
        ...formData,
        ["other_data"]: [...formData.other_data, duplicated_other_data],
      }));
    };


    if (key == "meeting_point") {
      const duplicated_meeting_point_data = {
        meeting_point_location_id: "",
        meeting_point_location: "",
        meeting_point_latitude: "",
        meeting_point_longitude: "",
        meeting_point_description: "",
        meeting_point_available: "false",
      };

      setFormData((formData) => ({
        ...formData,
        ["meeting_point_data"]: [
          ...formData.meeting_point_data,
          duplicated_meeting_point_data,
        ],
      }));
    }

    if (key == "end_point") {
      const duplicated_end_point_data = {
        end_point_location: "",
        end_point_location_id: "",
        end_point_latitude: "",
        end_point_longitude: "",
        meeting_point_available: "false",
      };
      setFormData((formData) => ({
        ...formData,
        ["end_point_data"]: [
          ...formData.end_point_data,
          duplicated_end_point_data,
        ],
      }));
    }
  };

  const removeLocation = (key, index) => {
    let dataObject = key + "_data";
    let data = formData[dataObject];
    data.splice(index, 1);
    setFormData((prev) => ({ ...prev, dataObject: data }));
    setIsDragged(false);
  };

  const handleAvailableLPoint = (e, key, index) => {
    const { name } = e.target;
    let updatingData = formData[key];
    updatingData.forEach((item, i) => {
      item[name] = index == i ? "true" : "false";
    });

    setFormData((formData) => ({
      ...formData,
      key: updatingData,
    }));
  };

  const submitData = (exit) => {
    let validate = {};
    if (
      formData.traveler_pickup_option == "PICKUP_EVERYONE" ||
      formData.traveler_pickup_option == "PICKUP_AND_MEET_AT_START_POINT"
    ) {
      if (
        formData.hotel == true ||
        formData.port == true ||
        formData.airport == true ||
        formData.other == true
      ) {
        let removefirstErrorElement = document.getElementsByName(
          "meeting_pickup_traveler"
        )[0];
        if (removefirstErrorElement) {
          removefirstErrorElement.classList.remove("form_error_field");
        }
      } else {
        validate = {
          ...validate,
          meeting_pickup_traveler: "RequiredNoBorder|",
        };
      }
      if (formData.hotel == true) {
        validate = {
          ...validate,
          arr_hotel_location: "hotel_data|Required|",
        };
      }

      if (formData.port == true) {
        validate = {
          ...validate,
          arr_port_location: "port_data|Required|",
        };
      }

      if (formData.airport == true) {
        validate = {
          ...validate,
          arr_airport_location: "airport_data|Required|",
        };
      }

      if (formData.other == true) {
        validate = {
          ...validate,
          arr_other_location: "other_data|Required|",
        };
      }
      validate = {
        ...validate,
        additional_info: "Required|",
      };
    }

    if (
      formData.traveler_pickup_option == "PICKUP_AND_MEET_AT_START_POINT" ||
      formData.traveler_pickup_option == "MEET_EVERYONE_AT_START_POINT"
    ) {
      if (formData.product_type != "ticket_and_pass") {
        validate = {
          ...validate,
          arr_meeting_point_location: "meeting_point_data|Required|",
        };
      }

      if (
        formData.product_type == "tour" &&
        formData.traveler_pickup_option != "PICKUP_EVERYONE" &&
        formData.end_point == false
      ) {
        validate = {
          ...validate,
          arr_end_point_location: "end_point_data|Required|",
        };
      }
    }
    validateField(validate, { ...formData }).then((res) => {
      startSpinLoader("cover-spin");
      if (res == true) {
        let nextRoute = exit || "activity-detail";
        if (languageReducer !== "") {
          let sendData = {
            ...formData,
            hotel_location_obj: getArray(
              formData.hotel_data,
              "hotel_location_obj"
            ),
            hotel_location: getArray(formData.hotel_data, "hotel_location"),
            hotel_location_id: getArray(
              formData.hotel_data,
              "hotel_location_id"
            ),
            hotel_latitude: getArray(formData.hotel_data, "hotel_latitude"),
            hotel_longitude: getArray(formData.hotel_data, "hotel_longitude"),
            hotel_radius: getArray(formData.hotel_data, "hotel_radius"),
            hotel_radius_type: getArray(
              formData.hotel_data,
              "hotel_radius_type"
            ),
            port_location_obj: getArray(
              formData.port_data,
              "port_location_obj"
            ),
            port_location: getArray(formData.port_data, "port_location"),
            port_location_id: getArray(formData.port_data, "port_location_id"),
            port_latitude: getArray(formData.port_data, "port_latitude"),
            port_longitude: getArray(formData.port_data, "port_longitude"),
            airport_location_obj: getArray(
              formData.airport_data,
              "airport_location_obj"
            ),
            airport_location: getArray(
              formData.airport_data,
              "airport_location"
            ),
            airport_location_id: getArray(
              formData.airport_data,
              "airport_location_id"
            ),
            airport_latitude: getArray(
              formData.airport_data,
              "airport_latitude"
            ),
            airport_longitude: getArray(
              formData.airport_data,
              "airport_longitude"
            ),
            other_location_obj: getArray(
              formData.other_data,
              "other_location_obj"
            ),
            other_location: getArray(formData.other_data, "other_location"),
            other_location_id: getArray(
              formData.other_data,
              "other_location_id"
            ),
            other_latitude: getArray(formData.other_data, "other_latitude"),
            other_longitude: getArray(formData.other_data, "other_longitude"),
            meeting_point_location_obj: getArray(
              formData.meeting_point_data,
              "meeting_point_location_obj"
            ),
            meeting_point_location: getArray(
              formData.meeting_point_data,
              "meeting_point_location"
            ),
            meeting_point_location_id: getArray(
              formData.meeting_point_data,
              "meeting_point_location_id"
            ),
            meeting_point_latitude: getArray(
              formData.meeting_point_data,
              "meeting_point_latitude"
            ),
            meeting_point_longitude: getArray(
              formData.meeting_point_data,
              "meeting_point_longitude"
            ),
            meeting_point_description: getArray(
              formData.meeting_point_data,
              "meeting_point_description"
            ),

            meeting_point_available: getArray(
              formData.meeting_point_data,
              "meeting_point_available"
            ),

            end_point_location_obj: getArray(
              formData.end_point_data,
              "end_point_location_obj"
            ),
            end_point_location: getArray(
              formData.end_point_data,
              "end_point_location"
            ),
            end_point_location_id: getArray(
              formData.end_point_data,
              "end_point_location_id"
            ),
            end_point_latitude: getArray(
              formData.end_point_data,
              "end_point_latitude"
            ),
            end_point_longitude: getArray(
              formData.end_point_data,
              "end_point_longitude"
            ),
            end_point_description: getArray(
              formData.end_point_data,
              "end_point_description"
            ),
            end_point_available: getArray(
              formData.end_point_data,
              "end_point_available"
            ),
          };


          console.log("sendData", sendData);

          dispatch(
            uplodProductNew(
              languageReducer,
              setTourId,
              navigate,
              sendData,
              nextRoute,
              "",
              "",
              "",
              "",
              "",
              "",
              setSpinLoader
            )
          );
        }
      } else {
        setSpinLoader((status) => !status);
      }
    });
  };


  useEffect(() => {
    if (isFirstRender == false) {
      submitData(exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  useEffect(() => {
    if (languageReducer != "") {
      getData("five", setData);
    }
  }, [languageReducer, languageIdSelected]);

  useEffect(() => {
    if (languageReducer) {
      setFormData(() => ({
        ...meetingData.product_data,
        tourId: tour_id,
        step: "five",
        product_type: meetingData.ProductType,
        language: productInfo.productLanguageId,
        // "product_type": productType,
      }));
    }
  }, [meetingData]);

  // ==========Google map code=========

  const fitBounds = async (map, key) => {
    let bounds = new window.google.maps.LatLngBounds();
    let isSingleLocation = false;
    if (map != null) {
      if (key == "multiple") {
        formData.hotel_data.forEach((marker) => {
          bounds.extend(
            new window.google.maps.LatLng(
              marker.hotel_latitude,
              marker.hotel_longitude
            )
          );
        });
        if (formData.hotel_data.length == 1) {
          isSingleLocation = true;
        }
      }
      if (key == "meeting_point") {
        formData.meeting_point_data.forEach((marker) => {
          bounds.extend(
            new window.google.maps.LatLng(
              marker.meeting_point_latitude,
              marker.meeting_point_longitude
            )
          );
        });
        if (formData.meeting_point_data.length == 1) {
          isSingleLocation = true;
        }
      }
      if (key == "end_point") {
        formData.end_point_data.forEach((marker) => {
          bounds.extend(
            new window.google.maps.LatLng(
              marker.end_point_latitude,
              marker.end_point_longitude
            )
          );
        });
        if (formData.end_point_data.length == 1) {
          isSingleLocation = true;
        }
      }

      if (isSingleLocation == true) {
        let zoomLevel = 0.01; //Increase value : Zoom-out, Decrease value:Zoom In
        const singleLocation = bounds.getCenter();
        bounds.extend(
          new window.google.maps.LatLng(
            singleLocation.lat() + zoomLevel,
            singleLocation.lng() + zoomLevel
          )
        );
        bounds.extend(
          new window.google.maps.LatLng(
            singleLocation.lat() - zoomLevel,
            singleLocation.lng() - zoomLevel
          )
        );
      }

      if (isDragged != true) {
        await map.fitBounds(bounds);
        setIsDragged(false);
      }
    }
  };

  const handleMapMounted = (map, key) => {
    if (map != null) {
      fitBounds(map, key);
    }
  };

  // const handleBoundsChanged = (map, key) => {
  //   if (map != null) {
  //     fitBounds(map, key);
  //   }
  // };

  // ==========Google map code=========

  // ============New Google Search Code==============

  // console.log("formData",formData?.meeting_point_data);

  return (
    <>
      {formData.product_type && dataLoad == true && googleKey && (
        <div className="meet-pickup-main">
          <div className="meet-box-main">
            {/* Top Upper Heading */}
            <div className="top-header-meet">
              <h2>{t("_Tell_us_how_and_where_you_meet_your_travelers")}</h2>
              <div className="top-header-info-box">
                <div className="icon-svg-info">
                  <svg
                    viewBox="0 0 27 36"
                    xmlns="http://www.w3.org/2000/svg"
                    className="Alert__alertIcon___S7wRU Icon__icon___2sbfU"
                  >
                    <g fillRule="nonzero">
                      <path d="M17.97 34.81v.25h-9a.7.7 0 01-.7-.7V28.5h1.4v5.16h7.6V28.5h1.4v5.86a.7.7 0 01-.7.7v-.25z"></path>
                      <path d="M20.22 23.45l.24-.23c.77-.76 1.11-1.12 1.51-1.65a11.5 11.5 0 003.05-7.82A11.6 11.6 0 0013.43 2.2 11.57 11.57 0 005 21.63c.35.47.69.83 1.46 1.59l.24.23c1.77 1.74 2.52 2.98 2.56 5h8.42c.03-2.02.78-3.26 2.55-5zm-2.25 5.74v.75h-9a1.23 1.23 0 01-1.22-1.21c.03-1.7-.52-2.64-2.12-4.22l-.23-.22c-.83-.81-1.2-1.2-1.56-1.7A13.03 13.03 0 01.4 13.76C.4 6.56 6.24.69 13.45.69a13.1 13.1 0 0113.06 13.07 13 13 0 01-3.4 8.77c-.41.55-.78.95-1.61 1.76l-.23.23c-1.6 1.57-2.14 2.51-2.11 4.2a1.2 1.2 0 01-1.2 1.22v-.75z"></path>
                      <path d="M4.44 14H3.29v-.24a10.2 10.2 0 014.2-8.23l.2-.15.82 1.13-.2.15a8.8 8.8 0 00-3.62 7.1h-.25V14zM9.22 5.79l-.5-1.03.22-.11c.36-.18.69-.32 1.01-.44l.24-.08.48 1.31-.24.09c-.28.1-.56.22-.87.37l-.11-.22-.23.1zM7.67 32.25v-1.4h11.6v1.4z"></path>
                    </g>
                  </svg>
                </div>
                <div className="meet-box-content">
                  <h2>{t("_Did_you_know?")}</h2>
                  <p>
                    {t(
                      "_Travelers_want_to_book_products_with_accurate_picku_locations_so_they_can_plan_their_day_Adding_specific_meeting_and_pickup_points_will_help_them_find_your_product."
                    )}
                  </p>
                </div>
              </div>
            </div>

            {/* Second Section Select */}
            <div className="box-nt-ul-selcet">
              <label> {t("_How_do_you_meet_your_travelers?")}</label>
              {/* <select className="form-select" aria-label="Default select example" onChange={(e) => setDropdownvalue(e.target.value)} > */}
              <select
                className="form-select"
                aria-label="Default select example"
                name="traveler_pickup_option"
                onChange={(e) => handleChange(e, "")}
                value={formData.traveler_pickup_option}
              >
                {formData.product_type == "ticket_and_pass" ? (
                  <>
                    <option value="PICKUP_AND_MEET_AT_START_POINT">
                      {t("_Yes,_pickup_is_optional")}
                    </option>
                    <option value="MEET_EVERYONE_AT_START_POINT">
                      {" "}
                      {t("_no,_travelers_go_directly_to_the_location")}
                    </option>
                  </>
                ) : (
                  <>
                    <option value="PICKUP_EVERYONE">
                      {t("_We_pick_up_all_travelers")}
                    </option>
                    <option value="PICKUP_AND_MEET_AT_START_POINT">
                      {t(
                        "_We_can_pick_up_travelers_or_meet_them_at_a_meeting_point"
                      )}
                    </option>
                    <option value="MEET_EVERYONE_AT_START_POINT">
                      {t("_No,_we_meet_all_travelers_at_a_meeting_point")}
                    </option>
                  </>
                )}
              </select>
              {/* <p className="error-code">
                Please specify whether you offer a pickup option.
              </p> */}
            </div>

            {(formData.traveler_pickup_option == "PICKUP_EVERYONE" ||
              formData.traveler_pickup_option ==
              "PICKUP_AND_MEET_AT_START_POINT" ||
              (formData.product_type == "ticket_and_pass" &&
                formData.traveler_pickup_option !=
                "MEET_EVERYONE_AT_START_POINT")) && (
                // <FirstOptionSelectdiv />
                <div className="option_firstselectmaindiv">
                  <h4>
                    {" "}
                    {t("_Now_tell_us_where_you_pick_up_travelers")}
                    <span className="required-field">*</span>
                  </h4>
                  <h6> {t("_Where_do_you_pick_up_your_travelers?")}</h6>
                  <div>
                    <div
                      className="metting_checkboxmaindiv"
                      name="meeting_pickup_traveler"
                    >
                      <div>
                        <label className="metting_checkboxes">
                          <input
                            type="checkbox"
                            name="hotel"
                            onChange={(e) =>
                              handleChange(e, "pick-up-your-travelers")
                            }
                            checked={formData.hotel}
                          />
                          <span>{t("_Hotel")}</span>
                        </label>
                      </div>

                      <div>
                        <label className="metting_checkboxes">
                          <input
                            type="checkbox"
                            name="port"
                            onChange={(e) =>
                              handleChange(e, "pick-up-your-travelers")
                            }
                            checked={formData.port}
                          />
                          <span>{t("_Port")}</span>
                        </label>
                      </div>
                      <div>
                        <label className="metting_checkboxes">
                          <input
                            type="checkbox"
                            name="airport"
                            onChange={(e) =>
                              handleChange(e, "pick-up-your-travelers")
                            }
                            checked={formData.airport}
                          />
                          <span>{t("_Airport")}</span>
                        </label>
                      </div>
                      <div>
                        <label className="metting_checkboxes">
                          <input
                            type="checkbox"
                            name="other"
                            onChange={(e) =>
                              handleChange(e, "pick-up-your-travelers")
                            }
                            checked={formData.other}
                          />
                          <span> {t("_Other")}</span>
                        </label>
                      </div>

                      {/* <p className="error-code">
                    Please select one of the options above
                  </p> */}
                    </div>
                  </div>

                  {formData.hotel == true && (
                    <>
                      {/* -- hotel checkbox tab -- */}
                      <div className="hotel_checkshow">
                        <h6>
                          {t(
                            "_How_do_you_want_to_enter_your_hotel_pickup_information"
                          )}
                        </h6>
                        <div className="hotel_radiodiv">
                          <input
                            type="radio"
                            className="productradio"
                            name="hotel_pickup_information"
                            onChange={(e) =>
                              handleChange(e, "hotel_pickup_information")
                            }
                            value="HOTEL_AREA"
                            checked={
                              formData.hotel_pickup_information == "HOTEL_AREA"
                                ? true
                                : false
                            }
                          />
                          <span>
                            {t(
                              "_I_want_to_define_a_general_area_of_hotels_i_pick_up_from"
                            )}
                          </span>
                        </div>
                        <p className="gap_left">
                          <span>
                            {t(
                              "_i_will_select_a_radius_in_a_city,_neighborhood_specific_address_and_pick_up_within_that_radius"
                            )}
                          </span>
                        </p>

                        <div className="hotel_radiodiv">
                          <input
                            type="radio"
                            className="productradio"
                            name="hotel_pickup_information"
                            onChange={(e) =>
                              handleChange(e, "hotel_pickup_information")
                            }
                            value="SINGLE_HOTEL"
                            checked={
                              formData.hotel_pickup_information == "SINGLE_HOTEL"
                                ? true
                                : false
                            }
                          />
                          <span>
                            {" "}
                            {t("_I_want_to_enter_locations_one_at_a_time")}
                          </span>
                        </div>
                        <p className="gap_left">
                          <span>{t("_I_will_add_hotels_individually")}</span>
                        </p>
                      </div>

                      <div className="port_checkshow">
                        {formData.hotel_pickup_information == "SINGLE_HOTEL" ? (
                          <h6>
                            {t("_Hotel")}
                            <span className="required-field">*</span>
                          </h6>
                        ) : formData.hotel_pickup_information == "HOTEL_AREA" ? (
                          <>
                            {t(
                              "_define_the_geographic_area_for_your_hotel_pickup_information"
                            )}
                            <span className="required-field">*</span>
                          </>
                        ) : (
                          ""
                        )}
                        {formData.hotel_pickup_information != "" &&
                          formData.hotel_pickup_information != null && (
                            <>
                              {formData.hotel_data.map((value, index) => {
                                return (
                                  <div key={index}>
                                    <div
                                      key={index}
                                      className="mb-2 d-flex"
                                      name={`hotel_location.${index}`}
                                      id={`hotel_location.${index}`}
                                    >
                                      <div>
                                        <Geosuggest
                                          placeholder="Search Place here"
                                          onSuggestSelect={(place) => handleGoogleAddress(place, "hotel", index, "false")}
                                          initialValue={value.hotel_location}
                                        />
                                      </div>

                                      <div className="hotel_select ">
                                        {formData.hotel_pickup_information ==
                                          "HOTEL_AREA" && (
                                            <>
                                              <input
                                                type="text"
                                                name="hotel_radius"
                                                className="hotel_radius"
                                                // value={formData.hotel_radius[index]}
                                                value={
                                                  formData.hotel_data[index]
                                                    .hotel_radiushandleGoogleAddress
                                                }
                                                onChange={(e) =>
                                                  handleRadius(e, index)
                                                }
                                              />
                                              <select
                                                className=""
                                                id="cars"
                                                name="hotel_radius_type"
                                                // value={formData.hotel_radius_type[index]}
                                                value={
                                                  formData.hotel_data[index]
                                                    .hotel_radius_type
                                                }
                                                onChange={(e) =>
                                                  handleRadius(e, index)
                                                }
                                              >
                                                <option value="km">
                                                  {t("_km")}
                                                </option>
                                                <option value="mile">
                                                  {t("_mile")}
                                                </option>
                                              </select>
                                            </>
                                          )}
                                      </div>
                                      <span
                                        className="cursor-pointer delete-icon"
                                        onClick={(e) => {
                                          removeLocation("hotel", index);
                                        }}
                                      >
                                        {index != 0 && (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                              fill="#fc5301"
                                            />
                                            <path
                                              d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                              fill="#fc5301"
                                            />
                                            <path
                                              d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                              fill="#fc5301"
                                            />
                                            <path
                                              d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                              fill="#fc5301"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}

                              {formData.hotel_pickup_information && (
                                <button
                                  className="trasnparent_buton mt-2"
                                  onClick={() => addMore("hotel")}
                                >
                                  + {t("_add_a_Hotel")}
                                </button>
                              )}

                              {formData.hotel_pickup_information ==
                                "HOTEL_AREA" && (
                                  <div className="map-inner">
                                    <div className="google-map-code vishal">
                                      <Map
                                        center={{
                                          lat: 22.7527421,
                                          lng: 75.88371599999999,
                                        }}
                                        zoom={12}
                                        mapName="multiple"
                                        places={{
                                          multiple: formData.hotel_data,
                                        }}
                                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                                        loadingElement={
                                          <div style={{ height: `100%` }} />
                                        }
                                        containerElement={
                                          <div style={{ height: `300px` }} />
                                        }
                                        mapElement={
                                          <div style={{ height: `100%` }} />
                                        }
                                        onMapMounted={(map) =>
                                          handleMapMounted(map, "multiple")
                                        }
                                        // onBoundsChanged={(map) => handleBoundsChanged(map, "multiple")}
                                        handleGoogleAddress={handleGoogleAddress}
                                        setIsDragged={setIsDragged}
                                      />
                                    </div>
                                  </div>
                                )}
                            </>
                          )}

                        {/* <p className="error-code">
                        You must add at least one hotel
                      </p> */}
                      </div>
                    </>
                  )}

                  {formData.port == true && (
                    <div className="port_checkshow">
                      <h6>
                        {t("_Port")}
                        <span className="required-field">*</span>
                      </h6>
                      {formData.port_data.map((value, index) => {
                        return (
                          <div className="meeting-pickup-locations" key={index}>
                            <div
                              key={index}
                              className="mb-2 d-flex location-search-div"
                              name={`port_location.${index}`}
                              id={`port_location.${index}`}
                            >
                              <div>
                                <Geosuggest
                                  placeholder="Search Place here"
                                  onSuggestSelect={(place) => handleGoogleAddress(place, "port", index, "false")}
                                  initialValue={value.port_location}
                                />
                              </div>
                            </div>
                            <span
                              className="cursor-pointer delete-icon"
                              onClick={(e) => {
                                removeLocation("port", index);
                              }}
                            >
                              {index != 0 && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                    fill="#fc5301"
                                  />
                                  <path
                                    d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                    fill="#fc5301"
                                  />
                                  <path
                                    d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                    fill="#fc5301"
                                  />
                                  <path
                                    d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                    fill="#fc5301"
                                  />
                                </svg>
                              )}
                            </span>
                          </div>
                        );
                      })}
                      <button
                        className="trasnparent_buton mt-2"
                        onClick={() => addMore("port")}
                      >
                        + {t("_add_a_port")}
                      </button>
                      {/* <p className="error-code">You must add at least one port</p> */}
                    </div>
                  )}
                  {formData.airport == true && (
                    <>
                      {/* -- airport checkbox tab -- */}
                      <div className="airport_checkshow">
                        <h6>
                          {t("_Airport")}
                          <span className="required-field">*</span>
                        </h6>
                        {formData.airport_data.map((value, index) => {
                          return (
                            <div className="meeting-pickup-locations" key={index}>
                              <div
                                className="mb-2 d-flex location-search-div"
                                name={`airport_location.${index}`}
                                id={`airport_location.${index}`}
                              >
                                <div>
                                  <Geosuggest
                                    placeholder="Search Place here"
                                    onSuggestSelect={(place) => handleGoogleAddress(place, "airport", index, "false")}
                                    initialValue={value.airport_location}
                                  />
                                </div>
                              </div>
                              <span
                                className="cursor-pointer delete-icon"
                                onClick={(e) => {
                                  removeLocation("airport", index);
                                }}
                              >
                                {index != 0 && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                      fill="#fc5301"
                                    />
                                    <path
                                      d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                      fill="#fc5301"
                                    />
                                    <path
                                      d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                      fill="#fc5301"
                                    />
                                    <path
                                      d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                      fill="#fc5301"
                                    />
                                  </svg>
                                )}
                              </span>
                            </div>
                          );
                        })}

                        <button
                          className="trasnparent_buton mt-2"
                          onClick={() => addMore("airport")}
                        >
                          + {t("_add_a_airport")}
                        </button>
                        {/* <p className="error-code">
                        You must add at least one airport
                      </p> */}
                      </div>
                    </>
                  )}
                  {formData.other == true && (
                    <>
                      {/* -- other checkbox tab -- */}
                      <div className="otherone_checkshow">
                        <h6>
                          {t("_Other")}
                          <span className="required-field">*</span>
                        </h6>
                        {formData.other_data.map((value, index) => {
                          return (
                            <div className="meeting-pickup-locations" key={index}>
                              <div
                                className="mb-2 d-flex location-search-div"
                                name={`other_location.${index}`}
                                id={`other_location.${index}`}
                              >
                                <div>
                                  <Geosuggest
                                    placeholder="Search Place here"
                                    onSuggestSelect={(place) => handleGoogleAddress(place, "other", index, "false")}
                                    initialValue={value.other_location}
                                  />
                                </div>
                              </div>
                              <span
                                className="cursor-pointer delete-icon"
                                onClick={(e) => {
                                  removeLocation("other", index);
                                }}
                              >
                                {index != 0 && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                      fill="#fc5301"
                                    />
                                    <path
                                      d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                      fill="#fc5301"
                                    />
                                    <path
                                      d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                      fill="#fc5301"
                                    />
                                    <path
                                      d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                      fill="#fc5301"
                                    />
                                  </svg>
                                )}
                              </span>
                            </div>
                          );
                        })}

                        {/* <input className="fullwidthinput" type="text" placeholder="Add a description (optional) " /> */}
                        <button
                          className="trasnparent_buton mt-2"
                          onClick={() => addMore("other")}
                        >
                          + {t("_add_a_location")}
                        </button>
                      </div>
                    </>
                  )}

                  {/* <div className="pickuppoint">
                <label className="metting_checkboxes">
                  <input type="checkbox" />
                  <span>Allow travelers to specify their own pickup point</span>
                </label>
              </div> */}

                  <h6 className="mt-4">
                    {t(
                      "_how_long_before_your_activity_start_time_do_you_pick_up_travelers?"
                    )}
                  </h6>

                  <div className="hourinputdiv">
                    <input
                      type="text"
                      name="before_departure_pickup_time"
                      value={formData.before_departure_pickup_time}
                      onChange={handleChange}
                    />
                    <select
                      id="cars"
                      name="before_departure_pickup_type"
                      value={formData.before_departure_pickup_type}
                      onChange={handleChange}
                    >
                      <option value="hour"> {t("_hour")}</option>
                      <option value="minute">{t("_minute")}</option>
                    </select>
                  </div>

                  <h6>
                    {t(
                      "_add_any_additional_pickup_details_you'd_like_travelers_to_know"
                    )}
                    <span className="required-field">*</span>
                  </h6>
                  <p>
                    {t(
                      "_this_will_be_displayed_on_both_the_product_page_and_travelers'tickets."
                    )}
                  </p>
                  <textarea
                    name="additional_info"
                    placeholder="E.g. how to find the pickup location, how travelers can find you."
                    rows="4"
                    onChange={handleChange}
                    value={formData.additional_info}
                  ></textarea>

                  {/* <p className="error-code">
                  Please enter details for the "Other" pickup options you
                  support in the "Additional pickup information" section
                </p> */}
                </div>
              )}

            {(formData.traveler_pickup_option ==
              "PICKUP_AND_MEET_AT_START_POINT" ||
              formData.traveler_pickup_option ==
              "MEET_EVERYONE_AT_START_POINT") &&
              formData.product_type != "ticket_and_pass" && (
                <>
                  <div className="option_thirdselectmaindiv">
                    {formData.traveler_pickup_option ==
                      "PICKUP_AND_MEET_AT_START_POINT" ? (
                      <h4>
                        {t(
                          "_Where_will_you_meet_travelers_that_don’t_require_pickup?"
                        )}
                      </h4>
                    ) : (
                      <h4>{t("_Now_tell_us_where_you_meet_travelers")}</h4>
                    )}
                    {/* <SecondOptionSelectdiv /> */}
                    <div>
                      <h3 className="meet_h3 mt-4">
                        {t("_Where_is_the_meeting_point_for_your_activity?")}
                      </h3>

                      {formData.meeting_point_data.map((value, index) => {
                        return (
                          <div key={index}>
                            <span>
                              {t("_Meeting_Point")}
                              <span className="required-field">*</span>
                            </span>
                            <div className="meeting-pickup-locations">
                              {formData.product_type != "activity" && (
                                <div className="point-available-radio-btn">
                                  <input
                                    type="radio"
                                    className="productradio"
                                    name="meeting_point_available"
                                    onChange={(e) =>
                                      handleAvailableLPoint(
                                        e,
                                        "meeting_point_data",
                                        index
                                      )
                                    }
                                    // value="HOTEL_AREA"
                                    checked={
                                      value.meeting_point_available == "true"
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              )}

                              <div
                                className="mb-2 location-search-div"
                                name={`meeting_point_location.${index}`}
                                id={`meeting_point_location.${index}`}
                              >
                                <div>
                                  <Geosuggest
                                    placeholder="Search Place here"
                                    onSuggestSelect={(place) => handleGoogleAddress(place, "meeting_point", index, "false")}
                                    initialValue={value.meeting_point_location}
                                  />
                                </div>
                              </div>
                              <span
                                className="cursor-pointer delete-icon"
                                onClick={(e) => {
                                  removeLocation("meeting_point", index);
                                }}
                              >
                                {index != 0 && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                      fill="#fc5301"
                                    />
                                    <path
                                      d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                      fill="#fc5301"
                                    />
                                    <path
                                      d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                      fill="#fc5301"
                                    />
                                    <path
                                      d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                      fill="#fc5301"
                                    />
                                  </svg>
                                )}
                              </span>
                            </div>
                            {/* {
                              value.meeting_point_location_obj &&
                              <Map
                                center={{ lat: value.meeting_point_latitude, lng: value.meeting_point_longitude }}
                                zoom={12}
                                places={{ lat: value.meeting_point_latitude, lng: value.meeting_point_longitude }}
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `300px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                              />
                            } */}
                            <span className="meet_h3 mt-2">
                              {t("_Meeting_instructions")}
                            </span>
                            <textarea
                              name="meeting_point_description"
                              id="meeting_point_description"
                              rows="3"
                              placeholder="Please only include information about how to find the meeting point (e.g. Go to the corner of Stockton and Post Street and look for a guide wearing a red hat)"
                              onChange={(e) =>
                                handleMeetingPointDescription(e, index)
                              }
                              defaultValue={value.meeting_point_description}
                            ></textarea>
                          </div>
                        );
                      })}

                      {formData.product_type != "activity" && (
                        <button
                          className="trasnparent_buton mt-2"
                          onClick={() => addMore("meeting_point")}
                        >
                          + {t("_add_a_location")}
                        </button>
                      )}

                      <div className="map-inner">
                        <div className="google-map-code vishal">
                          <Map
                            center={{ lat: 22.7527421, lng: 75.88371599999999 }}
                            zoom={12}
                            mapName="meeting_point" 
                            places={{
                              multiple: formData.meeting_point_data,
                            }}
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={
                              <div style={{ height: `300px` }} />
                            }
                            mapElement={<div style={{ height: `100%` }} />}
                            onMapMounted={(map) =>
                              handleMapMounted(map, "meeting_point")
                            }
                            // onBoundsChanged={(map) => handleBoundsChanged(map, "meeting_point")}
                            handleGoogleAddress={handleGoogleAddress}
                            setIsDragged={setIsDragged}
                          />

                        </div>
                      </div>

                      {/* <p>Drag and drop the pin to make the location more accurate.</p> */}
                    </div>
                  </div>
                  {formData.product_type != "activity" && (
                    <>
                      <div className="hotel_checkshow">
                        <h6>
                          {t(
                            "_Does_this_tour_end_back_at_the_meeting_point(s)?"
                          )}
                        </h6>
                        <div className="hotel_radiodiv">
                          <input
                            type="radio"
                            className="productradio"
                            name="end_point"
                            onClick={(e) => handleChange(e, "end_point")}
                            defaultValue={true}
                            defaultChecked={
                              formData.end_point == true ? true : false
                            }
                          />
                          <span>{t("_Yes")}</span>
                        </div>

                        <div className="hotel_radiodiv">
                          <input
                            type="radio"
                            className="productradio"
                            name="end_point"
                            onClick={(e) => handleChange(e, "end_point")}
                            defaultValue={false}
                            defaultChecked={
                              formData.end_point == false ? true : false
                            }
                          />
                          <span>{t("_No")}</span>
                        </div>
                      </div>
                      {/* 
                      {
                        console.log("test", formData.end_point_data)
                      } */}

                      {formData.end_point == false &&
                        formData.end_point_data.map((value, index) => {
                          return (
                            <div key={index} className="mt-4">
                              <span>
                                {t("_End_Point")}
                                <span className="required-field">*</span>
                              </span>
                              <div className="meeting-pickup-locations">
                                <div
                                  key={index}
                                  className="mb-2 location-search-div enpoint-div"
                                  name={`end_point_location.${index}`}
                                  id={`end_point_location.${index}`}
                                >
                                  <div className="point-available-radio-btn">
                                    <input
                                      type="radio"
                                      className="productradio"
                                      name="end_point_available"
                                      onChange={(e) =>
                                        handleAvailableLPoint(
                                          e,
                                          "end_point_data",
                                          index
                                        )
                                      }
                                      // value="HOTEL_AREA"
                                      checked={
                                        value.end_point_available == "true"
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                  <div>
                                    <Geosuggest
                                      placeholder="Search Place here"
                                      onSuggestSelect={(place) => handleGoogleAddress(place, "end_point", index, "false")}
                                      initialValue={value.end_point_location}
                                    />
                                  </div>
                                </div>
                                <span
                                  className="cursor-pointer delete-icon"
                                  onClick={(e) =>
                                    removeLocation("end_point", index)
                                  }
                                >
                                  {index != 0 && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                                        fill="#fc5301"
                                      />
                                      <path
                                        d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                                        fill="#fc5301"
                                      />
                                      <path
                                        d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                                        fill="#fc5301"
                                      />
                                      <path
                                        d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                                        fill="#fc5301"
                                      />
                                    </svg>
                                  )}
                                </span>
                              </div>
                              {/* {
                                value.end_point_location_obj &&
                                <Map
                                  center={{ lat: value.end_point_latitude, lng: value.end_point_longitude }}
                                  zoom={12}
                                  places={{ lat: value.end_point_latitude, lng: value.end_point_longitude }}
                                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                                  loadingElement={<div style={{ height: `100%` }} />}
                                  containerElement={<div style={{ height: `300px` }} />}
                                  mapElement={<div style={{ height: `100%` }} />}
                                />
                              } */}

                              {/* <div className="search_autocomplete mb-4">
                              <Autocomplete
                                libraries={["places"]}
                                apiKey={googleKey}
                                className={`form-control input-text search_input`}
                                defaultValue={value.end_point_location}
                                onPlaceSelected={(place) => {
                                  handleGoogleAddress(place, 'end_point', index);
                                }}
                                options={{
                                  types: ["establishment"],
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                              <span>
                                <svg
                                  viewBox="0 0 18 18"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="LocationSelect__arrowIcon___3xu-b Icon__icon___2sbfU"
                                >
                                  <path
                                    d="M6.75 1.5a5.26 5.26 0 11-.01 10.51A5.26 5.26 0 016.75 1.5m0 12c1.6 0 3.07-.56 4.22-1.5l5.75 5.78a.75.75 0 101.06-1.06l-5.75-5.78a6.76 6.76 0 10-5.28 2.56"
                                    fillRule="evenodd"
                                  ></path>
                                </svg>
                              </span>
                            </div> */}

                              <textarea
                                className="mt-4"
                                name="end_point_description"
                                id="end_point_description"
                                rows="3"
                                placeholder="Please only include information about how to find the meeting point (e.g. Go to the corner of Stockton and Post Street and look for a guide wearing a red hat)"
                                onChange={(e) =>
                                  handleEndPointDescription(e, index)
                                }
                                defaultValue={value.end_point_description}
                              >
                                {/* {value.end_point_description} */}
                              </textarea>
                            </div>
                          );
                        })}

                      {formData.end_point == false && (
                        <>
                          <button
                            className="trasnparent_buton mt-2"
                            onClick={() => addMore("end_point")}
                          >
                            + {t("_add_a_location")}
                          </button>
                          <div className="map-inner">
                            <div className="google-map-code vishal">
                              <Map
                                center={{
                                  lat: 22.7527421,
                                  lng: 75.88371599999999,
                                }}
                                zoom={12}
                                mapName="end_point"
                                places={{
                                  multiple: formData.end_point_data,
                                }}
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                                loadingElement={
                                  <div style={{ height: `100%` }} />
                                }
                                containerElement={
                                  <div style={{ height: `300px` }} />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                                onMapMounted={(map) =>
                                  handleMapMounted(map, "end_point")
                                }
                                // onBoundsChanged={(map) => handleBoundsChanged(map, "end_point")}
                                handleGoogleAddress={handleGoogleAddress}
                                setIsDragged={setIsDragged}
                              />
                            </div>
                          </div>
                          {/* <div className="map-inner">
                            <div className="google-map-code vishal">
                              <Map
                                center={{ lat: 22.7527421, lng: 75.88371599999999 }}
                                zoom={12}
                                mapName="end_point"
                                places={{
                                  multiple: formData.end_point_data
                                }}
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `300px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                onMapMounted={(map) => handleMapMounted(map, "end_point")}
                                onBoundsChanged={(map) => handleBoundsChanged(map, "end_point")}
                              />
                            </div>
                          </div> */}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

            {/* {
            maindropdownvalue == 1 ?
              <>
                <FirstOptionSelectdiv />
              </>
              : maindropdownvalue == 2 ?
                <>
                  <div className="option_secondselectmaindiv">
                    <h4>Now tell us where you meet travelers</h4>
                    <SecondOptionSelectdiv
                    />
                  </div>
                </>
                : <>
                  <FirstOptionSelectdiv />
                  <div className='option_thirdselectmaindiv'>
                    <h4>Where will you meet travelers that don’t require pickup?</h4>
                    <SecondOptionSelectdiv />
                  </div>
                </>
          } */}

            {/* Button Last Save */}
            <div className="btn-last-save">
              <button onClick={() => submitData()} className="btn-all-save">
                {" "}
                {t("_Save_&_continue")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Meetingpickup;

// One time slot every 30 minutes.
