import Geosuggest from 'react-geosuggest';
import 'react-geosuggest/module/geosuggest.css';

function LocationSearchInput({ data }) {
    const { address, handleGoogleAddress, index, key } = data
    const handleSuggestSelect = (suggest) => {
        if (suggest) {
            const { location, label } = suggest;
            const place = {
                lat: location.lat,
                lng: location.lng,
                address: label
            };
            handleGoogleAddress(place, key, index, "false");
        }
    };

    return (
        <div>
            <Geosuggest
                placeholder="Search Place here"
                onSuggestSelect={(suggest) => handleSuggestSelect(suggest)}
                initialValue={address}
            />
        </div>
    );
}

export default LocationSearchInput;