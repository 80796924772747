import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, successMsg } from "./customFn";
import { profileData } from "./profileAction";

//   ===================API call for blogs starts=================
export const getProviderData = (
  languageReducer,
  setProviderData,
  navigate,
  setIsLoading,
  data
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");

      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        token: token,
        language: languageReducer.id,
        currency: currency.id,
        ...data,
      };
      const response = await axios.post("get-compnany-details", params);
      if (response.data.status === true) {
        setProviderData(response.data.data);
        setTimeout(function () {
          setIsLoading(false);
        }, 1000);
      } else {
        setTimeout(function () {
          setIsLoading(false);
        }, 1000);
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(function () {
    //   setIsLoading(false);
    // }, 1000);
  };
};
// ===================API call for blogs ends===================

//   ===================API upload starts=================
export const uploadProviderData = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("compnany-details", data);
      if (response.data.status === true) {
        successMsg(response.data.message);
        // setProviderData(response.data.data);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(function () {
    //     setIsLoading(true);
    // }, 1000);
  };
};
// ===================API uploads ends===================

//   ===================API provider orders starts=================
export const getProviderOrderList = (
  languageReducer,
  setOrderList,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage,
  filterData,
  isFilter
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: currentPage,
        is_filter: isFilter,
        ...filterData,
      };

      const response = await axios.post("partner-order-list", params);
      if (response.data.status === true) {
        setOrderList(response.data.data);
        setPageCount(response.data.page_count);
        setRecordStatus(true);
      } else {
        setOrderList([]);
        setRecordStatus(false);
        // errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
};
// ===================API provider orders ends===================

//   ===================API order Details starts=================
export const getProviderOrderDetails = (
  languageReducer,
  setOrderDetails,
  navigate,
  setIsLoading,
  id
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        order_id: id,
      };

      const response = await axios.post("partner-order-details", params);
      if (response.data.status === true) {
        setOrderDetails(response.data.data);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);
  };
};

// ===================API order Details ends===================
//   ===================API order Details starts=================
export const getProviderDashboardData = (
  languageReducer,
  setDashBoardData,
  navigate,
  setIsLoading,
  lastMonth,
  month
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        last_month: lastMonth,
        month: month,
      };

      const response = await axios.post("provider-dashboard", params);
      if (response.data.status === true) {
        setDashBoardData(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        errorMsg("Somthing went wrong...");
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================API order Details ends===================

//   ===================API provider Commission starts=================
export const getProviderCommissionList = (
  languageReducer,
  setOrderList,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage,
  filterData,
  isFilter
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: currentPage,
        is_filter: isFilter,
        ...filterData,
      };

      const response = await axios.post("partner-commission", params);
      if (response.data.status === true) {
        setOrderList(response.data.data);
        setPageCount(response.data.page_count);
        setRecordStatus(true);
      } else {
        setOrderList([]);
        setRecordStatus(false);
        // errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
};
// ===================API provider Commission ends===================

export const setProviderPaymentDetails = (
  languageReducer,
  formData,
  navigate,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...formData,
      };

      const response = await axios.post("update-paypal", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
        dispatch(profileData(languageReducer, navigate));
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        errorMsg(response.data.message);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
};

//   ===================Analytics starts=================
export const getAnalyticsData = (
  languageReducer,
  setAnalyticsData,
  navigate,
  setIsLoading,
  formData
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...formData,
      };

      const response = await axios.post("get-analytics-data", params);
      if (response.data.status === true) {
        setAnalyticsData(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        errorMsg("Somthing went wrong...");
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================Analtycs ends===================

export const getPartnerProductList = (
  languageReducer,
  navigate,
  setProductList,
  newEntry
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
      };

      const response = await axios.post("partner-products", params);
      if (response.data.status === true) {
        let products = response.data.data.products;
        products.unshift(newEntry);
        setProductList(products);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

// Get ReservationSystem
export const getSupplierReservationSystem = (
  setReservationData,
  languageReducer,
  navigate,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
      };

      const response = await axios.post(
        "get-supplier-reservation-system",
        params
      );
      if (response.data.status === true) {
        setReservationData(response.data.data);
        setIsLoading(false);
      } else {
        errorMsg("Somthing went wrong...");
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

// Update ReservationSystem
export const updateReservationData = (
  languageReducer,
  formData,
  navigate,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...formData,
      };

      const response = await axios.post(
        "update-supplier-reservation-system",
        params
      );
      if (response.data.status === true) {
        setIsLoading(false);
      } else {
        errorMsg("Somthing went wrong...");
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};


export const SentSecurityCode = (
  userEmail,
  languageReducer,
  navigate,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);

      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency?.id,
        token: token,
        email: userEmail,
      };

      const response = await axios.post("send_security_code", params);

      if (response?.data?.status === true) {
        successMsg(response?.data?.message);
        setIsLoading(false);
        return response;
      } else {
        setIsLoading(false);
        return response;
      }
    } catch (error) {
      errorMsg("An error occurred while sending the security code.");
      setIsLoading(false);
    }
  };
};

// Validate Security Code for Reservation System
export const checkSecurityCode = (
  userEmail,
  securityCode,
  languageReducer,
  navigate,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);

      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer?.id,
        currency: currency?.id,
        token: token,
        email: userEmail,
        security_code: securityCode,
      };

      const response = await axios.post("check_security_code", params);
      if (response?.data?.status === true) {
        successMsg(response?.data?.message);
        setIsLoading(false);
        return response;
      } else {
        setIsLoading(false);
        return response;
      }
    } catch (error) {
      errorMsg("An error occurred while sending the security code.");
      setIsLoading(false);
    }
  };
};