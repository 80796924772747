import { CommissionDetail, ProviderOrderDetails } from "../components";
import HotelUserCommissionDetails from "../components/HotelUser/HotelUserCommissionDetails";
import MyMaps from "../components/UserProfile/MyMap";
import {
  Home,
  CultureListing,
  Cart,
  ActivityProvider,
  UserProfile,
  ProviderAccount,
  AboutUs,
  Blog,
  Checkout,
  BlogDetails,
  Affiliate,
  ContactUs,
  Guide,
  TermsCondition,
  PrivacyPolicy,
  Partners,
  GuideDeails,
  AddTour,
  AffiliateUser,
  PageNotFound,
  OrderDetail,
  LastCartProduct,
  HelpCenter,
  AboutUsPage,
  SupplierNewPage,
  AfiliatesNewPage,
} from "../pages";
import AllSearch from "../pages/AllSearch";
import AttractionListing from "../pages/AttractionListing";
import ChangePassword from "../pages/ChangePassword";
import CultureDetailsNew from "../pages/CulturalDetailsNew";
import DestinationListing from "../pages/DestinationListing";
import HotelUser from "../pages/HotelUser";
import ProductBuilder from "../pages/ProductBuilder";
import ProductPreviewNew from "../pages/ProductPreviewNew";
import SupplyHelpCenter from "../pages/SupplyHelpCenter";
import AffiliateHelpCenter from "../pages/AffiliateHelpCenter";
import ThankYouPageNew from "../pages/ThankYouPageNew";
// import HelpCenter from "../pages/HelpCenter";

const RouteArr = [
  {
    name: "Home",
    path: "/",
    key: "home",
    route: "/",
    page: <Home />,
  },
  {
    name: "CultureListing",
    path: "/culture-listing/:category?",
    key: "culture-listing",
    route: "/culture-listing/:category?",
    page: <CultureListing />,
  },

  {
    name: "CultureDetails",
    path: "/culture-details/:id/:code?",
    key: "culture-details",
    route: "/culture-details/:id/:code?",
    // page: <CultureDetails />,
    page: <CultureDetailsNew />,
  },

  {
    name: "Product Preview",
    path: "/product-preview/:id/:user_id?/:user_type?",
    key: "product-preview",
    route: "/product-preview/:id/:user_id?/:user_type?",
    // page: <ProductPreview />,
    page: <ProductPreviewNew />,
  },

  {
    name: "Cart",
    path: "/cart",
    key: "cart",
    route: "/cart",
    page: <Cart />,
  },

  {
    name: "Cart Details",
    path: "/cart-details",
    key: "cart-details",
    route: "/cart-details",
    page: <LastCartProduct />,
  },

  {
    name: "AboutUs",
    path: "/about-us",
    key: "about-us",
    route: "/about-us",
    page: <AboutUsPage />,
  },

  {
    name: "Blog",
    path: "blog",
    key: "blog",
    route: "/blogs",
    page: <Blog />,
  },

  {
    name: "Checkout",
    path: "checkout",
    key: "checkout",
    route: "/checkout",
    page: <Checkout />,
  },

  {
    name: "ActivitySupplier",
    path: "/activity-supplier/:slug",
    key: "activity-suppliert",
    route: "/activity-supplier/:slug",
    page: <ActivityProvider />,
  },

  {
    name: "UserProfile",
    path: "/user-profile",
    key: "user-profile",
    route: "/user-profile",
    page: <UserProfile />,
    private: true,
    user_type: "Customer",
  },

  {
    name: "EditProfile",
    path: "/edit-profile",
    key: "edit-profile",
    route: "/edit-profile",
    private: true,
    page: <UserProfile />,
    user_type: "Customer",
  },

  {
    name: "Wishlist",
    path: "/wish-list",
    key: "wish-list",
    route: "/wish-list",
    private: true,
    page: <UserProfile />,
    user_type: "Customer",
  },

  {
    name: "Activity List",
    path: "/activity-list/:activityId?",
    key: "activity-list",
    route: "/activity-list/:activityId?",
    private: true,
    page: <UserProfile />,
    user_type: "Customer",
  },

  {
    name: "Customer Map",
    path: "/customer-map",
    key: "customer-map",
    route: "/customer-map",
    user_type: "Customer",
    private: true,
    page: <UserProfile />,
  },

  {
    name: "Wishlist",
    path: "/supplier-wishlist",
    key: "supplier-wishlist",
    route: "/supplier-wishlist",
    private: true,
    page: <ProviderAccount />,
    user_type: "Partner",
  },

  {
    name: "Commission History",
    path: "/supplier-commission-history",
    key: "supplier-commission-history",
    route: "/supplier-commission-history",
    private: true,
    page: <ProviderAccount />,
    user_type: "Partner",
  },

  {
    name: "Supplier Transaction History",
    path: "/supplier-transaction-history",
    key: "supplier-transaction-history",
    route: "/supplier-transaction-history",
    private: true,
    page: <ProviderAccount />,
    user_type: "Partner",
  },

  {
    name: "UpcomingBooking",
    path: "/upcoming-booking",
    key: "upcoming-booking",
    route: "/upcoming-booking",
    user_type: "Customer",
    private: true,
    page: <UserProfile />,
  },

  {
    name: "BookingHistory",
    path: "/booking-history",
    key: "booking-history",
    route: "/booking-history",
    user_type: "Customer",
    private: true,
    page: <UserProfile />,
  },

  {
    name: "PaymentDetails",
    path: "/payment-details",
    key: "payment-details",
    route: "/payment-details",
    private: true,
    page: <UserProfile />,
  },

  {
    name: "Become Affiliate",
    path: "/become-affiliate",
    key: "become-affiliate",
    route: "/become-affiliate",
    private: true,
    page: <UserProfile />,
    user_type: "Customer",
  },

  {
    name: "ProviderAccount",
    path: "/provider-account",
    key: "provider-account",
    route: "/provider-account",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "ProfileProvider",
    path: "/profile-supplier",
    key: "profile-supplier",
    route: "/profile-supplier",
    private: true,
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "MyTours",
    path: "/my-tours",
    key: "my-tours",
    route: "/my-tours",
    page: <ProviderAccount />,
    private: true,
    private: true,
    user_type: "Partner",
  },

  {
    name: "SupplierPage",
    path: "/supplier-page",
    key: "supplier-page",
    route: "/supplier-page",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "SupplierUpcoming",
    path: "/supplier-upcoming",
    key: "supplier-upcoming",
    route: "/supplier-upcoming",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "SupplierBooking",
    path: "/supplier-booking",
    key: "supplier-booking",
    route: "/supplier-booking",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "ToursFiles",
    path: "/tours-files",
    key: "tours-files",
    route: "/tours-files",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "My Map",
    path: "/provider-map",
    key: "provider-map",
    route: "/provider-map",
    user_type: "Partner",
    private: true,
    page: <ProviderAccount />,
  },

  {
    name: "OrderDetail",
    path: "/order-detail/:id",
    key: "order-detail",
    route: "/order-detail/:id",
    page: <OrderDetail />,
  },

  {
    name: "BlogDetails",
    path: "/blog-details/:slug",
    key: "blog-details",
    route: "/blog-details/:slug",
    page: <BlogDetails />,
  },

  {
    name: "Affiliate",
    path: "/affiliate",
    key: "affiliate",
    route: "/affiliate",
    page: <AfiliatesNewPage />,
  },

  {
    name: "ContactUs",
    path: "/contact-us",
    key: "contact-us",
    route: "/contact-us",
    page: <ContactUs />,
  },

  {
    name: "Guide",
    path: "/guide",
    key: "guide",
    route: "/guide",
    page: <Guide />,
  },
  {
    name: "TermsCondition",
    path: "/terms-condition",
    key: "terms-condition",
    route: "/terms-condition",
    page: <TermsCondition />,
  },

  {
    name: "PrivacyPolicy",
    path: "/privacy-policy",
    key: "privacy-policy",
    route: "/privacy-policy",
    page: <PrivacyPolicy />,
  },

  {
    name: "Partners",
    path: "/partners",
    key: "partners",
    route: "/partners",
    page: <SupplierNewPage />,
  },
  {
    name: "GuideDeails",
    path: "/guide-deails/:slug",
    key: "guide-deails",
    route: "/guide-deails/:slug",
    page: <GuideDeails />,
  },

  {
    name: "AddTour",
    path: "/add-tour",
    key: "add-tour",
    route: "/add-tour",
    page: <AddTour />,
  },

  {
    name: "AffiliateUser",
    path: "/affiliate-user",
    key: "affiliate-user",
    route: "/affiliate-user",
    private: true,
    user_type: "Affiliate",
    page: <AffiliateUser />,
  },
  {
    name: "Affiliate Transaction History",
    path: "/affiliate-transaction-history",
    key: "affiliate-transaction-history",
    route: "/affiliate-transaction-history",
    private: true,
    page: <AffiliateUser />,
    user_type: "Affiliate",
  },

  {
    name: "AffiliateProfile",
    path: "/affiliate-profile",
    key: "affiliate-profile",
    route: "/affiliate-profile",
    private: true,
    user_type: "Affiliate",
    page: <AffiliateUser />,
  },

  {
    name: "AffiliateMoney",
    path: "/affiliate-money",
    key: "affiliate-money",
    route: "/affiliate-money",
    private: true,
    user_type: "Affiliate",
    page: <AffiliateUser />,
  },

  {
    name: "AfiiliatePayment",
    path: "/affiliate-payment",
    key: "affiliate-payment",
    route: "/affiliate-payment",
    private: true,
    user_type: "Affiliate",
    page: <AffiliateUser />,
  },

  {
    name: "AffiliateReviews",
    path: "/affiliate-reviews",
    key: "affiliate-reviews",
    route: "/affiliate-reviews",
    user_type: "Affiliate",
    private: true,
    page: <AffiliateUser />,
  },

  {
    name: "CommissionHistory",
    path: "/commission-history",
    key: "commission-history",
    route: "/commission-history",
    private: true,
    user_type: "Affiliate",
    page: <AffiliateUser />,
  },

  {
    name: "Affilliate Wishlist",
    path: "/affiliate-wishlist",
    key: "affiliate-wishlist",
    route: "/affiliate-wishlist",
    private: true,
    user_type: "Affiliate",
    page: <AffiliateUser />,
  },

  {
    name: "AffiliateBookingHistory",
    path: "/affiliate-booking-history",
    key: "affiliate-booking-history",
    route: "/affiliate-booking-history",
    private: true,
    user_type: "Affiliate",
    page: <AffiliateUser />,
  },

  {
    name: "Affiliate Dashboard",
    path: "/affiliate-dashboard",
    key: "affiliate-dashboard",
    route: "/affiliate-dashboard",
    private: true,
    user_type: "Affiliate",
    page: <AffiliateUser />,
  },

  {
    name: "CouponList",
    path: "/coupon-list",
    key: "coupon-list",
    route: "/coupon-list",
    private: true,
    user_type: "Affiliate",
    page: <AffiliateUser />,
  },

  {
    name: "Affiliate Analytics",
    path: "/affiliate-analytics",
    key: "affiliate-analytics",
    route: "/affiliate-analytics",
    user_type: "Affiliate",
    private: true,
    page: <AffiliateUser />,
  },

  {
    name: "Affiliate Map",
    path: "/affiliate-map",
    key: "affiliate-map",
    route: "/affiliate-map",
    user_type: "Affiliate",
    private: true,
    page: <AffiliateUser />,
  },

  {
    name: "CommissionDetails",
    path: "/commission-details/:id",
    key: "commission-details",
    route: "/commission-details/:id",
    private: true,
    user_type: "Affiliate",
    page: <CommissionDetail />,
  },

  {
    name: "DestinationListing",
    path: "/destination-listing/:slug?",
    key: "destination-listing",
    route: "/destination-listing/:slug?",
    page: <DestinationListing />,
  },

  {
    name: "Title",
    path: "/tours-files/title/:tour_id",
    key: "title",
    route: "/tours-files/title/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "Location",
    path: "/tours-files/location/:tour_id",
    key: "location",
    route: "/tours-files/location/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "Transportation",
    path: "/tours-files/transportation/:tour_id",
    key: "transportation",
    route: "/tours-files/transportation/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "Inclusion",
    path: "/tours-files/inclusion/:tour_id",
    key: "inclusion",
    route: "/tours-files/inclusion/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "Information",
    path: "/tours-files/information/:tour_id",
    key: "information",
    route: "/tours-files/information/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "AboutActivity",
    path: "/tours-files/about-activity/:tour_id",
    key: "AboutActivity",
    route: "/tours-files/about-activity/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "Option",
    path: "/tours-files/option/:tour_id",
    key: "Option",
    route: "/tours-files/option/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "Photos",
    path: "/tours-files/photos/:tour_id",
    key: "Photos",
    route: "/tours-files/photos/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "Food",
    path: "/tours-files/food/:tour_id",
    key: "Food",
    route: "/tours-files/food/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "ActivityInfo",
    path: "/tours-files/activity-info/:tour_id",
    key: "ActivityInfo",
    route: "/tours-files/activity-info/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "Keyword",
    path: "/tours-files/keyword/:tour_id",
    key: "Keyword",
    route: "/tours-files/keyword/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "OptionSetup",
    path: "/tours-files/option-setup/:tour_id/:option_id",
    key: "OptionSetup",
    route: "/tours-files/option-setup/:tour_id/:option_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "OptionPricing",
    path: "/tours-files/option-pricing/:tour_id/:option_id",
    key: "option-pricing",
    route: "/tours-files/option-pricing/:tour_id/:option_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "ProductOthers",
    path: "/tours-files/others/:tour_id",
    key: "others",
    route: "/tours-files/others/:tour_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "OptionPricing",
    path: "/tours-files/option-pricing/:tour_id/:option_id",
    key: "option-pricing",
    route: "/tours-files/option-pricing/:tour_id/:option_id",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "SupplierOrders",
    path: "/supplier-orders",
    key: "supplier-orders",
    route: "/supplier-orders",
    page: <ProviderAccount />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    key: "dashboard",
    route: "/dashboard",
    user_type: "Partner",
    private: true,
    page: <ProviderAccount />,
  },

  {
    name: "reservation-system",
    path: "/supplier-payment",
    key: "Supplier-Payment",
    route: "/supplier-payment",
    private: true,
    user_type: "Partner",
    page: <ProviderAccount />,
  },

  {
    name: "Reservation System",
    path: "/reservation-system",
    key: "Reservation System",
    route: "/reservation-system",
    private: true,
    user_type: "Partner",
    page: <ProviderAccount />,
  },

  {
    name: "Provider Analytics",
    path: "/provider-analytics",
    key: "provider-analytics",
    route: "/provider-analytics",
    user_type: "Partner",
    private: true,
    page: <ProviderAccount />,
  },
  {
    name: "Provider Reviews",
    path: "/provider-reviews",
    key: "provider-reviews",
    route: "/provider-reviews",
    user_type: "Partner",
    private: true,
    page: <ProviderAccount />,
  },

  {
    name: "ProviderOrderDetails",
    path: "/supplier-order-details/:id",
    key: "supplier-order-details",
    route: "/supplier-order-details/:id",
    private: true,
    user_type: "Partner",
    page: <ProviderOrderDetails />,
  },

  {
    name: "AttractionListing",
    path: "/attraction-listing/:attraction_slug",
    key: "attraction-listing",
    route: "/attraction-listing/:attraction_slug",
    page: <AttractionListing />,
  },

  {
    name: "Change Password",
    path: "/change-password/:id",
    key: "change-password",
    route: "/change-password/:id",
    page: <ChangePassword />,
    private: false,
  },

  {
    name: "ThankYou Page",
    path: "/thank-you",
    key: "thank-you",
    route: "/thank-you",
    // page: <ThankYouPage />,
    page: <ThankYouPageNew />,
    private: false,
  },

  {
    name: "HotelUser",
    path: "/hotel-user",
    key: "hotel-user",
    route: "/hotel-user",
    private: true,
    page: <HotelUser />,
    user_type: "Hotel",
  },

  {
    name: "Hotel User Dashboard",
    path: "/hotel-user-dashboard",
    key: "hotel-user-dashboard",
    route: "/hotel-user-dashboard",
    private: true,
    page: <HotelUser />,
    user_type: "Hotel",
  },

  {
    name: "Hotel User Account",
    path: "/hotel-user-account",
    key: "hotel-user-account",
    route: "/hotel-user-account",
    private: true,
    page: <HotelUser />,
    user_type: "Hotel",
  },
  {
    name: "Hotel User Transaction History",
    path: "/hotel-user-transaction-history",
    key: "hotel-user-transaction-history",
    route: "/hotel-user-transaction-history",
    private: true,
    page: <HotelUser />,
    user_type: "Hotel",
  },
  {
    name: "Hotel User Commission History",
    path: "/hotel-user-commission-history",
    key: "hotel-user-commission-history",
    route: "/hotel-user-commission-history",
    private: true,
    page: <HotelUser />,
    user_type: "Hotel",
  },

  {
    name: "Hotel User Analytics",
    path: "/hotel-user-analytics",
    key: "hotel-user-analytics",
    route: "/hotel-user-analytics",
    user_type: "Hotel",
    private: true,
    page: <HotelUser />,
  },

  {
    name: "Hotel User Commission Details",
    path: "/hotel-user-commission-details/:id",
    key: "hotel-user-commission-details",
    route: "/hotel-user-commission-details/:id",
    private: true,
    page: <HotelUserCommissionDetails />,
    user_type: "Hotel",
  },

  {
    name: "ProductBuilder",
    path: "/product-bulider",
    key: "product-bulider",
    route: "/product-bulider",
    private: false,
    page: (
      <>
        <ProductBuilder />
      </>
    ),
  },

  {
    name: "Title",
    path: "/product-bulider/product-language-title/:tour_id?",
    key: "title",
    route: "/product-bulider/product-language-title/:tour_id?",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "Categorization",
    path: "/product-bulider/categorization/:tour_id",
    key: "Categorization",
    route: "/product-bulider/categorization/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "Photos",
    path: "/product-bulider/photos/:tour_id",
    key: "Photos",
    route: "/product-bulider/photos/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "Meetingpickup",
    path: "/product-bulider/meeting-pickup/:tour_id",
    key: "Meetingpickup",
    route: "/product-bulider/meeting-pickup/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "MoreContent",
    path: "/product-bulider/more-content/:tour_id",
    key: "MoreContent",
    route: "/product-bulider/more-content/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "ActivityDetail",
    path: "/product-bulider/activity-detail/:tour_id",
    key: "ActivityDetail",
    route: "/product-bulider/activity-detail/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "GuideActivityInfo",
    path: "/product-bulider/guide-activity-info/:tour_id",
    key: "GuideActivityInfo",
    route: "/product-bulider/guide-activity-info/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "InclusionExclusion",
    path: "/product-bulider/inclusion-exclusion/:tour_id",
    key: "InclusionExclusion",
    route: "/product-bulider/inclusion-exclusion/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "UniqueProduct",
    path: "/product-bulider/unique-product/:tour_id",
    key: "UniqueProduct",
    route: "/product-bulider/unique-product/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "PhysicalRestriction",
    path: "/product-bulider/physical-restriction/:tour_id",
    key: "PhysicalRestriction",
    route: "/product-bulider/physical-restriction/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "TravellerDetails",
    path: "/product-bulider/traveller-details/:tour_id",
    key: "TravellerDetails",
    route: "/product-bulider/traveller-details/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "PriceSchedule",
    path: "/product-bulider/price-schedule/:tour_id",
    key: "PriceSchedule",
    route: "/product-bulider/price-schedule/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "BookingProcess",
    path: "/product-bulider/booking-process/:tour_id",
    key: "BookingProcess",
    route: "/product-bulider/booking-process/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "CancellingPolicy",
    path: "/product-bulider/cancelling-policy/:tour_id",
    key: "CancellingPolicy",
    route: "/product-bulider/cancelling-policy/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "TravellerRequired",
    path: "/product-bulider/traveller-required/:tour_id",
    key: "TravellerRequired",
    route: "/product-bulider/traveller-required/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "TicketBuilder",
    path: "/product-bulider/ticket-builder/:tour_id",
    key: "TicketBuilder",
    route: "/product-bulider/ticket-builder/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },
  {
    name: "TicketRedemption",
    path: "/product-bulider/ticket-redemption/:tour_id",
    key: "TicketRedemption",
    route: "/product-bulider/ticket-redemption/:tour_id",
    page: <ProductBuilder />,
    private: true,
    user_type: "Partner",
  },

  {
    name: "All Search",
    path: "/all-search/:slug?",
    key: "all-search",
    route: "/all-search/:slug?",
    page: <AllSearch />,
  },

  {
    name: "SupplyHelpCenter",
    path: "/supplyhelp-center",
    key: "supplyhelp-center",
    route: "/supplyhelp-center",
    page: <SupplyHelpCenter />,
  },
  {
    name: "HelpCenter",
    path: "/help-center",
    key: "help-center",
    route: "/help-center",
    page: <HelpCenter />,
  },
  {
    name: "AffiliateHelpCenter",
    path: "/affiliate-help-center",
    key: "affiliate-help-center",
    route: "/affiliate-help-center",
    page: <AffiliateHelpCenter />,
  },

  {
    name: "Page-Not-Found",
    path: "/page-not-found",
    key: "page-not-found",
    route: "*",
    private: false,
    page: (
      <>
        <PageNotFound />
      </>
    ),
  },

  {
    name: "AboutUsPage",
    path: "/about-us-page",
    key: "about-us-page",
    route: "/about-us-page",
    page: <AboutUsPage />,
  },
  {
    name: "SupplierNewPage",
    path: "/supplier-new-page",
    key: "supplier-new-page",
    route: "/supplier-new-page",
    page: <SupplierNewPage />,
  },

  {
    name: "AfiliatesNewPage",
    path: "/afiliates-new-page",
    key: "afiliates-new-page",
    route: "/afiliates-new-page",
    page: <AfiliatesNewPage />,
  },
];

export default RouteArr;
