import { useEffect, useState } from "react";
import { HeaderSticky, LoadingBar, LoginModal } from "../components";
import { NavLink, useNavigate } from "react-router-dom";
import {
  CountdownTimer,
  MetaData,
  errorMsg,
  handleScrollError,
  images,
  startSpinLoader,
  stopSpinLoader,
  successMsg,
} from "../actions/customFn";
import { motion } from "framer-motion";
import {
  checkProduct,
  getCartData,
  reservationTimeEndsAPI,
} from "../actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box } from "@mui/material";
import { getStateList } from "../actions/stateAction";
import { getCityList } from "../actions/cityAction";
import TextField from "@mui/material/TextField";
import { placeOrder } from "../actions/checkoutAction";
import validator from "validator";
import RecordNotFound from "./RecordNotFound";
import axios from "../axios";
import { setLogin } from "../actions/loginAction";
import { useContext } from "react";
import LocaleContext from "../components/LocaleContext";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../assets/StripePayment/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import LoadingOverlay from "react-loading-overlay";
import { PuffLoader } from "react-spinners";
import { t } from "i18next";
// import { images } from '../../actions/customFn';

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const phoneCodeReducer = useSelector((state) => state.phoneCodeReducer);
  const languageReducer = useSelector((state) => state.languageReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [cartData, setCartData] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [checkOutTotal, setCheckOutTotal] = useState([]);
  const [recordStatus, setRecordStatus] = useState(true);
  const homeData = useSelector((state) => state.home);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const checklogin = useSelector((state) => state.login);
  const [loginCheck, setLoginCheck] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [ModalLogin, setModalLogin] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [couponCheck, setCouponCheck] = useState(true);
  const { cartLoader, setCartLoader } = useContext(LocaleContext);
  const profileReducer = useSelector((state) => state.profileReducer);
  const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [countryFlag, setCountryFlag] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [cartTaxFee, setcartTaxFee] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const { SetLoginKey, loginKey } = useContext(LocaleContext);

  let paymentInfo = {};
  // =========== stripe data===========
  const PUBLISHABLE_KEY = homeData?.settings?.stripe_publish_key;
  const PAYPAL_CLIENT_ID = homeData?.settings?.paypal_client_id;
  const stripePromise = PUBLISHABLE_KEY ? loadStripe(PUBLISHABLE_KEY) : "";
  const options = {
    mode: "payment",
    // amount: Math.round(chargesInfo.amountTotal),
    amount: Math.round(100),
    currency: "usd",
  };

  const generateStripeToken = (token) => {
    // setPaymentLoader(true);
    if (formData.payment_method == "stripe") {
      paymentInfo = { stripe_token: token };
    } else {
      paymentInfo = { google_pay_token: token };
    }
    bookOrder(paymentInfo);
  };

  // =========== stripe data===========

  // =========== PAYPAL data===========

  const initialOptions = {
    "client-id": PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  // =========== PAYPAL data===========

  // =========== google Pay data===========
  // const googlePayResposne = (data) => {
  //   console.log("data");
  //   paymentInfo = { "googlePay_details": data }                   //card used : 4273149019799094
  //   bookOrder(paymentInfo);
  // }
  // =========== google Pay data===========

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    image: "",
    country: "",
    state: "",
    city: "",
    address: "",
    zipcode: "",
    password: "",
    confirm_password: "",
    coupon: "",
    payment_method: "",
    phone_code: "",
  });

  const [formErrors, setFormError] = useState({});
  const errors = {};

  useEffect(() => {
    if (profileReducer !== "") {
      if (profileReducer.user_type == "Hotel") {
        navigate("/");
        return;
      }
      setFormData({
        first_name: profileReducer.first_name,
        last_name: profileReducer.last_name,
        phone: profileReducer.phone_number,
        email: profileReducer.email,
        country: profileReducer.country,
        state: profileReducer.state,
        city: profileReducer.city,
        address: profileReducer.address,
        zipcode: profileReducer.zipcode,
        image: profileReducer.image,
        phone_code: profileReducer.phone_code,
        change_password: 0,
        old_password: "",
        new_password: "",
        confirm_password: "",
        coupon: "",
      });
    }
  }, [profileReducer, checklogin, profileReducer]);

  useEffect(() => {
    if (Object.keys(cartData).length > 0) {
      setCartItems(cartData.detail);
      setcartTaxFee(cartData.cart_tax_fee);
    } else {
      setCartItems([]);
      setcartTaxFee([]);
    }
  }, [cartData]);

  useEffect(() => {
    if (languageReducer !== "") {
      setCartLoader(true);
      dispatch(
        getCartData(
          languageReducer,
          setCartData,
          navigate,
          setRecordStatus,
          setCartLoader,
          coupon
        )
      );
    }
  }, [languageReducer, dispatch, couponCheck, checklogin, toggle]);

  useEffect(() => {
    if (Object.keys(cartData).length > 0) {
      if (cartData.is_booking_allow == true) {
        setCartItems(cartData.detail);
        setCheckOutTotal(cartData.checkout);
      } else {
        navigate("/cart");
      }
    }
  }, [cartData]);


  // ===============Checkout=============
  useEffect(() => {
    if (homeData) {
      setCountryList(homeData.countries);
      setIsLoading(true);
    }
  }, [homeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyNumbers = /^[0-9]*$/;
    const zipcodePattern = /^[0-9-]*$/;

    if (name === "phone") {
      if (onlyNumbers.test(value) === true) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else if (name === "zipcode") {
      if (zipcodePattern.test(value) === true) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    if (formData.country !== "") {
      dispatch(getStateList(setStateList, formData.country));
    }
  }, [homeData, formData.country]);

  useEffect(() => {
    if (formData.state !== "") {
      dispatch(getCityList(setCityList, formData.state));
    }
  }, [formData.state]);

  const handleCountry = (event, data) => {
    if (data) {
      const { id } = data;
      setFormData((prev) => ({ ...prev, country: id }));
      setFormData((prev) => ({ ...prev, state: "" }));
      setFormData((prev) => ({ ...prev, city: "" }));
      setCityList([]);
      dispatch(getStateList(setStateList, id));
    }
  };

  const handleState = (event, data) => {
    if (data) {
      const { id } = data;
      setFormData((prev) => ({ ...prev, state: id }));
      setFormData((prev) => ({ ...prev, city: "" }));
      dispatch(getCityList(setCityList, id));
    }
  };

  const handleCity = (event, data) => {
    if (data) {
      const { id } = data;
      setFormData((prev) => ({ ...prev, city: id }));
    }
  };

  //=================Form Validation starts===============
  const validateFormData = () => {
    if (formData.first_name === "") {
      errors.first_name = t("_enter_first_name");
    }

    if (formData.last_name === "") {
      errors.last_name = t("_enter_last_name");
    }

    if (!validator.isEmail(formData.email) || formData.email.length === 0) {
      errors.email = t("_enter_valid_email_id");
    }

    if (formData.country === "") {
      errors.country = t("_select_country");
    }
    if (formData.state === "") {
      errors.state = t("_select_state");
    }
    if (formData.city === "") {
      errors.city = t("_select_city");
    }

    if (formData.phone == "") {
      errors.phone = t("_enter_phone_number");
    } else if (!validator.isNumeric(formData.phone)) {
      errors.phone = t("_phone_number_must_be_numeric");
    } else if (formData.phone.length < 7 || formData.phone.length > 15) {
      errors.phone = t("_phone_number_must_be_of_6_to_15_digit");
    }

    if (formData.zipcode === "") {
      errors.zipcode = t("_enter_zipcode");
    }

    if (formData.address === "") {
      errors.address = t("_enter_address");
    }

    setFormError(errors);
  };
  //=================Form Validation ends===============

  const bookOrder = (paymentInfo) => {
    validateFormData();
    if (Object.keys(errors).length === 0) {
      setPaymentLoader(true);
      startSpinLoader("cover-spin");
      dispatch(
        placeOrder(
          languageReducer,
          formData,
          navigate,
          setIsLoading,
          paymentInfo,
          setPaymentLoader
        )
      );
    } else {
      setFormError(errors);
    }
  };

  useEffect(() => {
    if (paymentLoader == false) {
      stopSpinLoader("cover-spin");
    }
  }, [paymentLoader]);

  // ======================Ckeckout ends=================

  const login = () => {
    setModalLogin(true);
    SetLoginKey("login");
  };

  const closeLoginModal = () => {
    setModalLogin(false);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    validateFormData();

    if (Object.keys(errors).length === 0) {
      formData.name = formData.first_name;
      formData.phone_number = formData.phone;
      formData.language = "en";
      formData.currency = localStorage.getItem("currency");
      formData.token = localStorage.getItem("_token");
      formData.user_type = "Customer";

      const url = "/signup";
      axios
        .post(url, formData)
        .then((response) => {
          const data = response.data;
          if (data.status) {
            successMsg(data.message);
            localStorage.setItem("userId", data.user_id);
            localStorage.setItem("password", data.password);
            dispatch(setLogin());
          } else {
            errorMsg(data.message);
          }
        })
        .catch((err) => {
          if (err.response.status === 402) {
            errorMsg(err.response.data.message);
          } else {
            navigate("/something-went-wrong");
            window.history.pushState(null, "", "/");
          }
        });
    } else {
      setTimeout(() => {
        handleScrollError(errors); //Scroll the page too the error message
      }, 500);
    }
  };

  const applyCoupon = () => {
    if (coupon == "") {
      errorMsg(t("_enter_coupon_code"));
    } else {
      // setCouponApplied(true);
      setFormData((prev) => ({ ...prev, coupon: coupon, payment_method: "" }));
      setCouponCheck((state) => !state);
    }
  };
  useEffect(() => {
    const getTotalAmountData = cartData?.checkout?.filter(
      (list) => list.title == "Total"
    )[0].new_amount;

    if (getTotalAmountData != undefined) {
      if (getTotalAmountData == 0) {
        setFormData((formData) => ({
          ...formData,
          payment_method: "free_order",
        }));
      }
      setTotalAmount(getTotalAmountData);
    }

    cartData.coupon_flag == 1 &&
      formData.coupon !== "" &&
      setCouponApplied(true);
  }, [cartData]);

  const removeCoupon = () => {
    setCouponApplied(false);
    setCouponCheck((state) => !state);
    setCoupon("");
    setFormData((prev) => ({ ...prev, coupon: "", payment_method: "" }));
  };

  const [productAvailabiltyMsg, setProductAvailabiltyMsg] = useState("");
  const chanegPaymentMethod = async (e) => {
    try {
      validateFormData();
      if (Object.keys(errors).length == 0) {
        if (e.target != undefined) {
          const { name, value } = e.target;
          setFormData((formData) => ({ ...formData, [name]: value }));
          // if (value == "stripe") {
          //   setFormData((formData) => ({ ...formData, [name]: value }));
          // }
          // if (value == "paypal") {
          //   setFormData((formData) => ({ ...formData, [name]: value }));
          // } else {
          //   setFormData((formData) => ({ ...formData, [name]: value }));
          // }
        }

        const response = await checkProduct(
          languageReducer,
          navigate,
          dispatch
        ); //To check product availibilty to book
        if (response.data.status == true) {
          setProductAvailabiltyMsg("");
        } else {
          setProductAvailabiltyMsg(
            "Some of product in your cart not available for booking."
          );
        }
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ============PhoneCode code starts===========
  useEffect(() => {
    if (phoneCodeReducer) {
      setPhoneCodeList(phoneCodeReducer);
    }
  }, [phoneCodeReducer]);

  // ===============Setting country flag image on initial load starts=============
  useEffect(() => {
    if (formData.phone_code && phoneCodeList && phoneCodeList.length > 0) {
      setFlag();
    }
  }, [formData.phone_code, phoneCodeList]);

  const [userPhoneCode, setUserPhoneCode] = useState("");

  const setFlag = () => {
    if (phoneCodeList) {
      let object = phoneCodeList.find(
        (option) => option.code == formData.phone_code
      );
      setCountryFlag(object.flag);
      setUserPhoneCode({
        phoneCode: object.code,
        flag: object.flag,
        label: object.label,
      });
    }
  };
  // ===============Setting country flag image on initial load ends==============

  const handlePhoneCode = (event, data) => {
    if (data) {
      const { code } = data;
      setFormData((prev) => ({ ...prev, phone_code: code }));
    } else {
      setCountryFlag("");
    }
  };
  // ============PhoneCode code ends===========

  let metaData = {
    title: t("_checkout"),
  };

  const reservationTimeEnds = (cart_id) => {
    dispatch(
      reservationTimeEndsAPI(cart_id, languageReducer, navigate, setToggle)
    );
  };

  const bookFreeOrder = () => {
    bookOrder("free_order");
  };
  return (
    <>
      <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
        {metaData && <MetaData data={metaData} />}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        ></motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        >
          <HeaderSticky />
          <div className="Checkout_main_page">
            <div id="cover-spin"></div>
            <div className="container">
              <div className="list_Breadcrumb">
                <ul>
                  <li>
                    <NavLink to="/"> {t("_home")} / </NavLink>
                    <NavLink to="/cart">{t("_cart")} / </NavLink>
                    {t("_checkout")}
                  </li>
                </ul>
              </div>

              {/* {
              cartLoader === true ?
                <div className="blinking-bar-loader">
                  <LoadingBar />
                </div>
                : ""
            } */}
              {Object.keys(cartData).length > 0 ? (
                <div className="row">
                  <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                    <div className="billing_details_main">
                      <div className="user_edit_main">
                        <h2>{t("_billing_details")}</h2>
                        <div className="input_all_edit">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                              <label className="label_all mb-2">
                                {t("_first_name")}{" "}
                              </label>
                              <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                value={formData.first_name}
                                placeholder={t("_enter_first_name")}
                                // className="form-control"
                                className={
                                  `form-control  ` +
                                  (formErrors.first_name ? " is-invalid" : "")
                                }
                                onChange={handleChange}
                              />
                              {formErrors.first_name && (
                                <div className="invalid-feedback">
                                  {formErrors.first_name}
                                </div>
                              )}
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                              <label className="label_all mb-2">
                                {t("_last_name")}{" "}
                              </label>
                              <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                value={formData.last_name}
                                placeholder={t("_enter_last_name")}
                                // className="form-control"
                                className={
                                  `form-control ` +
                                  (formErrors.last_name ? " is-invalid" : "")
                                }
                                onChange={handleChange}
                              />
                              {formErrors.last_name && (
                                <div className="invalid-feedback">
                                  {formErrors.last_name}
                                </div>
                              )}
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                              <label className="label_all mb-2">
                                {t("_email")}{" "}
                              </label>
                              <input
                                type="text"
                                id="email"
                                name="email"
                                value={formData.email}
                                placeholder={t("_enter_email")}
                                className={
                                  `form-control ` +
                                  (formErrors.email ? " is-invalid" : "")
                                }
                                onChange={handleChange}
                              />
                              {formErrors.email && (
                                <div className="invalid-feedback">
                                  {formErrors.email}
                                </div>
                              )}
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                              <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                  <label className="label_all mb-2">
                                    {t("_country_code")}
                                  </label>
                                  {phoneCodeList &&
                                    phoneCodeList.length > 0 && (
                                      <Autocomplete
                                        id="country-select-demo"
                                        className="new_filter_select"
                                        sx={{ width: 180 }}
                                        options={phoneCodeList}
                                        onChange={(event, data) =>
                                          handlePhoneCode(event, data)
                                        }
                                        autoHighlight
                                        getOptionLabel={(option) =>
                                          option.code + " " + option.label
                                        }
                                        value={
                                          formData.phone_code
                                            ? phoneCodeList.find(
                                              (obj) =>
                                                obj.code ==
                                                parseInt(formData.phone_code)
                                            )
                                            : null
                                        }
                                        renderOption={(props, option) => (
                                          <Box
                                            component="li"
                                            sx={{
                                              "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                            }}
                                            {...props}
                                          >
                                            <img
                                              src={option.flag}
                                              style={{ width: 25 }}
                                              alt=""
                                            />
                                            {option.label} +({option.code})
                                            {option.phone}
                                          </Box>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className="country-code-title"
                                            placeholder={t(
                                              "_select_country_code"
                                            )}
                                            InputProps={{
                                              ...params.InputProps,
                                              startAdornment: (
                                                <>
                                                  {userPhoneCode && (
                                                    <>
                                                      <img
                                                        src={userPhoneCode.flag}
                                                        alt=""
                                                        style={{ width: 25 }}
                                                      />{" "}
                                                      {/* {userPhoneCode.phoneCode} {" "} {userPhoneCode.label} */}
                                                    </>
                                                  )}
                                                </>
                                              ),
                                            }}
                                          // autoComplete: 'new-password', // disable autocomplete and autofill
                                          />
                                        )}
                                      />
                                    )}
                                  {formErrors.phone_code && (
                                    <div className="invalid-feedback">
                                      {formErrors.phone_code}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                  <label className="label_all mb-2">
                                    {t("_phone_number")}
                                  </label>
                                  <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    placeholder={t("_enter_your_phone_number")}
                                    className={
                                      `form-control ` +
                                      (formErrors.phone ? " is-invalid" : "")
                                    }
                                    onChange={handleChange}
                                  />
                                  {formErrors.phone && (
                                    <div className="invalid-feedback">
                                      {formErrors.phone}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                              <label className="label_all mb-2">
                                {t("_country")}{" "}
                              </label>
                              {countryList !== undefined &&
                                countryList.length > 0 && (
                                  <>
                                    <Autocomplete
                                      className="new_filter_select"
                                      disablePortal
                                      id="country"
                                      name="country"
                                      options={countryList}
                                      value={
                                        countryList.length > 0 &&
                                          formData.country != ""
                                          ? countryList.find(
                                            (obj) =>
                                              obj.id == formData.country
                                          )
                                          : ""
                                      }
                                      onChange={(event, data) =>
                                        handleCountry(event, data)
                                      }
                                      renderInput={(params) => (
                                        <TextField {...params} label="" />
                                      )}
                                    />
                                  </>
                                )}
                              {formErrors.country && (
                                <div className="invalid-feedback">
                                  {formErrors.country}
                                </div>
                              )}
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                              <label className="label_all mb-2">
                                {t("_state")}{" "}
                              </label>
                              <Autocomplete
                                className="new_filter_select"
                                disablePortal
                                id="state"
                                name="state"
                                options={stateList}
                                value={
                                  stateList.length > 0 && formData.state != ""
                                    ? stateList.find(
                                      (obj) => obj.id === formData.state
                                    )
                                    : ""
                                }
                                onChange={(event, data) =>
                                  handleState(event, data)
                                }
                                renderInput={(params) => (
                                  <TextField {...params} label="" />
                                )}
                              />
                              {formErrors.state && (
                                <div className="invalid-feedback">
                                  {formErrors.state}
                                </div>
                              )}
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                              <label className="label_all mb-2">
                                {t("_state")}{" "}
                              </label>
                              <Autocomplete
                                className="new_filter_select"
                                disablePortal
                                id="city"
                                name="city"
                                options={cityList}
                                value={
                                  cityList.length > 0 && formData.city != ""
                                    ? cityList.find(
                                      (obj) => obj.id === formData.city
                                    )
                                    : ""
                                }
                                onChange={(event, data) =>
                                  handleCity(event, data)
                                }
                                renderInput={(params) => (
                                  <TextField {...params} label="" />
                                )}
                              />
                              {formErrors.city && (
                                <div className="invalid-feedback">
                                  {formErrors.city}
                                </div>
                              )}
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                              <label className="label_all mb-2">
                                {t("_zipcode")}{" "}
                              </label>
                              <input
                                type="text"
                                id="zipcode"
                                name="zipcode"
                                placeholder="Zip Code"
                                value={formData.zipcode}
                                // className="form-control"
                                className={
                                  `form-control ` +
                                  (formErrors.zipcode ? " is-invalid" : "")
                                }
                                onChange={handleChange}
                              />
                              {formErrors.zipcode && (
                                <div className="invalid-feedback">
                                  {formErrors.zipcode}
                                </div>
                              )}
                            </div>

                            <div className="col-12">
                              <label className="label_all mb-2">
                                {t("_street_address")}{" "}
                              </label>
                              <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                placeholder="Street Address"
                                // className="form-control"
                                className={
                                  `form-control ` +
                                  (formErrors.address ? " is-invalid" : "")
                                }
                                onChange={handleChange}
                              />
                              {formErrors.address && (
                                <div className="invalid-feedback">
                                  {formErrors.address}
                                </div>
                              )}
                            </div>

                            {checklogin === false &&
                              createAccount &&
                              loginCheck === false && (
                                <>
                                  <div className="col-12 mt-3">
                                    <label>{t("_password")}</label>
                                    <input
                                      type="password"
                                      className={
                                        `form-control  ` +
                                        (formErrors.password
                                          ? "is-invalid"
                                          : "")
                                      }
                                      onChange={handleChange}
                                      value={formData.password}
                                      name="password"
                                      placeholder={t("_enter_your_password")}
                                    />
                                    {formErrors.password && (
                                      <div className="invalid-feedback">
                                        {formErrors.password}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-12 mt-3">
                                    <label>{t("_confirm_password")}</label>
                                    <input
                                      type="password"
                                      className={
                                        `form-control  ` +
                                        (formErrors.confirm_password
                                          ? "is-invalid"
                                          : "")
                                      }
                                      onChange={handleChange}
                                      value={formData.confirm_password}
                                      name="confirm_password"
                                      placeholder={t(
                                        "_enter_your__confirm_password"
                                      )}
                                    />
                                    {formErrors.confirm_password && (
                                      <div className="invalid-feedback">
                                        {formErrors.confirm_password}
                                      </div>
                                    )}

                                    <div className="sign-up_btn mt-4">
                                      <button onClick={handleSignup}>
                                        {t("_signup")}
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        </div>

                        {checklogin ? (
                          ""
                        ) : (
                          <>
                            {/* {
                          loginCheck ? (
                            <div className="login_btn mt-4">
                              <button onClick={() => login()}>Login</button>
                            </div>
                          ) : (
                            <>
                              {createAccount === false && (
                                <div className="create_account_btn mt-4">
                                  <button
                                    onClick={() => {
                                      setCreateAccount(true);
                                      setLoginCheck(false);
                                    }}
                                  >
                                    Create new account
                                  </button>
                                </div>
                              )}
                            </>
                          )} */}
                            {/* <div className="col-12 mt-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onChange={(e) =>
                                  setLoginCheck(e.target.checked)
                                }
                              />
                              <label
                                className="form-check-label label_all mb-2"
                                for="flexCheckDefault"
                              >
                                Already have an account
                              </label>
                            </div>
                          </div> */}
                            <div className="col-12 mt-3">
                              <p className="font-weight-bold text-capitalize text-danger">
                                ({t("_login_to_make_payment")})
                              </p>
                              <label
                                className="form-check-label label_all mb-2"
                                for="flexCheckDefault"
                              >
                                {t("_already_have_an_account")}{" "}
                                <NavLink
                                  to=""
                                  className="checkout_login"
                                  onClick={() => login()}
                                >
                                  {t("_login")}
                                </NavLink>
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                      {checklogin && (
                        <div className="payment_method ">
                          <div className="user_edit_main">
                            <h2>{t("_payment_method")}</h2>
                            {totalAmount >= 1 ? (
                              <>
                                <div className="card_one mt-3">
                                  <div className="card_top_info">
                                    <div className="card_inner_div">
                                      <div className="card_info">
                                        <div className="card_radio">
                                          <input
                                            className="cursor-pointer"
                                            checked={
                                              formData.payment_method ==
                                              "paypal"
                                            }
                                            value="paypal"
                                            onChange={chanegPaymentMethod}
                                            type="radio"
                                            id="one"
                                            name="payment_method"
                                          />
                                        </div>
                                        <label htmlFor=""></label>
                                        <h2>{t("_paypal")}</h2>
                                        {/* <p>
                                  Safe money transfer using your bank account
                                  Visa, Maestro, Discover, American Express,
                                  etc.
                                </p> */}
                                      </div>
                                      <div className="card_img">
                                        <img
                                          src={images["paypal.png"]}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    {/* )} */}
                                  </div>
                                  {productAvailabiltyMsg != "" &&
                                    formData.payment_method == "paypal" && (
                                      <div
                                        className="row product-availabilty-msg-div header_error_cart_msg mb-2"
                                        role="alert"
                                      >
                                        <div>
                                          <p className="pruduct-availavlity-msg-checkout ">
                                            {productAvailabiltyMsg}{" "}
                                          </p>
                                        </div>
                                        <div>
                                          <p
                                            className="got-to-cart-link"
                                            onClick={() => navigate("/cart")}
                                          >
                                            <span>Go to Cart</span>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                </div>

                                {formData.payment_method == "paypal" &&
                                  productAvailabiltyMsg == "" && (
                                    <>
                                      <PayPalScriptProvider
                                        options={initialOptions}
                                      >
                                        <PayPalButtons
                                          title={"Donate Now"}
                                          // ref={inputRef}
                                          // disabled={paypalBtnStatus}
                                          className=""
                                          style={{ layout: "horizontal" }}
                                          createOrder={(data, actions) => {
                                            return actions.order.create({
                                              purchase_units: [
                                                {
                                                  amount: {
                                                    // value: cartData.checkout[cartData.checkout.length - 1].amount.split(" ")[1],
                                                    value:
                                                      cartData.final_amount.toFixed(
                                                        2
                                                      ),
                                                  },
                                                },
                                              ],
                                            });
                                          }}
                                          onApprove={(data, actions) => {
                                            return actions.order
                                              .capture()
                                              .then(async (details) => {
                                                paymentInfo = {
                                                  paypal_details: details,
                                                };
                                                // const response = await checkProduct(
                                                //   languageReducer,
                                                //   navigate,
                                                //   dispatch
                                                // );
                                                // if (response.data.status == true) {
                                                //   bookOrder(paymentInfo);
                                                // }

                                                bookOrder(paymentInfo);
                                                // setPaymentInfo({ "paypal_details": details })
                                                // if (details.status === "COMPLETED") {
                                                //   asyncAddDonate(
                                                //     { ...formData, paypal_transation: details },
                                                //     dataId
                                                //   );
                                                // } else {
                                                //   errorMsg("payment was failed please try again");
                                                // }
                                              });
                                          }}
                                          onError={(err) => {
                                            console.log("err", err);
                                            // errorMsg("Something went wrong!");
                                          }}
                                          onCancel={(err) => {
                                            console.log("cancel", err);
                                          }}
                                        />
                                      </PayPalScriptProvider>
                                    </>
                                  )}

                                <div className="card_one mt-3">
                                  <div className="card_top_info">
                                    <div className="card_inner_div">
                                      <div className="card_info">
                                        <div className="card_radio">
                                          <input
                                            className="cursor-pointer"
                                            checked={
                                              formData.payment_method ==
                                              "stripe"
                                            }
                                            value="stripe"
                                            onChange={chanegPaymentMethod}
                                            type="radio"
                                            id="one"
                                            name="payment_method"
                                          />
                                        </div>
                                        <h2>{t("_stripe")}</h2>
                                      </div>
                                      <div className="card_img">
                                        <img
                                          src={images["cardImg.png"]}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    {/* )} */}
                                  </div>
                                  {formData.payment_method == "stripe" && (
                                    // productAvailabiltyMsg == "" &&
                                    <div className="order_summary mt-4">
                                      {paymentLoader ? (
                                        <PuffLoader
                                          size={50}
                                          color="#7247e4"
                                          className="puffloader-checkout"
                                        />
                                      ) : (
                                        <>
                                          {productAvailabiltyMsg != "" ? (
                                            <div
                                              className="row product-availabilty-msg-div header_error_cart_msg mb-2"
                                              role="alert"
                                            >
                                              <div>
                                                <p className="pruduct-availavlity-msg-checkout ">
                                                  {productAvailabiltyMsg}{" "}
                                                </p>
                                              </div>
                                              <div>
                                                <p
                                                  className="got-to-cart-link"
                                                  onClick={() =>
                                                    navigate("/cart")
                                                  }
                                                >
                                                  <span>Go to Cart</span>
                                                </p>
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="order_heading d-flex justify-content-between">
                                                <h4>{t("_payment_details")}</h4>
                                                <div>
                                                  <img
                                                    src={
                                                      images["paymentCard.png"]
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                              {stripePromise && (
                                                <div className="mt-3 card_detail_div">
                                                  <Elements
                                                    stripe={stripePromise}
                                                    options={options}
                                                  >
                                                    <CheckoutForm
                                                      generateStripeToken={
                                                        generateStripeToken
                                                      }
                                                      formErrors={formErrors}
                                                      setFormError={
                                                        setFormError
                                                      }
                                                      errors={errors}
                                                      validateFormData={
                                                        validateFormData
                                                      }
                                                    // paymentLoader={paymentLoader}
                                                    // setPaymentLoader={setPaymentLoader}
                                                    />
                                                  </Elements>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <button
                                type="button"
                                className="direct_payment btn btn-info w-100"
                                onClick={() => bookFreeOrder()}
                              >
                                <img
                                  className="direct_payment_img"
                                  src={images["valid.png"]}
                                  alt="valid.png"
                                />{" "}
                                Pay Now
                              </button>
                            )}

                            {/* <div className="card_one mt-3">
                            <div className="card_top_info">
                              <div className="card_inner_div">
                                <div className="card_info">
                                  <div className="card_radio">
                                    <input
                                      checked={
                                        formData.payment_method === "google_pay"
                                      }
                                      value="google_pay"
                                      onChange={chanegPaymentMethod}
                                      type="radio"
                                      id="google_pay"
                                      name="payment_method"
                                    />
                                  </div>
                                  <label htmlFor="google_pay"><h2>GooglePay</h2></label>
                                </div>
                                <div className="card_img">
                                  <img src={images["cardImg.png"]} alt="" />
                                </div>
                              </div>
                            </div>
                            {
                              formData.payment_method == "google_pay" &&
                              <div className="order_summary mt-4">
                                <div className="order_heading d-flex justify-content-between">
                                  <div>
                                    <img src={images["paymentCard.png"]} alt="" />
                                  </div>
                                </div>
                                <div className="mt-3 card_detail_div">
                                  <GooglePaymetPage
                                    generateStripeToken={generateStripeToken}
                                    totalAmount={cartData.final_amount}
                                    errors={errors}
                                    validateFormData={validateFormData}
                                  />
                                </div>
                              </div>
                            }
                          </div> */}
                            {/* <div className="calnce_box">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M12.0577 9.15641C12.1076 9.20617 12.1471 9.26529 12.1741 9.33037C12.2011 9.39546 12.215 9.46523 12.215 9.5357C12.215 9.60616 12.2011 9.67593 12.1741 9.74102C12.1471 9.8061 12.1076 9.86522 12.0577 9.91498L8.84338 13.1293C8.79361 13.1792 8.7345 13.2187 8.66941 13.2457C8.60433 13.2728 8.53456 13.2867 8.46409 13.2867C8.39363 13.2867 8.32385 13.2728 8.25877 13.2457C8.19368 13.2187 8.13457 13.1792 8.0848 13.1293L6.47766 11.5221C6.42785 11.4723 6.38834 11.4132 6.36139 11.3481C6.33443 11.283 6.32056 11.2133 6.32056 11.1428C6.32056 11.0724 6.33443 11.0026 6.36139 10.9376C6.38834 10.8725 6.42785 10.8134 6.47766 10.7636C6.57825 10.663 6.71469 10.6064 6.85695 10.6064C6.92739 10.6064 6.99714 10.6203 7.06222 10.6473C7.12729 10.6742 7.18643 10.7137 7.23623 10.7636L8.46409 11.9925L11.2991 9.15641C11.3489 9.10652 11.408 9.06694 11.4731 9.03993C11.5381 9.01293 11.6079 8.99902 11.6784 8.99902C11.7488 8.99902 11.8186 9.01293 11.8837 9.03993C11.9488 9.06694 12.0079 9.10652 12.0577 9.15641Z"
                                fill="#FC5301"
                              />
                              <path
                                d="M4.17847 0.428711C4.32055 0.428711 4.45681 0.485152 4.55727 0.585618C4.65774 0.686084 4.71418 0.822345 4.71418 0.964425V1.50014H13.2856V0.964425C13.2856 0.822345 13.342 0.686084 13.4425 0.585618C13.543 0.485152 13.6792 0.428711 13.8213 0.428711C13.9634 0.428711 14.0997 0.485152 14.2001 0.585618C14.3006 0.686084 14.357 0.822345 14.357 0.964425V1.50014H15.4285C15.9968 1.50014 16.5418 1.7259 16.9437 2.12777C17.3456 2.52963 17.5713 3.07468 17.5713 3.643V15.4287C17.5713 15.997 17.3456 16.5421 16.9437 16.9439C16.5418 17.3458 15.9968 17.5716 15.4285 17.5716H2.57132C2.003 17.5716 1.45796 17.3458 1.0561 16.9439C0.654231 16.5421 0.428467 15.997 0.428467 15.4287V3.643C0.428467 3.07468 0.654231 2.52963 1.0561 2.12777C1.45796 1.7259 2.003 1.50014 2.57132 1.50014H3.64275V0.964425C3.64275 0.822345 3.69919 0.686084 3.79966 0.585618C3.90013 0.485152 4.03639 0.428711 4.17847 0.428711ZM2.57132 2.57157C2.28716 2.57157 2.01464 2.68445 1.81371 2.88538C1.61278 3.08631 1.4999 3.35884 1.4999 3.643V15.4287C1.4999 15.7129 1.61278 15.9854 1.81371 16.1863C2.01464 16.3873 2.28716 16.5001 2.57132 16.5001H15.4285C15.7126 16.5001 15.9851 16.3873 16.1861 16.1863C16.387 15.9854 16.4999 15.7129 16.4999 15.4287V3.643C16.4999 3.35884 16.387 3.08631 16.1861 2.88538C15.9851 2.68445 15.7126 2.57157 15.4285 2.57157H2.57132Z"
                                fill="#232A35"
                              />
                              <path
                                d="M3.10693 4.71443C3.10693 4.57234 3.16337 4.43608 3.26384 4.33562C3.36431 4.23515 3.50057 4.17871 3.64265 4.17871H14.3569C14.499 4.17871 14.6353 4.23515 14.7357 4.33562C14.8362 4.43608 14.8926 4.57234 14.8926 4.71443V5.78585C14.8926 5.92793 14.8362 6.06419 14.7357 6.16466C14.6353 6.26513 14.499 6.32157 14.3569 6.32157H3.64265C3.50057 6.32157 3.36431 6.26513 3.26384 6.16466C3.16337 6.06419 3.10693 5.92793 3.10693 5.78585V4.71443Z"
                                fill="#232A35"
                              />
                            </svg>
                          </span>
                          Cancel before 9:30 AM on June 16 for a full refund
                        </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                    <div className="promo_banner_frm ">
                      <label className="my-lab">{t("_apply_coupon")}</label>
                      <div className="pro_grp_one">
                        {!couponApplied ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              id="coupon"
                              name="coupon"
                              placeholder={t("_enter_coupon_code")}
                              value={coupon}
                              onChange={(e) =>
                                setCoupon(e.target.value.toUpperCase())
                              }
                            />

                            <button
                              type="buttton"
                              className="apply_button"
                              onClick={applyCoupon}
                            // onClick={() => setCouponCheck((state) => !state)}
                            >
                              {t("_apply")}
                            </button>
                          </>
                        ) : (
                          <div className="d-flex justify-content-between px-2 align-items-center applied_coupon_div">
                            <div className="coupon-value">{coupon}</div>
                            <button
                              className="border-0 bg-transparent button_close "
                              onClick={removeCoupon}
                            >
                              &times;
                            </button>
                          </div>
                        )}
                      </div>
                      {cartData.coupon_flag === 0 && (
                        <div className="invalid-feedback">
                          {cartData.coupon_msg}
                        </div>
                      )}
                    </div>
                    {cartLoader === true ? (
                      <div className="blinking-bar-loader">
                        <LoadingBar />
                      </div>
                    ) : (
                      <>
                        {/* <div className="booking_summary">
                          <div className="booking_top_info">
                            <h2>{t("_booking_summary")}</h2>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing and
                              typesetting industry.
                            </p>
                          </div>
                          <>
                            <div className="booking_table_info">
                              <div className="Upcoming_booking_table table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>{t("_activity_name")}</th>
                                      <th>{t("_price")}</th>
                                      <th>{t("_total_price")}</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {cartItems.length > 0 &&
                                      cartItems.map((item, index) => {
                                        return (
                                          <Fragment key={index}>
                                            {
                                              item.is_booking_allow == true &&
                                              <tr>
                                                <td>
                                                  <div>
                                                    <p className="tag_p_table">
                                                      {" "}
                                                      {item.title}{" "}
                                                    </p>
                                                    <span className="tag_span_table">
                                                      {item.date}
                                                    </span>
                                                  </div>
                                                </td>

                                                <td>
                                                  <div className="table_info">
                                                    <div className="info_one">
                                                      <span className="tag_span_table">
                                                        {item.price_brakdown.length >
                                                          0 &&
                                                          item.price_brakdown.map(
                                                            (value, index) => {
                                                              return (
                                                                <span className="d-block" key={index}>
                                                                  {value.title ==
                                                                    "Participants"
                                                                    ? value.totalParticipants +
                                                                    " " +
                                                                    value.title +
                                                                    " X " +
                                                                    "$" +
                                                                    (
                                                                      value.pricePerPerson /
                                                                      value.totalParticipants
                                                                    ).toFixed(2) +
                                                                    " : " +
                                                                    "$" +
                                                                    value.pricePerPerson
                                                                    : value.totalParticipants +
                                                                    " " +
                                                                    value.title +
                                                                    " X " +
                                                                    "$" +
                                                                    value.pricePerPerson +
                                                                    " : " +
                                                                    "$" +
                                                                    (value.pricePerPerson *
                                                                      value.totalParticipants).toFixed(2)}
                                                                </span>
                                                              );
                                                            }
                                                          )}

                                                      </span>
                                                    </div>

                                                  </div>
                                                </td>

                                                <td style={{ textAlign: "end" }}>
                                                  <span className="icon_price">$</span>{" "}
                                                  {item.totalAmount}

                                                  <div>
                                                    {item.coupon_amount !== undefined &&
                                                      item.coupon_amount > 0 && (
                                                        <span className="d-block coupan-display">
                                                          {item.couponText} :{" "}
                                                          {item.coupon_amount_format}
                                                        </span>
                                                      )}
                                                    {item.coupon_amount && (
                                                      <span className="d-block">
                                                        {t("_after_coupon_discount")}{" "}: {" "}
                                                        {
                                                          item.after_coupon_discount
                                                        }
                                                      </span>
                                                    )}
                                                    {item.tax.length > 0 && (
                                                      <>
                                                        {item.total_tax > 0 && (
                                                          <span className="d-block">
                                                            (
                                                            {item.tax.map(
                                                              (value, index) => {
                                                                return (
                                                                  <Fragment key={index}>
                                                                    <span >
                                                                      {value.title}{" "}
                                                                      {value.type ==
                                                                        "percentage" ? (
                                                                        <>
                                                                          {value.basic}%{" "}
                                                                        </>
                                                                      ) : (
                                                                        value.format_amount
                                                                      )}
                                                                    </span>{" "}
                                                                    {item.tax.length -
                                                                      1 ==
                                                                      index
                                                                      ? ""
                                                                      : ", "}
                                                                  </Fragment>
                                                                );
                                                              }
                                                            )}
                                                            ) : {item.total_tax_format}
                                                          </span>
                                                        )}
                                                      </>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            }
                                          </Fragment>
                                        );
                                      })}


                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="total_price">
                              <div className="total_price_inner">
                                {checkOutTotal.length > 0 &&
                                  checkOutTotal.map((value, index) => {
                                    return (
                                      <div className="total_main_price" key={index}>
                                        <span>{value.title}</span>

                                        <p>
                                          {" "}
                                          <span className="iscon_price">
                                            {" "}
                                          </span>{" "}
                                          {value.amount}
                                        </p>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </>
                        </div> */}
                        <div className="booked-items">
                          <div className="activity_details">
                            <div className="user_edit_main">
                              <h2>{t("_booking_summary")}</h2>
                              {cartItems.length > 0 &&
                                cartItems.map((value, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="bottom-border-checkout"
                                    >
                                      {value.is_booking_allow == true && (
                                        <>
                                          <div className="checkout_product_details_wrapper">
                                            {
                                              <CountdownTimer
                                                targetTime={
                                                  value.reservation_end_time
                                                }
                                                onTimerEnd={() =>
                                                  reservationTimeEnds(value.id)
                                                }
                                              />
                                            }
                                            <div className="row">
                                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="checkout_product_img">
                                                  <NavLink
                                                    to={`/culture-details/${value.slug}`}
                                                    target="_blank"
                                                  >
                                                    <div
                                                      className="checkout_product_img"
                                                      style={{
                                                        backgroundImage: `url(${value.image})`,
                                                      }}
                                                    ></div>
                                                  </NavLink>
                                                </div>
                                              </div>
                                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-lg-0">
                                                <div className="row">
                                                  <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12">
                                                    <div className="checkout_product_detail">
                                                      <div className="checkout_product">
                                                        <NavLink
                                                          className="checkout_product_title"
                                                          to={`/culture-details/${value.slug}`}
                                                          target="_blank"
                                                        >
                                                          <h2>{value.title}</h2>
                                                        </NavLink>
                                                        <h6>
                                                          {
                                                            value.option_details
                                                              .title
                                                          }
                                                        </h6>
                                                      </div>
                                                      <div className="checkout_product_detail_list">
                                                        <ul className="list">
                                                          <li className="row">
                                                            <svg
                                                              width="16"
                                                              height="16"
                                                              viewBox="0 0 16 16"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              className="icon__3e6C person__3B0N"
                                                            >
                                                              <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M8 2a2.75 2.75 0 100 5.5A2.75 2.75 0 008 2zM6.25 4.75a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0zM8 8.5c-2.76 0-5 2.17-5 5 0 .28.22.5.5.5h9a.5.5 0 00.5-.5c0-2.83-2.24-5-5-5zm0 1a3.96 3.96 0 013.97 3.5H4.03A3.96 3.96 0 018 9.5z"
                                                              ></path>
                                                            </svg>
                                                            {value.pricing_type ==
                                                              "person" ? (
                                                              <small>
                                                                {value.adult >
                                                                  0 && (
                                                                    <>
                                                                      {
                                                                        value.adult
                                                                      }{" "}
                                                                      -{" "}
                                                                      {t(
                                                                        "_adult"
                                                                      )}{" "}
                                                                    </>
                                                                  )}
                                                                {/* {value.child > 0 ? "," : ""} */}
                                                                {value.child >
                                                                  0 && (
                                                                    <span>
                                                                      {" "}
                                                                      {
                                                                        value.child
                                                                      }{" "}
                                                                      -{" "}
                                                                      {t(
                                                                        "_child"
                                                                      )}
                                                                    </span>
                                                                  )}
                                                                {/* {value.infant > 0 ? "," : ""} */}
                                                                {value.infant >
                                                                  0 && (
                                                                    <span>
                                                                      {" "}
                                                                      {
                                                                        value.infant
                                                                      }{" "}
                                                                      -{" "}
                                                                      {t(
                                                                        "_infant"
                                                                      )}
                                                                    </span>
                                                                  )}
                                                              </small>
                                                            ) : (
                                                              <>
                                                                {
                                                                  value.participants
                                                                }
                                                                &nbsp;{" "}
                                                                {t(
                                                                  "_Participants"
                                                                )}
                                                              </>
                                                            )}
                                                          </li>
                                                          <li className="row">
                                                            <svg
                                                              width="16"
                                                              height="16"
                                                              viewBox="0 0 16 16"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              className="icon__3e6C calendar__3jjU"
                                                            >
                                                              <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M5.5 1c.28 0 .5.22.5.5V2h4v-.5a.5.5 0 011 0V2h1.25c.97 0 1.75.78 1.75 1.75v8.5c0 .97-.78 1.75-1.75 1.75h-8.5C2.78 14 2 13.22 2 12.25v-8.5C2 2.78 2.78 2 3.75 2H5v-.5c0-.28.22-.5.5-.5zM10 3v.5a.5.5 0 001 0V3h1.25c.41 0 .75.34.75.75V5H3V3.75c0-.41.34-.75.75-.75H5v.5a.5.5 0 001 0V3h4zM3 6v6.25c0 .41.34.75.75.75h8.5c.41 0 .75-.34.75-.75V6H3z"
                                                              ></path>
                                                            </svg>
                                                            <small className="date">
                                                              <span>
                                                                {value.date}
                                                              </span>{" "}
                                                              •
                                                              <span>
                                                                {
                                                                  value.time_slot
                                                                }
                                                              </span>
                                                            </small>
                                                          </li>
                                                          <li className="row">
                                                            <svg
                                                              width="16"
                                                              height="16"
                                                              aria-hidden="true"
                                                              focusable="false"
                                                              data-prefix="fas"
                                                              data-icon="map-marker-alt"
                                                              className="svg-inline--fa fa-map-marker-alt "
                                                              role="img"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 384 512"
                                                            >
                                                              <path
                                                                fill="currentColor"
                                                                d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                                                              ></path>
                                                            </svg>
                                                            <small>
                                                              {value.location}
                                                            </small>
                                                          </li>
                                                          {/* <li className="row">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="icon__3e6C refundableIcon__3Dnw"><path d="M10.85 6.85a.5.5 0 00-.7-.7l-2.9 2.9-1.4-1.4a.5.5 0 00-.7.7L6.9 10.1a.5.5 0 00.7 0l3.25-3.25z"></path><path fillRule="evenodd" clipRule="evenodd" d="M8 2a6 6 0 100 12A6 6 0 008 2zM3 8a5 5 0 1110 0A5 5 0 013 8z"></path></svg>
                                                    <small>Free cancellation before 7:00 PM (local time) on Mar 26, 2024 <a href="#" className="cancellation_policy_button">Cancellation policy</a>
                                                    </small>
                                                  </li> */}
                                                        </ul>
                                                        <small>
                                                          {/* This experience is hosted by {" "} */}
                                                          <span className="checkout-provider-name">
                                                            {value.provider_slug ? (
                                                              <NavLink
                                                                target="_blank"
                                                                to={`/activity-supplier/${value.provider_slug}`}
                                                              >
                                                                {value.provider}
                                                              </NavLink>
                                                            ) : (
                                                              value.provider
                                                            )}
                                                          </span>
                                                        </small>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 cart_product_list booking-summary">
                                                    <div className="radt_price">
                                                      <div className="radt_price_number">
                                                        <p>
                                                          {" "}
                                                          <span className="picer_icon">
                                                            {homeData?.currency}
                                                          </span>{" "}
                                                          {value.totalAmount}{" "}
                                                          {/* {value.formatTotalAmount}{" "} */}
                                                        </p>

                                                        <div className="check-out-coupan-msg">
                                                          {value.coupon_amount !==
                                                            undefined && (
                                                              <span className="d-block coupan-display">
                                                                {value.couponText}
                                                              </span>
                                                            )}
                                                          {/* {value.coupon_amount && (
                                                            <span className="d-block">
                                                              {t(
                                                                "_after_coupon_discount"
                                                              )}{" "}
                                                              :{" "}
                                                              {
                                                                value.after_coupon_discount
                                                              }
                                                            </span>
                                                          )} */}
                                                          {/* {value.tax.length > 0 && (
                                                      <>
                                                        {item.total_tax > 0 && (
                                                          <span className="d-block">
                                                            (
                                                            {item.tax.map(
                                                              (value, index) => {
                                                                return (
                                                                  <Fragment key={index}>
                                                                    <span >
                                                                      {value.title}{" "}
                                                                      {value.type ==
                                                                        "percentage" ? (
                                                                        <>
                                                                          {value.basic}%{" "}
                                                                        </>
                                                                      ) : (
                                                                        value.format_amount
                                                                      )}
                                                                    </span>{" "}
                                                                    {item.tax.length -
                                                                      1 ==
                                                                      index
                                                                      ? ""
                                                                      : ", "}
                                                                  </Fragment>
                                                                );
                                                              }
                                                            )}
                                                            ) : {item.total_tax_format}
                                                          </span>
                                                        )}
                                                      </>
                                                    )} */}
                                                        </div>

                                                        {value.deal !== "" &&
                                                          value.deal !==
                                                          undefined ? (
                                                          <p className="deal_details">
                                                            {/* <span> */}
                                                            <span className="original_price">
                                                              {
                                                                value.deal
                                                                  .formatoriginalPrice
                                                              }
                                                            </span>
                                                            <span className="percentage">
                                                              {" "}
                                                              -
                                                              {
                                                                value.deal
                                                                  .percentage
                                                              }
                                                              %
                                                            </span>
                                                            {/* </span> */}
                                                          </p>
                                                        ) : (
                                                          ""
                                                        )}
                                                        {/* </p> */}
                                                      </div>
                                                      <span className="text_all">
                                                        {value.tax.length >
                                                          0 ? (
                                                          <ul className="tax_list">
                                                            {value.tax.map(
                                                              (item, index) => {
                                                                return (
                                                                  <li
                                                                    key={index}
                                                                  >
                                                                    {item.title}{" "}
                                                                    {item.type ==
                                                                      "percentage" ? (
                                                                      <>
                                                                        (
                                                                        {
                                                                          item.basic
                                                                        }
                                                                        %){" "}
                                                                      </>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    :
                                                                    <span className="tax_distribution">
                                                                      {" "}
                                                                      &nbsp;
                                                                      {
                                                                        item.format_amount
                                                                      }
                                                                    </span>
                                                                  </li>
                                                                );
                                                              }
                                                            )}
                                                          </ul>
                                                        ) : (
                                                          // "All taxes and fees included"
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                    {/* <div className="prod_price">
                                              <div className="prod_price_number">
                                                <p>
                                                  <span className="prod_picer_icon">$</span> {value.totalAmount}
                                                </p>
                                              </div>
                                              <p className="all_tax_text">All taxes and fees included</p>
                                            </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* {
                                        (value.meeting_point || value.pickup_point || value.pickup_point) &&
                                        <div className="meet_and_pickup_sec"> */}
                                          {value.meeting_point && (
                                            <div className="meet_and_pickup_sec">
                                              <div className="meeting_sec">
                                                <h2 className="title">
                                                  {t("_Meeting_point")}
                                                </h2>
                                                <div className="single_location_info_wrapper">
                                                  <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="icon__3e6C icon__FXnF"
                                                  >
                                                    <path d="M11.5 21.8l.5-.55.5.56a.75.75 0 01-1 0z"></path>
                                                    <path
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                      d="M12 5.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM10 9a2 2 0 114 0 2 2 0 01-4 0z"
                                                    ></path>
                                                    <path
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                      d="M11.5 21.8l.5-.55.5.56.02-.02.07-.06a25.34 25.34 0 001.07-1.04c.7-.69 1.62-1.66 2.54-2.77a24.12 24.12 0 002.58-3.7A8.62 8.62 0 0020 10.26c0-2.21-.9-4.19-2.36-5.75A7.72 7.72 0 0012 2c-2.13 0-4.15.9-5.64 2.5A8.36 8.36 0 004 10.25c0 1.3.52 2.69 1.22 3.98.72 1.3 1.65 2.57 2.58 3.69a40.97 40.97 0 003.61 3.8l.07.07.01.01zM7.46 5.53A6.22 6.22 0 0112 3.5c1.7 0 3.33.72 4.54 2.02a6.86 6.86 0 011.96 4.73c0 .94-.39 2.06-1.04 3.26a22.66 22.66 0 01-2.41 3.45A39.5 39.5 0 0112 20.22a38.61 38.61 0 01-3.05-3.26 22.66 22.66 0 01-2.4-3.45 7.17 7.17 0 01-1.05-3.26c0-1.79.72-3.4 1.96-4.73z"
                                                    ></path>
                                                  </svg>
                                                  <p className="activity-details-arrival-meeting-single-point-text">
                                                    {
                                                      value.meeting_point
                                                        .location
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {value.pickup_point &&
                                            value.pickup_point !=
                                            "DECIDE_LATER" &&
                                            value.pickup_point !=
                                            "GO_TO_MEETING_POINT" && (
                                              <div className="meet_and_pickup_sec">
                                                <div className="pickup_sec">
                                                  <h2 className="title">
                                                    {t("_pickup_point")}
                                                  </h2>
                                                  <div className="single_location_info_wrapper">
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="icon__3e6C icon__FXnF"
                                                    >
                                                      <path d="M11.5 21.8l.5-.55.5.56a.75.75 0 01-1 0z"></path>
                                                      <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 5.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM10 9a2 2 0 114 0 2 2 0 01-4 0z"
                                                      ></path>
                                                      <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M11.5 21.8l.5-.55.5.56.02-.02.07-.06a25.34 25.34 0 001.07-1.04c.7-.69 1.62-1.66 2.54-2.77a24.12 24.12 0 002.58-3.7A8.62 8.62 0 0020 10.26c0-2.21-.9-4.19-2.36-5.75A7.72 7.72 0 0012 2c-2.13 0-4.15.9-5.64 2.5A8.36 8.36 0 004 10.25c0 1.3.52 2.69 1.22 3.98.72 1.3 1.65 2.57 2.58 3.69a40.97 40.97 0 003.61 3.8l.07.07.01.01zM7.46 5.53A6.22 6.22 0 0112 3.5c1.7 0 3.33.72 4.54 2.02a6.86 6.86 0 011.96 4.73c0 .94-.39 2.06-1.04 3.26a22.66 22.66 0 01-2.41 3.45A39.5 39.5 0 0112 20.22a38.61 38.61 0 01-3.05-3.26 22.66 22.66 0 01-2.4-3.45 7.17 7.17 0 01-1.05-3.26c0-1.79.72-3.4 1.96-4.73z"
                                                      ></path>
                                                    </svg>
                                                    <p className="activity-details-arrival-meeting-single-point-text">
                                                      {
                                                        value.pickup_point
                                                          .location
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          {value.end_point && (
                                            <div className="meet_and_pickup_sec">
                                              <div className="pickup_sec">
                                                <h2 className="title">
                                                  {t("_End_Point")}
                                                </h2>
                                                <div className="single_location_info_wrapper">
                                                  <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="icon__3e6C icon__FXnF"
                                                  >
                                                    <path d="M11.5 21.8l.5-.55.5.56a.75.75 0 01-1 0z"></path>
                                                    <path
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                      d="M12 5.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM10 9a2 2 0 114 0 2 2 0 01-4 0z"
                                                    ></path>
                                                    <path
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                      d="M11.5 21.8l.5-.55.5.56.02-.02.07-.06a25.34 25.34 0 001.07-1.04c.7-.69 1.62-1.66 2.54-2.77a24.12 24.12 0 002.58-3.7A8.62 8.62 0 0020 10.26c0-2.21-.9-4.19-2.36-5.75A7.72 7.72 0 0012 2c-2.13 0-4.15.9-5.64 2.5A8.36 8.36 0 004 10.25c0 1.3.52 2.69 1.22 3.98.72 1.3 1.65 2.57 2.58 3.69a40.97 40.97 0 003.61 3.8l.07.07.01.01zM7.46 5.53A6.22 6.22 0 0112 3.5c1.7 0 3.33.72 4.54 2.02a6.86 6.86 0 011.96 4.73c0 .94-.39 2.06-1.04 3.26a22.66 22.66 0 01-2.41 3.45A39.5 39.5 0 0112 20.22a38.61 38.61 0 01-3.05-3.26 22.66 22.66 0 01-2.4-3.45 7.17 7.17 0 01-1.05-3.26c0-1.79.72-3.4 1.96-4.73z"
                                                    ></path>
                                                  </svg>
                                                  <p className="activity-details-arrival-meeting-single-point-text">
                                                    {value.end_point.location ==
                                                      undefined
                                                      ? value.meeting_point
                                                        .location
                                                      : value.end_point
                                                        .location}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    //   }
                                    // </div>
                                  );
                                })}
                              <div className="total_price">
                                <div className="total_price_inner">
                                  {checkOutTotal.length > 0 &&
                                    checkOutTotal.map((value, index) => {
                                      return (
                                        <div
                                          className="total_main_price"
                                          key={index}
                                        >
                                          <span>
                                            {value.new_title}
                                            {value.coupon_code != undefined &&
                                              value.coupon_code != "" &&
                                              `(#${value.coupon_code})`}
                                          </span>

                                          <p>
                                            {" "}
                                            <span className="iscon_price">
                                              {" "}
                                            </span>{" "}
                                            {value.amount}
                                          </p>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                recordStatus === false && <RecordNotFound />
              )}
            </div>
          </div>
        </motion.div>
        <LoginModal
          loginInfo={loginKey}
          SetLoginKey={SetLoginKey}
          LoginModal={ModalLogin}
          LogincloseModal={closeLoginModal}
        // isFromCheckout={true}
        />
      </LoadingOverlay>
    </>
  );
};

export default Checkout;
