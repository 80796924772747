import moment from "moment";
import { useContext, useState } from "react";
import Flatpickr from "react-flatpickr";
import { PrincingScheduleContext } from "../../../context";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import { faTimesCircle } from "@fortawesome/fontawesome-free-regular";

const EditDiscount = () => {
    const { t } = useTranslation();
    const {
        formData,
        setFormData,
        formErrors,
        setSlidePages,
        CloseAddOPtionModel,
    } = useContext(PrincingScheduleContext);

    const handleChange = (e, key, index) => {
        const { name, value } = e.target;
        let onlyNumbers = /^[0-9]*$/;

        if (key == "discount_percentage") {
            if (onlyNumbers.test(value) == true) {
                if (value > 100) {
                    setFormData((prev) => ({ ...prev, [name]: 100 }));
                } else {
                    setFormData((prev) => ({ ...prev, [name]: value }));
                }
            }
        }
        if (key == "unique_discount_date") {


            let array = formData.unique_discount_date;
            if (onlyNumbers.test(value) == true) {
                if (value > 100) {
                    array[index].discount = 100;
                    setFormData((prev) => ({ ...prev, unique_discount_date: array }));
                } else {
                    array[index].discount = value;
                    setFormData((prev) => ({ ...prev, unique_discount_date: array }));
                }
            }



            // let array = formData.unique_discount_date;
            // array[index].discount = value;
            // setFormData((prev) => ({ ...prev, unique_discount_date: array }));
        }

        if (key == "weekly_discount") {
            if (onlyNumbers.test(value) == true) {
                if (value > 100) {
                    setFormData((prev) => ({
                        ...prev,
                        weekly_discount: { ...prev.weekly_discount, [name]: 100 },
                    }));
                } else {
                    setFormData((prev) => ({
                        ...prev,
                        weekly_discount: { ...prev.weekly_discount, [name]: value },
                    }));
                }
            }

        }
    };

    const handleDate = (date, key, index) => {
        date = moment(date).format("yyyy-MM-DD");
        console.log("date", date);
        if (key == "unique_discount_date") {
            let array = formData.unique_discount_date;
            array[index].date = date;
            setFormData((prev) => ({ ...prev, unique_discount_date: array }));
        } else {
            setFormData((prev) => ({ ...prev, [key]: date }));
        }
    };

    const addNewDate = (index) => {
        setFormData((prev) => ({
            ...prev,
            unique_discount_date: [...prev.unique_discount_date, { date: "", discount: "" }],
        }));
    };

    function removeDateField(index) {
        formData.unique_discount_date.splice(index, 1);
        setFormData((prev) => ({ ...prev, formData }));
    }

    return (
        <>
            {/* {
                dataLoad === true ?
                    <div className="blinking-bar-loader-tour_files">
                        <LoadingBar />
                    </div>
                    : */}
            <>
                {/* {editDiscountData && ( */}
                <div className="product_discount_section">
                    <div className="discount_heading">
                        <h1>{t("_discount")}</h1>
                        <h5>
                            {t(
                                "_When_is_your_activity_available_for_customers_Add_your_activity’s_dates_and_times_by_connecting_your_booking_system_or_by_manually_entering_the_information_for_your_product_and_options"
                            )}
                        </h5>
                    </div>

                    <div className="discount_section_text">
                        <h6>{t("_Validity_of_this_season")}</h6>
                    </div>

                    <div className="discount_valid_form">
                        <div className="priceDateSection priceDateSection-new">
                            <div className="valid-from-to-div">
                                <span>{t("_valid_from")}</span>
                                <div className="price_datepicker">
                                    <span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <g clipPath="url(#clip0_106_2)">
                                                <path
                                                    d="M15.375 10.3125C15.375 10.1633 15.4343 10.0202 15.5398 9.91475C15.6452 9.80926 15.7883 9.75 15.9375 9.75H17.0625C17.2117 9.75 17.3548 9.80926 17.4602 9.91475C17.5657 10.0202 17.625 10.1633 17.625 10.3125V11.4375C17.625 11.5867 17.5657 11.7298 17.4602 11.8352C17.3548 11.9407 17.2117 12 17.0625 12H15.9375C15.7883 12 15.6452 11.9407 15.5398 11.8352C15.4343 11.7298 15.375 11.5867 15.375 11.4375V10.3125ZM12 10.3125C12 10.1633 12.0593 10.0202 12.1648 9.91475C12.2702 9.80926 12.4133 9.75 12.5625 9.75H13.6875C13.8367 9.75 13.9798 9.80926 14.0852 9.91475C14.1907 10.0202 14.25 10.1633 14.25 10.3125V11.4375C14.25 11.5867 14.1907 11.7298 14.0852 11.8352C13.9798 11.9407 13.8367 12 13.6875 12H12.5625C12.4133 12 12.2702 11.9407 12.1648 11.8352C12.0593 11.7298 12 11.5867 12 11.4375V10.3125ZM6.375 13.6875C6.375 13.5383 6.43426 13.3952 6.53975 13.2898C6.64524 13.1843 6.78832 13.125 6.9375 13.125H8.0625C8.21168 13.125 8.35476 13.1843 8.46025 13.2898C8.56574 13.3952 8.625 13.5383 8.625 13.6875V14.8125C8.625 14.9617 8.56574 15.1048 8.46025 15.2102C8.35476 15.3157 8.21168 15.375 8.0625 15.375H6.9375C6.78832 15.375 6.64524 15.3157 6.53975 15.2102C6.43426 15.1048 6.375 14.9617 6.375 14.8125V13.6875ZM9.75 13.6875C9.75 13.5383 9.80926 13.3952 9.91475 13.2898C10.0202 13.1843 10.1633 13.125 10.3125 13.125H11.4375C11.5867 13.125 11.7298 13.1843 11.8352 13.2898C11.9407 13.3952 12 13.5383 12 13.6875V14.8125C12 14.9617 11.9407 15.1048 11.8352 15.2102C11.7298 15.3157 11.5867 15.375 11.4375 15.375H10.3125C10.1633 15.375 10.0202 15.3157 9.91475 15.2102C9.80926 15.1048 9.75 14.9617 9.75 14.8125V13.6875Z"
                                                    fill="#FC5301"
                                                />
                                                <path
                                                    d="M6.9375 3C7.08668 3 7.22976 3.05926 7.33525 3.16475C7.44074 3.27024 7.5 3.41332 7.5 3.5625V4.125H16.5V3.5625C16.5 3.41332 16.5593 3.27024 16.6648 3.16475C16.7702 3.05926 16.9133 3 17.0625 3C17.2117 3 17.3548 3.05926 17.4602 3.16475C17.5657 3.27024 17.625 3.41332 17.625 3.5625V4.125H18.75C19.3467 4.125 19.919 4.36205 20.341 4.78401C20.7629 5.20597 21 5.77826 21 6.375V18.75C21 19.3467 20.7629 19.919 20.341 20.341C19.919 20.7629 19.3467 21 18.75 21H5.25C4.65326 21 4.08097 20.7629 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V6.375C3 5.77826 3.23705 5.20597 3.65901 4.78401C4.08097 4.36205 4.65326 4.125 5.25 4.125H6.375V3.5625C6.375 3.41332 6.43426 3.27024 6.53975 3.16475C6.64524 3.05926 6.78832 3 6.9375 3ZM4.125 7.5V18.75C4.125 19.0484 4.24353 19.3345 4.4545 19.5455C4.66548 19.7565 4.95163 19.875 5.25 19.875H18.75C19.0484 19.875 19.3345 19.7565 19.5455 19.5455C19.7565 19.3345 19.875 19.0484 19.875 18.75V7.5H4.125Z"
                                                    fill="#FC5301"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_106_2">
                                                    <rect
                                                        width="18"
                                                        height="18"
                                                        fill="white"
                                                        transform="translate(3 3)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    <Flatpickr
                                        //   data-enable-time
                                        className="date_picker"
                                        placeholder={t("_select_date")}
                                        id="discount_validity_from"
                                        name="discount_validity_from"
                                        value={formData.discount_validity_from}
                                        options={{
                                            // minDate: "today",
                                            dateFormat: "Y-m-d",
                                            monthSelectorType: 'static'

                                        }}
                                        onChange={(value) =>
                                            handleDate(value[0], "discount_validity_from", "")
                                        }
                                    />
                                </div>
                            </div>
                            <div className="valid-from-to-div">
                                <span>{t("_validity_to")}</span>
                                <div>
                                    <div className="price_datepicker">
                                        <span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <g clipPath="url(#clip0_106_2)">
                                                    <path
                                                        d="M15.375 10.3125C15.375 10.1633 15.4343 10.0202 15.5398 9.91475C15.6452 9.80926 15.7883 9.75 15.9375 9.75H17.0625C17.2117 9.75 17.3548 9.80926 17.4602 9.91475C17.5657 10.0202 17.625 10.1633 17.625 10.3125V11.4375C17.625 11.5867 17.5657 11.7298 17.4602 11.8352C17.3548 11.9407 17.2117 12 17.0625 12H15.9375C15.7883 12 15.6452 11.9407 15.5398 11.8352C15.4343 11.7298 15.375 11.5867 15.375 11.4375V10.3125ZM12 10.3125C12 10.1633 12.0593 10.0202 12.1648 9.91475C12.2702 9.80926 12.4133 9.75 12.5625 9.75H13.6875C13.8367 9.75 13.9798 9.80926 14.0852 9.91475C14.1907 10.0202 14.25 10.1633 14.25 10.3125V11.4375C14.25 11.5867 14.1907 11.7298 14.0852 11.8352C13.9798 11.9407 13.8367 12 13.6875 12H12.5625C12.4133 12 12.2702 11.9407 12.1648 11.8352C12.0593 11.7298 12 11.5867 12 11.4375V10.3125ZM6.375 13.6875C6.375 13.5383 6.43426 13.3952 6.53975 13.2898C6.64524 13.1843 6.78832 13.125 6.9375 13.125H8.0625C8.21168 13.125 8.35476 13.1843 8.46025 13.2898C8.56574 13.3952 8.625 13.5383 8.625 13.6875V14.8125C8.625 14.9617 8.56574 15.1048 8.46025 15.2102C8.35476 15.3157 8.21168 15.375 8.0625 15.375H6.9375C6.78832 15.375 6.64524 15.3157 6.53975 15.2102C6.43426 15.1048 6.375 14.9617 6.375 14.8125V13.6875ZM9.75 13.6875C9.75 13.5383 9.80926 13.3952 9.91475 13.2898C10.0202 13.1843 10.1633 13.125 10.3125 13.125H11.4375C11.5867 13.125 11.7298 13.1843 11.8352 13.2898C11.9407 13.3952 12 13.5383 12 13.6875V14.8125C12 14.9617 11.9407 15.1048 11.8352 15.2102C11.7298 15.3157 11.5867 15.375 11.4375 15.375H10.3125C10.1633 15.375 10.0202 15.3157 9.91475 15.2102C9.80926 15.1048 9.75 14.9617 9.75 14.8125V13.6875Z"
                                                        fill="#FC5301"
                                                    />
                                                    <path
                                                        d="M6.9375 3C7.08668 3 7.22976 3.05926 7.33525 3.16475C7.44074 3.27024 7.5 3.41332 7.5 3.5625V4.125H16.5V3.5625C16.5 3.41332 16.5593 3.27024 16.6648 3.16475C16.7702 3.05926 16.9133 3 17.0625 3C17.2117 3 17.3548 3.05926 17.4602 3.16475C17.5657 3.27024 17.625 3.41332 17.625 3.5625V4.125H18.75C19.3467 4.125 19.919 4.36205 20.341 4.78401C20.7629 5.20597 21 5.77826 21 6.375V18.75C21 19.3467 20.7629 19.919 20.341 20.341C19.919 20.7629 19.3467 21 18.75 21H5.25C4.65326 21 4.08097 20.7629 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V6.375C3 5.77826 3.23705 5.20597 3.65901 4.78401C4.08097 4.36205 4.65326 4.125 5.25 4.125H6.375V3.5625C6.375 3.41332 6.43426 3.27024 6.53975 3.16475C6.64524 3.05926 6.78832 3 6.9375 3ZM4.125 7.5V18.75C4.125 19.0484 4.24353 19.3345 4.4545 19.5455C4.66548 19.7565 4.95163 19.875 5.25 19.875H18.75C19.0484 19.875 19.3345 19.7565 19.5455 19.5455C19.7565 19.3345 19.875 19.0484 19.875 18.75V7.5H4.125Z"
                                                        fill="#FC5301"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_106_2">
                                                        <rect
                                                            width="18"
                                                            height="18"
                                                            fill="white"
                                                            transform="translate(3 3)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        <Flatpickr
                                            //   data-enable-time
                                            className="date_picker"
                                            placeholder={t("_select_date")}
                                            id="discount_validity_to"
                                            name="discount_validity_to"
                                            value={formData.discount_validity_to}
                                            options={{
                                                minDate: formData.discount_validity_from ? new Date(formData.discount_validity_from).fp_incr(1) : '',
                                                dateFormat: "Y-m-d",
                                                monthSelectorType: 'static',
                                                disable: [
                                                    function (date) {
                                                        return formData.discount_validity_from ? false : true

                                                    }
                                                ],

                                            }}
                                            onChange={(value) => handleDate(value[0], "discount_validity_to", "")}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            <p className="availbilty-note mt-2">
                                {t("_Product_will_be_available_for_all_dates_if_you_select_validity_to_date_before_today")}
                            </p>
                        </div>
                        <div className="input_edit discount_percentage-input ">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("_Enter_discount_percentage")}
                                name="discount_percentage"
                                value={formData.discount_percentage}
                                onChange={(e) => handleChange(e, "discount_percentage", "")}
                            />
                            <span>%</span>
                        </div>
                        <div className="week_time_table">
                            <h6>{t("_weekly_starting_times")}</h6>
                            {/* <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown printer took a galley
                                    of type and scrambled it to make a type specimen book.
                                </p> */}
                            <ul>
                                {formData.weekly_discount &&
                                    Object.entries(formData.weekly_discount).map(
                                        (item, index) => {
                                            return (
                                                <li key={index} className="weekly_discount_flex">
                                                    <span>
                                                        {item[0].charAt(0).toUpperCase() + item[0].slice(1)}
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("_Enter_discount_percentage")}
                                                        name={item[0]}
                                                        value={item[1]}
                                                        // defaultValue={formData.discount_percentage}
                                                        onChange={(e) =>
                                                            handleChange(e, "weekly_discount", index)
                                                        }
                                                    />
                                                    <p>%</p>
                                                </li>
                                            );
                                        }
                                    )}
                            </ul>
                        </div>
                        <div className="special_dates">
                            <h6>{t("_single_special_dates")}</h6>

                        </div>
                        {formData.unique_discount_date.length > 0 &&
                            formData.unique_discount_date.map((item, index) => {
                                return (
                                    <div className="mb-1 position-relative" key={index}>
                                        <div className=" account_calender special_discount_date_div">
                                            <Flatpickr
                                                //   data-enable-time
                                                className="date_picker"
                                                placeholder={t("_select_date")}
                                                id="discount_validity_from"
                                                name="discount_validity_from"
                                                value={item.date}
                                                options={{
                                                    // minDate: "today",
                                                    dateFormat: "Y-m-d",
                                                    monthSelectorType: 'static'
                                                }}
                                                onChange={(value) =>
                                                    handleDate(value[0], "unique_discount_date", index)
                                                }
                                            />
                                            <div className="single-special-date-new">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("_Enter_discount_percentage")}
                                                    name="date"
                                                    value={item.discount}
                                                    onChange={(e) =>
                                                        handleChange(e, "unique_discount_date", index)
                                                    }
                                                />
                                                <span>%</span>
                                            </div>

                                            <FontAwesomeIcon
                                                onClick={() => removeDateField(index)}
                                                className="removedate"
                                                icon={faTimesCircle}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        <div className="add_new_date_btn">
                            {/* <button onClick={() => addNewDate()}>{t("_add_new_date")}</button> */}
                            <div className="add_new_date_btn">
                                <span onClick={() => addNewDate()} className="pricing_schedule_add_btn  step_three_add_new">
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;&nbsp;
                                    {t("_add_new_date")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* )} */}
                {/* {
                    editDiscountData &&
                    <div className="save_continue_btn">
                        <span>
                            <button onClick={() => submitData()}>{t("_save_and_continue")}</button>
                            <button onClick={() => cancelOption()}>{t("_cancel")}</button> {' '}
                        </span>
                    </div>
                } */}
            </>

            {/* } */}
        </>
    );
};
export default EditDiscount;
