import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyEmailId } from "../../actions/loginAction";
import { useTranslation } from "react-i18next";
import { PuffLoader } from "react-spinners";
const ForgotPasswordModal = (props) => {
    const { t } = useTranslation();
    const { forgotOpen, closeForgotPasswordModel } = props;
    const [buttonDisable, setButtonDisable] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const errors = {};
    const [email, setEmail] = useState("");
    const [formErrors, setFormErrors] = useState({});

    const validation = () => {
        if (email === "") {
            errors.email = "Enter email id";
        } else if (!validator.isEmail(email)) {
            errors.email = "Enter valid email id";
        }
        setFormErrors(errors);
    };

    const verifyEmail = (e) => {
        e.preventDefault();
        validation();
        if (Object.keys(errors).length === 0) {
            setButtonDisable(true);
            dispatch(
                verifyEmailId(
                    email,
                    setEmail,
                    closeForgotPasswordModel,
                    navigate,
                    setButtonDisable, t
                )
            );
        }
    };

    return (
        <div>
            <Modal
                className="login_popup"
                show={forgotOpen}
                onHide={() => {
                    closeForgotPasswordModel();
                    setFormErrors({});
                }}
            >
                <div className="login_model_main">
                    <button
                        className="btn_close"
                        onClick={() => {
                            closeForgotPasswordModel();
                            setFormErrors({});
                        }}
                    >
                        {" "}
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <div className="login_inner_modal">
                        <div className={`login_section new_login_main`}>
                            <div className="login_heading">
                                <h2>{t("_Verify_Email")}</h2>
                                <p>{t("_Please_Enter_your_registered_email")}</p>
                            </div>

                            <div className="main_form">
                                <div className="input_all_login">
                                    <label>{t("_Email")}</label>
                                    <input
                                        type="email"
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        className={
                                            `form-control  ` + (formErrors.email ? "is-invalid" : "")
                                        }
                                        placeholder="Enter your email"
                                    />
                                    {formErrors.email && (
                                        <div className="invalid-feedback">{formErrors.email}</div>
                                    )}
                                </div>
                            </div>
                            <div className="login_button">
                                <button onClick={verifyEmail} disabled={buttonDisable}>
                                    {
                                        buttonDisable ?
                                            <div className="order-cancelling-loader-btn">
                                                < PuffLoader size={30} color="#fff" />
                                            </div>
                                            :
                                            t("_submit")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ForgotPasswordModal;
