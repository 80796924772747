import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const ActivitiesList = ({ activitiesListData, isLoading }) => {
  const [activitiesList, setActivitiesList] = useState(activitiesListData);

  // Update activities list when data changes
  useEffect(() => {
    setActivitiesList(activitiesListData);
  }, [activitiesListData]);

  // Loading State
  return (
    <>
      {isLoading ? (
        // Show shimmer headers while loading
        <div className="shimmer-header">
          <ShimmerSimpleGallery col="1" row="1" imageHeight={300} />
        </div>
      ) : (
        <div className="ActivitiesList-main-supplier">
          <div className="activites-bg-new">
            <div className="container">
              <div className="all-list-data-new">
                <div className="heading-top-main">
                  <h2>{activitiesList?.activities_we_welcome_heading}</h2>
                  <div className="list-data-new">
                    <ul>
                      {activitiesList?.activities_we_welcome?.map((item, index) => (
                        <li key={index}>
                          <NavLink to="">{item}</NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActivitiesList;
