import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ProductBuilderContext } from "../../context";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { startSpinLoader } from "../../actions/customFn";

const TravellerRequired = (props) => {
  const { t } = useTranslation();
  const { tour_id, option_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    setTourId,
    getData,
    setDataLoad,
    dataLoad,
    runningDtep,
    productType,
    setSpinLoader,
    languageIdSelected
  } = useContext(ProductBuilderContext);

  const [travellersRequiredData, setData] = useState("");

  const [formData, setFormData] = useState({
    tourId: tour_id,
    product_type: productType,
    step: "fourteen",

    name: "false",
    height: "false",
    weight: "false",
    dob: "false",
    no_passport_details: "false",
    arrival_flight_details: "false",
    departure_flight_details: "false",
    arrival_ship_details: "false",
    departure_ship_details: "false",
    shore_excursion_details: "false",
    arrival_train_details: "false",
    departure_train_details: "false",
  });

  const handleChange = (e, key = "") => {
    const { name, checked } = e.target;
    setFormData((formData) => ({ ...formData, [name]: checked.toString() }));
  };

  console.log("formData", formData);

  useEffect(() => {
    if (languageReducer != "") {
      getData("fourteen", setData);
    }
  }, [languageReducer,languageIdSelected]);

  useEffect(() => {
    if (languageReducer && travellersRequiredData) {
      setFormData(() => ({
        ...travellersRequiredData.product_data,
        tourId: tour_id,
        step: "fourteen",
        product_type: travellersRequiredData.ProductType,
      }));
    }
  }, [travellersRequiredData]);

  const submit = () => {
    // validateFormData();
    // if (Object.keys(errors).length === 0) {
    let nextRoute = "ticket-builder";
    if (languageReducer !== "") {
      startSpinLoader("cover-spin");
      dispatch(
        uplodProductNew(
          languageReducer,
          setTourId,
          navigate,
          formData,
          nextRoute,
          "",
          "",
          "",
          "",
          "",
          "",
          setSpinLoader
        )
      );
    }
    var element = document.getElementById("cover-spin");
    element.style.display = "block";
    setTimeout(() => {
      element.style.display = "none";
    }, 1000);
    // }
  };

  return (
    <>
      {travellersRequiredData && (
        <div className="meet-pickup-main">
          <div className="traveller_reqiredpage activity_productdetail">
            <h1>{t("_Do_you_need_any_extra_information _from_travelers?")}</h1>

            <h6>{t("_Here's_what_we'll_collect_automatically:")}</h6>

            <div className="lead_travellername">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_33_905)">
                  <path
                    d="M22.3191 4.43101L8.50012 18.249C8.40721 18.3423 8.2968 18.4163 8.17522 18.4668C8.05363 18.5173 7.92327 18.5433 7.79162 18.5433C7.65997 18.5433 7.52961 18.5173 7.40803 18.4668C7.28644 18.4163 7.17603 18.3423 7.08312 18.249L1.73912 12.9C1.64621 12.8067 1.5358 12.7327 1.41422 12.6822C1.29263 12.6317 1.16228 12.6057 1.03062 12.6057C0.898968 12.6057 0.768609 12.6317 0.647027 12.6822C0.525444 12.7327 0.415031 12.8067 0.322121 12.9C0.228845 12.9929 0.154832 13.1033 0.10433 13.2249C0.0538287 13.3465 0.027832 13.4769 0.027832 13.6085C0.027832 13.7402 0.0538287 13.8705 0.10433 13.9921C0.154832 14.1137 0.228845 14.2241 0.322121 14.317L5.66812 19.662C6.23207 20.2249 6.99632 20.5411 7.79312 20.5411C8.58992 20.5411 9.35417 20.2249 9.91812 19.662L23.7361 5.84701C23.8292 5.75412 23.9031 5.64377 23.9535 5.52228C24.004 5.40079 24.0299 5.27054 24.0299 5.13901C24.0299 5.00747 24.004 4.87723 23.9535 4.75574C23.9031 4.63425 23.8292 4.5239 23.7361 4.43101C23.6432 4.33773 23.5328 4.26372 23.4112 4.21322C23.2896 4.16272 23.1593 4.13672 23.0276 4.13672C22.896 4.13672 22.7656 4.16272 22.644 4.21322C22.5224 4.26372 22.412 4.33773 22.3191 4.43101Z"
                    fill="#1A6C6E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_33_905">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>{t("_Lead_Traveler's_Name")}</p>
            </div>
            <h3> ({t("_first_and_last_name")})</h3>

            <div className="lead_travellername mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_33_905)">
                  <path
                    d="M22.3191 4.43101L8.50012 18.249C8.40721 18.3423 8.2968 18.4163 8.17522 18.4668C8.05363 18.5173 7.92327 18.5433 7.79162 18.5433C7.65997 18.5433 7.52961 18.5173 7.40803 18.4668C7.28644 18.4163 7.17603 18.3423 7.08312 18.249L1.73912 12.9C1.64621 12.8067 1.5358 12.7327 1.41422 12.6822C1.29263 12.6317 1.16228 12.6057 1.03062 12.6057C0.898968 12.6057 0.768609 12.6317 0.647027 12.6822C0.525444 12.7327 0.415031 12.8067 0.322121 12.9C0.228845 12.9929 0.154832 13.1033 0.10433 13.2249C0.0538287 13.3465 0.027832 13.4769 0.027832 13.6085C0.027832 13.7402 0.0538287 13.8705 0.10433 13.9921C0.154832 14.1137 0.228845 14.2241 0.322121 14.317L5.66812 19.662C6.23207 20.2249 6.99632 20.5411 7.79312 20.5411C8.58992 20.5411 9.35417 20.2249 9.91812 19.662L23.7361 5.84701C23.8292 5.75412 23.9031 5.64377 23.9535 5.52228C24.004 5.40079 24.0299 5.27054 24.0299 5.13901C24.0299 5.00747 24.004 4.87723 23.9535 4.75574C23.9031 4.63425 23.8292 4.5239 23.7361 4.43101C23.6432 4.33773 23.5328 4.26372 23.4112 4.21322C23.2896 4.16272 23.1593 4.13672 23.0276 4.13672C22.896 4.13672 22.7656 4.16272 22.644 4.21322C22.5224 4.26372 22.412 4.33773 22.3191 4.43101Z"
                    fill="#1A6C6E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_33_905">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>
                {/* Phone Number */}
                {t("_Phone_Number")}
              </p>
            </div>
            <h3>
              {/* (including country code)  */}({t("_including_country_code")})
            </h3>

            {/* -- select personal information -- */}
            <div className="selectpersonal_inform mt-5">
              <h6>
                {t(
                  "_Select_any_personal_information_you_need_from_all_travelers:"
                )}
              </h6>

              <div className="top-header-info-box">
                <div className="icon-svg-info">
                  <svg
                    viewBox="0 0 27 36"
                    xmlns="http://www.w3.org/2000/svg"
                    class="Alert__alertIcon___S7wRU Icon__icon___2sbfU"
                  >
                    <g fill-rule="nonzero">
                      <path d="M17.97 34.81v.25h-9a.7.7 0 01-.7-.7V28.5h1.4v5.16h7.6V28.5h1.4v5.86a.7.7 0 01-.7.7v-.25z"></path>
                      <path d="M20.22 23.45l.24-.23c.77-.76 1.11-1.12 1.51-1.65a11.5 11.5 0 003.05-7.82A11.6 11.6 0 0013.43 2.2 11.57 11.57 0 005 21.63c.35.47.69.83 1.46 1.59l.24.23c1.77 1.74 2.52 2.98 2.56 5h8.42c.03-2.02.78-3.26 2.55-5zm-2.25 5.74v.75h-9a1.23 1.23 0 01-1.22-1.21c.03-1.7-.52-2.64-2.12-4.22l-.23-.22c-.83-.81-1.2-1.2-1.56-1.7A13.03 13.03 0 01.4 13.76C.4 6.56 6.24.69 13.45.69a13.1 13.1 0 0113.06 13.07 13 13 0 01-3.4 8.77c-.41.55-.78.95-1.61 1.76l-.23.23c-1.6 1.57-2.14 2.51-2.11 4.2a1.2 1.2 0 01-1.2 1.22v-.75z"></path>
                      <path d="M4.44 14H3.29v-.24a10.2 10.2 0 014.2-8.23l.2-.15.82 1.13-.2.15a8.8 8.8 0 00-3.62 7.1h-.25V14zM9.22 5.79l-.5-1.03.22-.11c.36-.18.69-.32 1.01-.44l.24-.08.48 1.31-.24.09c-.28.1-.56.22-.87.37l-.11-.22-.23.1zM7.67 32.25v-1.4h11.6v1.4z"></path>
                    </g>
                  </svg>
                </div>
                <div className="meet-box-content">
                  {/* <h2>Did you know?</h2> */}
                  <p>
                    {t(
                      "_tip:_travelers_are_less_likely_to_book_if_they_have_to_enter_lots_of_information._Only_select_what's_absolute_necessary."
                    )}
                  </p>
                </div>
              </div>

              <label class="metting_checkboxes mt-4">
                <input
                  type="checkbox"
                  name="name"
                  onClick={handleChange}
                  defaultChecked={formData.name == "true" ? true : false}
                />
                <span>{t("_Full_Names")}</span>
              </label>

              <label class="metting_checkboxes mt-2">
                <input
                  type="checkbox"
                  name="weight"
                  onClick={handleChange}
                  defaultChecked={formData.weight == "true" ? true : false}
                />
                <span>{t("_Weights")} </span>
              </label>

              <label class="metting_checkboxes mt-2">
                <input
                  type="checkbox"
                  name="height"
                  onClick={handleChange}
                  defaultChecked={formData.height == "true" ? true : false}
                />
                <span> {t("_Heights")} </span>
              </label>

              <label class="metting_checkboxes mt-2">
                <input
                  type="checkbox"
                  name="dob"
                  onClick={handleChange}
                  defaultChecked={formData.dob == "true" ? true : false}
                />
                <span>{t("_Dates_of_Birth")}</span>
              </label>

              <label class="metting_checkboxes mt-2">
                <input
                  type="checkbox"
                  name="no_passport_details"
                  onClick={handleChange}
                  defaultChecked={
                    formData.no_passport_details == "true" ? true : false
                  }
                />
                <span>{t("_NoPassport_Details")} </span>
              </label>
            </div>

            <div className="selectpersonal_inform mt-5">
              <h6>
                {t("_Select_any_arrival/departure_information_you_need:")}
              </h6>

              <p className="mt-4">{t("_Flight_Information")}</p>
              <label class="metting_checkboxes mt-4">
                <input
                  type="checkbox"
                  name="arrival_flight_details"
                  onClick={handleChange}
                  defaultChecked={
                    formData.arrival_flight_details == "true" ? true : false
                  }
                />
                <span>{t("_Arrival_Flight_Details")} </span>
              </label>

              <label class="metting_checkboxes mt-2">
                <input
                  type="checkbox"
                  name="departure_flight_details"
                  onClick={handleChange}
                  defaultChecked={
                    formData.departure_flight_details == "true" ? true : false
                  }
                />
                <span> {t("_Departure_Flight_Details")} </span>
              </label>

              <p className="mt-4">{t("_Ship_Information")}</p>

              <label class="metting_checkboxes mt-4">
                <input
                  type="checkbox"
                  name="arrival_ship_details"
                  onClick={handleChange}
                  defaultChecked={
                    formData.arrival_ship_details == "true" ? true : false
                  }
                />
                <span>{t("_Arrival_Ship_Details")}</span>
              </label>

              <label class="metting_checkboxes mt-2">
                <input
                  type="checkbox"
                  name="departure_ship_details"
                  onClick={handleChange}
                  defaultChecked={
                    formData.departure_ship_details == "true" ? true : false
                  }
                />
                <span>{t("_Departure_Ship_Details")} </span>
              </label>
              <label class="metting_checkboxes mt-2">
                <input
                  type="checkbox"
                  name="shore_excursion_details"
                  onClick={handleChange}
                  defaultChecked={
                    formData.shore_excursion_details == "true" ? true : false
                  }
                />
                <span> {t("_Shore_excursion_details:")} </span>
              </label>

              <p className="mt-4">{t("_Train_Information")}</p>
              <label class="metting_checkboxes mt-2">
                <input
                  type="checkbox"
                  name="arrival_train_details"
                  onClick={handleChange}
                  defaultChecked={
                    formData.arrival_train_details == "true" ? true : false
                  }
                />
                <span>{t("_Arrival_Train_Details")}</span>
              </label>
              <label class="metting_checkboxes mt-2">
                <input
                  type="checkbox"
                  name="departure_train_details"
                  onClick={handleChange}
                  defaultChecked={
                    formData.departure_train_details == "true" ? true : false
                  }
                />
                <span> {t("_Departure_train_details")}</span>
              </label>
            </div>

            <div className="btn-last-save">
              <button className="btn-all-save" onClick={() => submit()}>
                {t("_Save_&_continue")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TravellerRequired;
