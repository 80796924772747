import { Navigate, Route } from "react-router-dom";
import toast from "react-hot-toast";
import validator from "validator";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import backAxios from "../axios";

const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../assets/images", false));

//Get Routs
//Get Routs
const GetRoutes = (allRoutes) =>
  allRoutes.map((route, index) => {
    const userType = localStorage.getItem("user_type");
    if (route.route) {
      return (
        <Route
          exact
          path={route.route}
          element={
            route.private == true ? (
              checkLogin() == true ? (
                route.user_type == userType ? (
                  route.page
                ) : (
                  <Navigate to="/" />
                )
              ) : (
                <Navigate to="/" />
              )
            ) : (
              route.page
            )
          }
          key={index}
        />
      );
    }
    return null;
  });
const successMsg = (msg) => {
  toast.success(msg);
};

const errorMsg = (msg) => {
  toast.error(msg);
};

// Set and get Token

const normalCharacters =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

const specialCharacters = "@:;#&-?/%+*";

const setGetToken = () => {
  const getToken = localStorage.getItem("_token");
  if (
    getToken !== undefined &&
    getToken !== "" &&
    getToken !== null &&
    getToken != "null"
  ) {
    return getToken;
  } else {
    let length = 18;
    var characterList = normalCharacters;
    var result = "";

    characterList += specialCharacters;

    while (length > 0) {
      // Pick random index from characterList
      var index = Math.floor(Math.random() * characterList.length);
      result += characterList[index];
      length--;
    }
    localStorage.setItem("_token", result);
    return result;
  }
};

const checkLogin = () => {
  var status = false;
  if (
    localStorage.getItem("userId") != null &&
    localStorage.getItem("password") != null
  ) {
    status = true;
  }
  return status;
};

const handleScrollError = (errors) => {
  const errorsvalues = Object.keys(errors);
  if (errorsvalues.length > 0) {
    let firstErrorElement = document.getElementsByName(errorsvalues[0])[0];

    if (firstErrorElement !== null && firstErrorElement !== "") {
      firstErrorElement.scrollIntoView({ behavior: `smooth`, block: "center" });
    }
  }
};

const getTimeone = async () => {
  try {
    const responseIP = await axios
      .get("https://api.ipify.org?format=json")
      .then(async (result) => {
        const response = await axios.get(
          `https://ipapi.co/${result.data.ip}/timezone/`
        );

        localStorage.setItem("_tz", response.data);
        return response.data;
      });
  } catch (error) {
    const response = await backAxios.get("get-timezone");
    try {
      localStorage.setItem("_tz", response.data);
      return response.data;
    } catch (error) {
      localStorage.setItem("_tz", response.data);
    }
  }
};

const readCookie = (cname) => {
  var name = cname + "=";
  var decoded_cookie = decodeURIComponent(document.cookie);

  var carr = decoded_cookie.split(";");
  for (var i = 0; i < carr.length; i++) {
    var c = carr[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const handleScroll = (id, headerOffset) => {
  let element = document.getElementById(id);
  let elementPosition = element.getBoundingClientRect().top;
  let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
  return true;
};

function NumberFormat(number) {
  return number != undefined && number > 0 ? parseFloat(number).toFixed(2) : 0;
}

const MetaData = (props) => {
  props.data && (
    <>
      {(document.title = props.data.title)}
      {document
        .getElementById("meta-description")
        .setAttribute("content", props.data.description)}
      {document
        .getElementById("meta-keywords")
        .setAttribute("content", props.data.keyword)}
    </>
  );
};

const setSessionCookie = (cookieName, cookieValue) => {
  document.cookie = cookieName + "=" + cookieValue + ";path=/";
};

function getUrlParams(search) {
  const params = {};
  const queryString = search.substring(1);
  const urlParams = queryString.split("&");

  for (let i = 0; i < urlParams.length; i++) {
    const param = urlParams[i].split("=");
    const paramName = decodeURIComponent(param[0]);
    const paramValue = decodeURIComponent(param[1]);
    params[paramName] = paramValue;
  }
  return params;
}

const getArray = (arr, key) => {
  let return_arr = [""];
  if (arr != undefined && arr.length > 0) {
    return_arr = arr.map((foo) => (foo[key] != undefined ? foo[key] : ""));
  }
  return return_arr;
};

// const coverSpinLoader = (id) => {
//   // var element = document.getElementById(id);
//   // element.style.display = "block";
//   // setTimeout(() => {
//   //   element.style.display = "none";
//   // }, 1200);
// };

const startSpinLoader = (id) => {
  var element = document.getElementById(id);
  element.style.display = "block";
};

const stopSpinLoader = (id) => {
  var element = document.getElementById(id);
  setTimeout(() => {
    element.style.display = "none";
  }, 1200);
};

const removeAppnedMsg = (
  fieldName,
  returnConfirmValue,
  returnValue,
  errors_obj,
  message,
  index = -1,
  NoBorder = 0
) => {
  var error_fieldName = "error_" + fieldName;
  var removefirstErrorElementName = fieldName;

  if (index >= 0) {
    error_fieldName = "error_" + fieldName + "." + index;
    removefirstErrorElementName = fieldName + "." + index;
  }

  var removefiled = document.getElementById(error_fieldName);
  if (removefiled) {
    removefiled.remove();
  }
  let removefirstErrorElement = document.getElementsByName(
    removefirstErrorElementName
  )[0];

  if (removefirstErrorElement) {
    removefirstErrorElement.classList.remove("form_error_field");
  }

  //Append Validation
  if (returnValue == false) {
    returnConfirmValue = false;
    errors_obj[removefirstErrorElementName] = returnValue;
    let firstErrorElement = document.getElementsByName(
      removefirstErrorElementName
    )[0];
    if (firstErrorElement != undefined) {
      if (NoBorder == 0) {
        firstErrorElement.classList.add("form_error_field");
      }
      firstErrorElement = firstErrorElement.parentNode;
      firstErrorElement.insertAdjacentHTML(
        "afterend",
        "<p class='form_error' id='error_" +
        removefirstErrorElementName +
        "'>" +
        message +
        "</p>"
      );
    }
  }

  let newErrorObj = errors_obj;
  if (Object.keys(errors_obj).length > 0) {
    returnConfirmValue = false;
  }

  return { errors_obj: newErrorObj, returnConfirmValue: returnConfirmValue };
};

const validateField = async (feilds, formData, isServer = 0) => {
  try {
    var returnConfirmValue = true;
    var returnValue = true;
    var errors_obj = {};
    var errors_obj_data = { errors_obj: {}, returnConfirmValue: true };
    var removefiled = document.getElementsByClassName("form_error")[0];
    if (removefiled) {
      removefiled.remove();
    }

    Object.entries(feilds).map((value, key) => {
      var message = "";
      var fieldName = value[0];

      returnValue = true;

      if (isServer == 1) {
        returnValue = false;
        message = value[1];
        errors_obj_data = removeAppnedMsg(
          fieldName,
          returnConfirmValue,
          returnValue,
          errors_obj,
          message
        );
      } else {
        var allField = fieldName.split(/_(.*)/s);
        var fieldNameNew = allField[1];
        if (allField[0] != undefined) {
          if (allField[0] == "arr") {
            var allValue = value[1].split("|");
            if (allValue[0] != undefined) {
              var newValue = allValue[0];
              if (newValue == "array") {
                for (let i = 0; i < formData[fieldNameNew].length; i++) {
                  if (formData[fieldNameNew][i] == "") {
                    var NoBorder = 0;
                    if (allValue[1].toLowerCase() == "required") {
                      var fieldName2 =
                        fieldNameNew.charAt(0).toUpperCase() +
                        fieldNameNew.slice(1).toLowerCase();

                      message =
                        fieldName2.split("_").join(" ") + " field is required!";
                      if (
                        formData[fieldNameNew][i] == "" ||
                        formData[fieldNameNew][i] == undefined
                      ) {
                        returnValue = false;
                      }
                    }

                    //Required Validation Start With No Border ------------------
                    if (allValue[1].toLowerCase() == "requirednoborder") {
                      var fieldName2 =
                        fieldNameNew.charAt(0).toUpperCase() +
                        fieldNameNew.slice(1).toLowerCase();

                      message =
                        fieldName2.split("_").join(" ") + " field is required!";
                      if (
                        formData[fieldNameNew][i] === "" ||
                        formData[fieldNameNew][i] == undefined
                      ) {
                        NoBorder = 1;
                        returnValue = false;
                      }
                    }
                    //Required Validation  End  With No Border------------------
                  }
                  errors_obj_data = removeAppnedMsg(
                    fieldNameNew,
                    returnConfirmValue,
                    returnValue,
                    errors_obj,
                    message,
                    i,
                    NoBorder
                  );
                }
              } else {
                for (let i = 0; i < formData[newValue].length; i++) {
                  if (formData[newValue][i][fieldNameNew] == "") {
                    if (allValue[1].toLowerCase() == "required") {
                      var fieldName2 =
                        fieldNameNew.charAt(0).toUpperCase() +
                        fieldNameNew.slice(1).toLowerCase();
                      message =
                        fieldName2.split("_").join(" ") + " field is required!";
                      if (
                        formData[newValue][i][fieldNameNew][i] == "" ||
                        formData[newValue][i][fieldNameNew][i] == undefined
                      ) {
                        returnValue = false;
                      }
                    }
                  }

                  errors_obj_data = removeAppnedMsg(
                    fieldNameNew,
                    returnConfirmValue,
                    returnValue,
                    errors_obj,
                    message,
                    i
                  );
                }
              }
            }
          } else {
            if (value[1] != undefined) {
              value[1].split("|").map((value2, key2) => {
                if (value2 != undefined && value2 != "") {
                  var NoBorder = 0;

                  var fieldName2 =
                    fieldName.charAt(0).toUpperCase() +
                    fieldName.slice(1).toLowerCase();
                  var message = "";

                  //Required Validation Start  ------------------
                  if (value2.toLowerCase() == "required") {
                    message =
                      fieldName2.split("_").join(" ") + " field is required!";
                    if (
                      formData[fieldName] === "" ||
                      formData[fieldName] == undefined
                    ) {
                      returnValue = false;
                    }
                  }
                  //Required Validation  End  ------------------

                  //Required Validation Start With No Border ------------------
                  if (value2.toLowerCase() == "requirednoborder") {
                    message =
                      fieldName2.split("_").join(" ") + " field is required!";
                    if (
                      formData[fieldName] == "" ||
                      formData[fieldName] == undefined
                    ) {
                      NoBorder = 1;
                      returnValue = false;
                    }
                  }
                  //Required Validation  End  With No Border------------------

                  //Email Validation Start  ------------------
                  if (value2.toLowerCase() == "email") {
                    message =
                      fieldName2.split("_").join(" ") +
                      " Please enter valid email";
                    ///Validdation
                    if (!validator.isEmail(formData[fieldName])) {
                      returnValue = false;
                    }
                  }
                  //Email Validation End  --------------------

                  ///Same Validation Start  ------------------
                  if (value2.includes(":")) {
                    if (
                      value2.split(":")[0] != undefined &&
                      value2.split(":")[0] == "same"
                    ) {
                      var checkSameName = value2.split(":")[1];
                      checkSameName =
                        checkSameName.charAt(0).toUpperCase() +
                        checkSameName
                          .slice(1)
                          .split("_")
                          .join(" ")
                          .toLowerCase();
                      message =
                        fieldName2.split("_").join(" ") +
                        " not match the " +
                        checkSameName;
                      if (
                        formData[fieldName] !== formData[value2.split(":")[1]]
                      ) {
                        returnValue = false;
                      }
                    } else if (
                      value2.split(":")[0] != undefined &&
                      value2.split(":")[0] == "minimum"
                    ) {
                      var minimumFile = value2.split(":")[1];
                      var existFile = formData.files.length;

                      if (existFile < minimumFile) {
                        message =
                          "Minimum " +
                          minimumFile +
                          " " +
                          fieldName2.split("_").join(" ") +
                          " required ";
                        if (
                          formData[fieldName] !== formData[value2.split(":")[1]]
                        ) {
                          returnValue = false;
                        }
                      }
                    } else if (
                      value2.split(":")[0] != undefined &&
                      value2.split(":")[0] == "maximum"
                    ) {
                      var maximumFile = value2.split(":")[1];
                      var existMaxFile = formData.files.length;

                      if (existMaxFile > maximumFile) {
                        message =
                          "Maximum " +
                          maximumFile +
                          " " +
                          fieldName2.split("_").join(" ") +
                          " allowed ";
                        if (
                          formData[fieldName] !== formData[value2.split(":")[1]]
                        ) {
                          returnValue = false;
                        }
                      }
                    } else if (
                      value2.split(":")[0] != undefined &&
                      value2.split(":")[0] == "min"
                    ) {
                      var minNo = value2.split(":")[1];

                      if (formData[fieldName] <= minNo) {
                        message =
                          fieldName2.split("_").join(" ") +
                          " must be greater then " +
                          minNo;
                        if (
                          formData[fieldName] !== formData[value2.split(":")[1]]
                        ) {
                          returnValue = false;
                        }
                      }
                    }
                  }
                  errors_obj_data = removeAppnedMsg(
                    fieldName,
                    returnConfirmValue,
                    returnValue,
                    errors_obj,
                    message,
                    -1,
                    NoBorder
                  );
                }
              });
            }
          }
        }
      }
    });

    handleScrollError(errors_obj_data.errors_obj);
    return errors_obj_data.returnConfirmValue;
  } catch (error) {
    console.log(error);
  }
};

// const characterLengthCheck = async (value, maxLength) => {
//   try {
//     let currentLength = value.length;
//     let remainingLength = maxLength - currentLength;
//     return remainingLength;
//   } catch (error) {
//     return false
//   }
// }

const characterLengthCheck = async (value, maxLength, t) => {
  try {
    var remainingChars = maxLength - value.length;
    let response = {};
    if (remainingChars > 0) {
      response = {
        message: t("_Minimum_charactres_required") + "\u00a0" + remainingChars,
        status: false,
      };
    } else {
      response = {
        message: t("_Remaining_characters") + "\u00a0" + remainingChars,
        status: true,
      };
    }
    return response;
  } catch (error) {
    return false;
  }
};

const checkInputLength = async (input, minLength, maxLength, t) => {
  try {
    var inputLength = input.length;
    let response = {};

    if (inputLength < minLength) {
      response = {
        message: t("_Minimum_charactres_required") + "\u00a0" + minLength,
        status: false,
      };
    } else {
      var remainingChars = maxLength - inputLength;
      if (remainingChars > 0) {
        response = {
          message: t("_Remaining_characters") + "\u00a0" + remainingChars,
          status: false,
        };
      } else {
        response = {
          message: t("_Remaining_characters") + "\u00a0" + remainingChars,
          status: true,
        };
      }
    }
    return response;
  } catch (error) {
    return false;
  }
};

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([intArray], { type: mimeString });
};

const checkDatePassed = (timeString, is_for_review = 0) => {
  // console.log("timeString", timeString);
  // let timeString = "04/15/2024 2:00 PM"F
  // const isoFormat = moment(timeString, "MM/DD/YYYY hh:mm A").toISOString();
  // const comparisonDate = new Date(isoFormat);
  // let inputTimestamp = comparisonDate.getTime(); //Timestamp for input date
  let inputTimestamp = timeString; //Timestamp for input date

  // console.log("inputTimestamp", inputTimestamp, Date.now());
  let status = inputTimestamp > Date.now();
  if (is_for_review == 1) {
    let currentDateTime = Date.now();
    currentDateTime = currentDateTime.toString();
    currentDateTime = currentDateTime.slice(0, -3);
    currentDateTime = parseInt(currentDateTime);
    status = currentDateTime > inputTimestamp;
  }
  // console.log("status", status);
  return status;
};

const getCurrentUTCTime = (setCurrentTime) => {
  fetch("https://worldtimeapi.org/api/timezone/Etc/UTC").then((response) => {
    response.json().then((data) => {
      const datetime = data.datetime;
      const currentTimestamp = Date.parse(datetime);
      setCurrentTime(currentTimestamp);
    });
  });
};

const CountdownTimer = ({ targetTime, onTimerEnd }) => {
  let countDownTime = targetTime - Math.floor(Date.now() / 1000);
  const [timeRemaining, setTimeRemaining] = useState(countDownTime);

  useEffect(() => {
    if (timeRemaining > 0) {
      const intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTime = prevTime - 1;
          if (newTime === 0) {
            clearInterval(intervalId);
            // Call the callback function when the timer ends
            onTimerEnd();
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [countDownTime, timeRemaining, onTimerEnd]);

  const formatTime = (countDownTime) => {
    const minutes = Math.floor(countDownTime / 60);
    const seconds = countDownTime % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return timeRemaining > 0 ? (
    <span className="timer-message">
      <div class="alert  timer-content" role="alert">
        <div>We hold your spot for {formatTime(timeRemaining)} minutes</div>
      </div>
    </span>
  ) : null;
};

// export const getAddressFromLatLng=(lat, lng) => {
//   const geocoder = new window.google.maps.Geocoder();
//   const latLng = new window.google.maps.LatLng(lat, lng);

//   geocoder.geocode({ 'location': latLng }, (results, status) => {
//     if (status === 'OK') {
//       if (results[0]) {
//         console.log('Address:', results[0].formatted_address);
//       } else {
//         console.log('No results found');
//       }
//     } else {
//       console.log('Geocoder failed due to: ' + status);
//     }
//   });
// }

// Example usage

const getLocationInfo = async (latitude, longitude,googleKey) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleKey}`;
  try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
          return {
              label: data.results[0].formatted_address,
              value: {
                  description: data.results[0].formatted_address,
                  place_id: data.results[0].place_id,
                  structured_formatting: {
                      main_text: data.results[0].formatted_address,
                      secondary_text: ''
                  },
                  terms: data.results[0].address_components.map(component => ({
                      offset: component.types[0],
                      value: component.long_name
                  })),
                  types: data.results[0].types
              }
          };

      } else {
          console.error('Failed to retrieve place details:', data.status);
          return null;
      }
  } catch (error) {
      console.error('Error fetching location information:', error);
      return null;
  }
}

const useScript = (src) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
};

export {
  images,
  GetRoutes,
  errorMsg,
  successMsg,
  setGetToken,
  handleScrollError,
  readCookie,
  handleScroll,
  NumberFormat,
  MetaData,
  setSessionCookie,
  getUrlParams,
  getArray,
  validateField,
  stopSpinLoader,
  startSpinLoader,
  characterLengthCheck,
  checkInputLength,
  modules,
  formats,
  dataURItoBlob,
  checkDatePassed,
  getCurrentUTCTime,
  CountdownTimer,
  getTimeone,
  getLocationInfo,
  useScript
};
