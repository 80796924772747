import React from "react";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { t } = useTranslation();
  return (
    <div className="Newsletter_main">
      <div className="Newsletter_content">
        <h2>{t("_Your_new_york_city_itinerary_is_waiting.")}</h2>
        {/* <h2>{t('_your_New_York')}</h2> */}
        <p>
          {t(
            "_receive_a_curated_48-hour_itinerary_featuring_the_most_iconic_experiences_in_new_york_city,_straight_to_your_inbox"
          )}
        </p>
        <div className="Newsletter_input">

          <input type="text" placeholder={t("_enter_your_email")} />
          <button>{t('_signup')}</button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
