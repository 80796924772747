import axios from "axios";

const instance = axios.create({
  baseURL: "https://mytket.infosparkles.com/admin/api",
  // baseURL: "https://easemybizz.com/admin/api",
});
instance.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";

instance.interceptors.request.use((config) => {
  const currency = localStorage.getItem("currency");
  const url = window.location.hostname.split(".")[0];
  config.params = config.params || {};
  config.params["user_id"] = localStorage.getItem("userId");
  config.params["tz"] = localStorage.getItem("_tz");
  config.params["currency"] = currency !== null ? currency : "";
  config.params["url"] = url !== null ? url : "";

  return config;
});
// instance.defaults.params = {
//   user_id: localStorage.getItem("userId"),
//   currency: language.currency,
// };
export default instance;
