import { successMsg } from "./customFn";
import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg } from "./customFn";
import { profileData } from "./profileAction";

export const becomeAffiliate = (
    languageReducer,
    navigate,
) => {
    return async (dispatch) => {
        try {
            const currency = JSON.parse(localStorage.getItem("currency"));
            const token = localStorage.getItem("_token");
            const params = {
                user_id: localStorage.getItem("userId"),
                password: localStorage.getItem("password"),
                language: languageReducer.id,
                currency: currency.id,
                token: token,
            };

            const response = await axios.post("become-affilliate", params);
            if (response.data.status === true) {
                // localStorage.setItem("userId", response.data.user_id);
                // localStorage.setItem("password", response.data.password);
                localStorage.setItem("user_type", "Affiliate");
                successMsg(response.data.message);
                setTimeout(() => {
                    dispatch(profileData(languageReducer, navigate, true));
                }, 1000)
                // navigate("/affiliate-dashboard");
            } else {
                errorMsg("Somthing went wrong...");
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            } else {
                errorMsg(error.response.data.message);
            }
        }
        // setTimeout(() => {
        //   setIsLoading(true);
        // }, 1000);
    };
};