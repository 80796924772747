import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { useSelector } from "react-redux";
import { ProductBuilderContext } from "../../../context";
import { useTranslation } from "react-i18next";

const ExclusionModal = (props) => {
  const { t } = useTranslation();
  const languageReducer = useSelector((state) => state.languageReducer);
  const { getData,languageIdSelected } = useContext(ProductBuilderContext);
  const {
    exclusionModalShow,
    setExclusionModalShow,
    getCategory,
    getExclusionKey,
    setExclusionKey,
    getExclusion,
    setExclusion,
    completeList,
  } = props;
  const [formErrors, setFormError] = useState({});
  const errors = {};
  const [getChildCategory, setChildCategory] = useState([]);
  const [exclusionData, setExclusionData] = useState({
    exclusion_id: "",
    exclusion_parent: "",
    exclusion_parent_title: "",
    exclusion_title: "",
    exclusion_child_title: "",
  });

  const handleModalClose = () => {
    setExclusionModalShow(false);
    setExclusionKey("");
    setExclusionData({
      exclusion_id: "",
      exclusion_parent: "",
      exclusion_parent_title: "",
      exclusion_title: "",
      exclusion_child_title: "",
    });
    setFormError({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let inInclusion = false;
    let inExclusion = false;

    completeList.forEach((item) => {
      if (item.inclusion_title == value) {
        inInclusion = true;
      }
      if (item.exclusion_title == value) {
        inExclusion = true;
      }
    });

    if (inInclusion == true) {
      errors.exclusion_title = t("_this_item_is_already_selected_in_inclusion");
    } else if (inExclusion == true) {
      errors.exclusion_title = t("_this_item_is_already_selected_in_exclusion");
    } else {
      errors.exclusion_title = "";
    }

    setFormError(errors);
    setExclusionData((prev) => ({ ...prev, [name]: value }));
    if (name == "exclusion_parent") {
      setExclusionData((prev) => ({ ...prev, exclusion_title: "" }));
      setExclusionData((prev) => ({ ...prev, exclusion_child_title: "" }));
    }
  };

  const validateFormData = () => {
    let errorMessage = t("_parent_field_required");
    if (exclusionData.exclusion_parent === "") {
      errors.exclusion_parent = errorMessage;
    }

    if (
      getChildCategory?.inclusion_exclusion_child != undefined &&
      getChildCategory?.inclusion_exclusion_child.length > 0
    ) {
      if (exclusionData.exclusion_title === "") {
        errors.exclusion_title = t("_sub_Child_field_required");
      }
    }
    setFormError(errors);
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    validateFormData();
    if (Object.keys(errors).length === 0) {
      let newUpdateData = [];
      if (typeof getExclusionKey === "number") {
        newUpdateData = [...getExclusion];
        newUpdateData[getExclusionKey] = exclusionData;
        setExclusion(newUpdateData);
      } else {
        setExclusion([...getExclusion, exclusionData]);
      }
      handleModalClose();
    }
  };

  useEffect(() => {
    if (languageReducer != "" && exclusionData?.exclusion_parent != "") {
      getData("inclusion_exclusion_child", setChildCategory, {
        get_child: exclusionData.exclusion_parent,
      });
    } else {
      setChildCategory([]);
    }
  }, [exclusionData?.exclusion_parent, languageReducer,languageIdSelected]);

  useEffect(() => {
    if (getExclusion != undefined) {
      if (typeof getExclusionKey === "number") {
        if (getExclusion[getExclusionKey] != undefined) {
          setExclusionData(getExclusion[getExclusionKey]);
        } else {
          setChildCategory([]);
        }
      }
    }
  }, [getExclusionKey]);

  return (
    <Modal
      show={exclusionModalShow}
      onHide={() => {
        setExclusionModalShow(false);
      }}
      className="inclusion_pagemodels"
    >
      <Modal.Body>
        <div className="close_modelbutn">
          <button
            onClick={() => {
              handleModalClose();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                fill="#4f4d4d"
              />
            </svg>
          </button>{" "}
        </div>

        <form
          onSubmit={(e) => {
            handelSubmit(e);
          }}
        >
          <div className="inclusion_models">
            <h1>{t("_Add_an_Exclusion")}</h1>
            <p>
              {t(
                "_make_your_list_precise_so_travelers_understand_the_value_of_this_experience._Don’t_add_more_than_10_items_and_avoid_duplicates."
              )}
            </p>

            <h4>{t("_category")}</h4>

            <div className="inclusion_modelselector mb-5">
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                error={formErrors.exclusion_parent ? true : false}
              >
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  placeholder="Select a Category"
                  value={exclusionData?.exclusion_parent}
                  name="exclusion_parent"
                  label="exclusion_parent"
                  onChange={handleChange}
                >
                  {getCategory != undefined &&
                    getCategory.length > 0 &&
                    getCategory.map((value, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={value.id}
                          onClick={(e) => {
                            setExclusionData((prev) => ({
                              ...prev,
                              exclusion_parent_title: value.title,
                            }));
                          }}
                        >
                          {value.title}
                        </MenuItem>
                      );
                    })}
                </Select>

                <FormHelperText className="form_error">
                  {formErrors?.exclusion_parent}
                </FormHelperText>
              </FormControl>
            </div>

            {/* {categorystate == 3 ? (
              <>
                <div className="top-header-info-box mb-4">
                  <div className="icon-svg-info">
                    <svg
                      viewBox="0 0 27 36"
                      xmlns="http://www.w3.org/2000/svg"
                      class="Alert__alertIcon___S7wRU Icon__icon___2sbfU"
                    >
                      <g fill-rule="nonzero">
                        <path d="M17.97 34.81v.25h-9a.7.7 0 01-.7-.7V28.5h1.4v5.16h7.6V28.5h1.4v5.86a.7.7 0 01-.7.7v-.25z"></path>
                        <path d="M20.22 23.45l.24-.23c.77-.76 1.11-1.12 1.51-1.65a11.5 11.5 0 003.05-7.82A11.6 11.6 0 0013.43 2.2 11.57 11.57 0 005 21.63c.35.47.69.83 1.46 1.59l.24.23c1.77 1.74 2.52 2.98 2.56 5h8.42c.03-2.02.78-3.26 2.55-5zm-2.25 5.74v.75h-9a1.23 1.23 0 01-1.22-1.21c.03-1.7-.52-2.64-2.12-4.22l-.23-.22c-.83-.81-1.2-1.2-1.56-1.7A13.03 13.03 0 01.4 13.76C.4 6.56 6.24.69 13.45.69a13.1 13.1 0 0113.06 13.07 13 13 0 01-3.4 8.77c-.41.55-.78.95-1.61 1.76l-.23.23c-1.6 1.57-2.14 2.51-2.11 4.2a1.2 1.2 0 01-1.2 1.22v-.75z"></path>
                        <path d="M4.44 14H3.29v-.24a10.2 10.2 0 014.2-8.23l.2-.15.82 1.13-.2.15a8.8 8.8 0 00-3.62 7.1h-.25V14zM9.22 5.79l-.5-1.03.22-.11c.36-.18.69-.32 1.01-.44l.24-.08.48 1.31-.24.09c-.28.1-.56.22-.87.37l-.11-.22-.23.1zM7.67 32.25v-1.4h11.6v1.4z"></path>
                      </g>
                    </svg>
                  </div>
                  <div className="meet-box-content">
                    <p>
                      We assume all fees and taxes are included, as stated in your
                      agreement. If you want to highlight any special fees or
                      taxes that are included, please specify below.
                    </p>
                  </div>
                </div>
              </>
            ) : categorystate == 4 ? (
              <div className="top-header-info-box mb-4">
                <div className="icon-svg-info">
                  <svg
                    viewBox="0 0 27 36"
                    xmlns="http://www.w3.org/2000/svg"
                    class="Alert__alertIcon___S7wRU Icon__icon___2sbfU"
                  >
                    <g fill-rule="nonzero">
                      <path d="M17.97 34.81v.25h-9a.7.7 0 01-.7-.7V28.5h1.4v5.16h7.6V28.5h1.4v5.86a.7.7 0 01-.7.7v-.25z"></path>
                      <path d="M20.22 23.45l.24-.23c.77-.76 1.11-1.12 1.51-1.65a11.5 11.5 0 003.05-7.82A11.6 11.6 0 0013.43 2.2 11.57 11.57 0 005 21.63c.35.47.69.83 1.46 1.59l.24.23c1.77 1.74 2.52 2.98 2.56 5h8.42c.03-2.02.78-3.26 2.55-5zm-2.25 5.74v.75h-9a1.23 1.23 0 01-1.22-1.21c.03-1.7-.52-2.64-2.12-4.22l-.23-.22c-.83-.81-1.2-1.2-1.56-1.7A13.03 13.03 0 01.4 13.76C.4 6.56 6.24.69 13.45.69a13.1 13.1 0 0113.06 13.07 13 13 0 01-3.4 8.77c-.41.55-.78.95-1.61 1.76l-.23.23c-1.6 1.57-2.14 2.51-2.11 4.2a1.2 1.2 0 01-1.2 1.22v-.75z"></path>
                      <path d="M4.44 14H3.29v-.24a10.2 10.2 0 014.2-8.23l.2-.15.82 1.13-.2.15a8.8 8.8 0 00-3.62 7.1h-.25V14zM9.22 5.79l-.5-1.03.22-.11c.36-.18.69-.32 1.01-.44l.24-.08.48 1.31-.24.09c-.28.1-.56.22-.87.37l-.11-.22-.23.1zM7.67 32.25v-1.4h11.6v1.4z"></path>
                    </g>
                  </svg>
                </div>
                <div className="meet-box-content">
                  <p>
                    Be careful when adding your own Exclusions! Don’t add
                    information travelers can find elsewhere, such as the duration
                    of your experience, discounts, or languages offered.
                  </p>
                </div>
              </div>
            ) : (
              ""
            )} */}

            {getChildCategory?.inclusion_exclusion_child != undefined &&
              getChildCategory?.inclusion_exclusion_child.length > 0 ? (
              <div>
                <h4>{t("_sub_category")}</h4>

                <div className="inclusion_modelselector mb-5">
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    error={formErrors.Exclusion_title ? true : false}
                  >
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      name="exclusion_title"
                      value={exclusionData.exclusion_title}
                      onChange={handleChange}
                    >
                      {getChildCategory?.inclusion_exclusion_child.map(
                        (value, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={value.id}
                              onClick={(e) => {
                                setExclusionData((prev) => ({
                                  ...prev,
                                  exclusion_child_title: value.title,
                                }));
                              }}
                            >
                              {value.title}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>

                    <FormHelperText className="form_error">
                      {formErrors?.exclusion_title}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* {categorystate === 2 ? (
              <>
                <h4>Description</h4>
                <input className="normal_input" type="text" />
              </>
            ) : (
              ""
            )} */}

            <div className="model_lastendbtns">
              <button
                className="remove-allsave mr-4"
                onClick={() => {
                  handleModalClose();
                }}
              >
                {t("_cancel")}
              </button>

              {formErrors?.exclusion_title ? (
                <span className="btnall-save disabled-save-btn">
                  {t("_Add")}
                </span>
              ) : (
                <button type="submit" className="btnall-save ">
                  {t("_Add")}
                </button>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ExclusionModal;
