import { useContext, useRef, useState } from "react";
import {
  PrincingScheduleContext,
  ProductBuilderContext,
} from "../../../context";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "flatpickr/dist/flatpickr.css";
import { useParams } from "react-router-dom";
import { Select, MenuItem } from "@mui/material";
import EditDiscount from "./EditDiscount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/fontawesome-free-regular";
const IndividualAddPricing = () => {
  const { t } = useTranslation();
  const { tour_id, option_id } = useParams();
  const {
    formData,
    setFormData,
    // handleChange,
    formErrors,
    setSlidePages,
    CloseAddOPtionModel,
    // pricingType,
    submitData,
  } = useContext(PrincingScheduleContext);

  const {
    setTourId,
    getData,
    setDataLoad,
    dataLoad,
    runningDtep,
    productType,
  } = useContext(ProductBuilderContext);
  const [isShow, setIsShow] = useState(false);
  const [ageGroup, setAgeGroup] = useState(["child", "infant"]);
  const [optionPricingData, setData] = useState("");
  const [ageGroupSelected, setAgeGroupSelected] = useState("");


  const handleChanges = (e) => {
    const { name, value, checked } = e.target;
    if (name == "is_discount") {
      setFormData((prev) => ({ ...prev, [name]: checked ? "yes" : "no" }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const HandleChangeData = (e, PriceType) => {
    const { name, value } = e.target;
    const new_obj = {
      ...formData,
      prices: {
        ...formData["prices"],
        [PriceType]: { ...formData["prices"][PriceType], [name]: value },
      },
    };
    setFormData(new_obj);
  };

  const setupPriceTier = (e, PriceType) => {
    let from_no_people = 0;
    let to_no_people = 51;
    const no_of_people = formData["prices"][PriceType]["no_of_people"];
    let lastElement = no_of_people[no_of_people.length - 1];
    if (lastElement !== undefined) {
      from_no_people = lastElement.to + 1;
      to_no_people = from_no_people + 50;
    }

    let no_of_people_obj = {
      from: from_no_people,
      to: to_no_people,
    };

    const new_obj = {
      ...formData,
      prices: {
        ...formData["prices"],
        [PriceType]: {
          ...formData["prices"][PriceType],
          no_of_people: [
            ...formData["prices"][PriceType]["no_of_people"],
            no_of_people_obj,
          ],
          commission: [...formData["prices"][PriceType]["commission"], 30],
          payout_per_person: [
            ...formData["prices"][PriceType]["payout_per_person"],
            0,
          ],
          retail_price: [...formData["prices"][PriceType]["retail_price"], 0],
        },
      },
    };

    setFormData(new_obj);
  };

  const AppendPriceType = (e) => {
    setAgeGroupSelected(e.target.value);
    let PriceNewObj = {};
    let new_adult_obj = {};
    let new_age_from = 0;
    let new_age_to = 18;
    if (e.target.value == "infant") {
      if (formData["prices"]["child"] !== undefined) {
        new_age_to = 9;
        new_adult_obj = {
          child: {
            ...formData["prices"]["child"],
            age_from: 10,
            age_to: 18,
          },
          adult: {
            ...formData["prices"]["adult"],
            age_from: 19,
            age_to: 99,
          },
        };
      } else {
        new_age_to = 9;
        new_adult_obj = {
          ...formData["prices"],
          adult: {
            ...formData["prices"]["adult"],
            age_from: 10,
            age_to: 99,
          },
        };
      }
    } else {
      if (formData["prices"]["infant"] !== undefined) {
        new_age_from = 10;
        new_adult_obj = {
          infant: {
            ...formData["prices"]["infant"],
            age_from: 0,
            age_to: 9,
          },
          adult: {
            ...formData["prices"]["adult"],
            age_from: 19,
            age_to: 99,
          },
        };
      } else {
        new_adult_obj = {
          ...formData["prices"],

          adult: {
            ...formData["prices"]["adult"],
            age_from: new_age_to + 1,
            age_to: 99,
          },
        };
      }
    }

    let price_obj = {
      [e.target.value]: {
        age_from: new_age_from,
        age_to: new_age_to,
        booking_category: "standard",
        no_of_people: [
          {
            from: 1,
            to: 51,
          },
        ],
        retail_price: [0],
        commission: [30],
        payout_per_person: [0],
      },
    };

    PriceNewObj = { ...price_obj, ...new_adult_obj };

    let ordered = Object.keys(PriceNewObj)
      .sort()
      .reduce((obj, key) => {
        obj[key] = PriceNewObj[key];
        return obj;
      }, {});

    let new_obj_reverse = {};
    let rev_obj = Object.keys(ordered).reverse();
    rev_obj.forEach(function (i) {
      new_obj_reverse[i] = ordered[i];
    });

    const new_obj = {
      ...formData,
      prices: new_obj_reverse,
    };

    setFormData(new_obj);
    setAgeGroup((ageGroup) => ageGroup.filter((val) => val !== e.target.value));

    setTimeout(() => {
      scrollToDiv(e.target.value);
    }, [1000]);

    // }
  };

  const HandleNoChange = (e, key, PriceType, type = "") => {
    const value = formData["prices"][PriceType]["no_of_people"][key]["to"];
    let LastVal = 2;
    if (type == "decrement") {
      LastVal = 3;
    }
    var newArr = [];
    const no_of_people_data = formData["prices"][PriceType]["no_of_people"];
    if (value >= LastVal) {
      no_of_people_data.map((Newvalue, index) => {
        let PeopleArr = {};
        if (type == "increment") {
          if (index > 0) {
            if (index >= key) {
              if (index > key) {
                PeopleArr["from"] = parseInt(Newvalue.from) + 1;
              } else {
                PeopleArr["from"] = parseInt(Newvalue.from);
              }

              PeopleArr["to"] = parseInt(Newvalue.to) + 1;
            } else {
              PeopleArr["from"] = parseInt(Newvalue.from);
              PeopleArr["to"] = parseInt(Newvalue.to);
            }
          } else {
            if (key == 0 && index == 0) {
              PeopleArr["from"] = 1;
              PeopleArr["to"] = parseInt(value) + 1;
            } else {
              PeopleArr["from"] = parseInt(Newvalue.from);
              PeopleArr["to"] = parseInt(Newvalue.to);
            }
          }
        } else {
          if (index > 0) {
            if (index >= key) {
              if (index > key) {
                PeopleArr["from"] = parseInt(Newvalue.from) - 1;
              } else {
                PeopleArr["from"] = parseInt(Newvalue.from);
              }
              PeopleArr["to"] = parseInt(Newvalue.to) - 1;
            } else {
              PeopleArr["from"] = parseInt(Newvalue.from);
              PeopleArr["to"] = parseInt(Newvalue.to);
            }
          } else {
            if (key == 0 && index == 0) {
              PeopleArr["from"] = 1;
              PeopleArr["to"] = parseInt(value) - 1;
            } else {
              PeopleArr["from"] = parseInt(Newvalue.from);
              PeopleArr["to"] = parseInt(Newvalue.to);
            }
          }
        }

        newArr.push(PeopleArr);
      });

      const new_obj = {
        ...formData,
        prices: {
          ...formData["prices"],
          [PriceType]: {
            ...formData["prices"][PriceType],
            no_of_people: [...newArr],
          },
        },
      };

      setFormData(new_obj);
    }
  };

  const CalculatePayout = (e, key, PriceType) => {
    const { value } = e.target;

    let percentage = formData["prices"][PriceType]["commission"][key];
    var calculatedPercentage = (percentage / 100) * value;
    var finalValue = parseFloat(value) - parseFloat(calculatedPercentage);

    let newStateS = [...formData["prices"][PriceType]["payout_per_person"]];
    if (newStateS[key] != undefined) {
      newStateS[key] = finalValue;
    }

    let newStateRetailPrice = [
      ...formData["prices"][PriceType]["retail_price"],
    ];
    if (newStateRetailPrice[key] != undefined) {
      newStateRetailPrice[key] = value;
    }

    const new_obj = {
      ...formData,
      prices: {
        ...formData["prices"],
        [PriceType]: {
          ...formData["prices"][PriceType],

          payout_per_person: newStateS,
          retail_price: newStateRetailPrice,
        },
      },
    };

    setFormData(new_obj);
  };

  const GetAgeRange = (item) => {
    var ageRange = [];
    var lastAge = 99;
    if (item.value[0] == "infant") {
      lastAge = 9;
    }
    if (item.value[0] == "child") {
      lastAge = 18;
    }
    for (let index = item.value[1].age_from + 1; index < lastAge + 1; index++) {
      ageRange.push(
        <option
          value={index}
          selected={index == item.value[1].age_to}
          key={index}
        >
          {index}
        </option>
      );
    }
    return ageRange;
  };

  // Get Age Range CHange

  const getAgeReangeChange = (e, item) => {
    const { value } = e.target;

    let new_adult_obj = {};
    let new_obj = {};
    if (item[0] == "infant") {
      if (formData["prices"]["child"] !== undefined) {
        // For Child Section
        if (formData["prices"]["child"].age_to < 18) {
          new_adult_obj = {
            child: {
              ...formData["prices"]["child"],
              age_from: parseInt(value) + 1,
              age_to: formData["prices"]["child"].age_to,
            },
            adult: {
              ...formData["prices"]["adult"],
              age_from: parseInt(value) + 3,
              age_to: formData["prices"]["adult"].age_to,
            },
            [item[0]]: {
              ...formData["prices"][item[0]],
              age_from: formData["prices"][item[0]].age_from,
              age_to: parseInt(value),
            },
          };
        } else {
          new_adult_obj = {
            child: {
              ...formData["prices"]["child"],
              age_from: parseInt(value) + 1,
              age_to: formData["prices"]["child"].age_to,
            },

            [item[0]]: {
              ...formData["prices"][item[0]],
              age_from: formData["prices"][item[0]].age_from,
              age_to: parseInt(value),
            },
          };
        }

        console.log("new_adult_obj", new_adult_obj);
        new_obj = {
          ...formData,
          prices: { ...formData["prices"], ...new_adult_obj },
        };
      } else {
        new_adult_obj = {
          adult: {
            ...formData["prices"]["adult"],
            age_from: parseInt(value) + 1,
            age_to: formData["prices"]["adult"].age_to,
          },
          [item[0]]: {
            ...formData["prices"][item[0]],
            age_from: formData["prices"][item[0]].age_from,
            age_to: parseInt(value),
          },
        };

        new_obj = {
          ...formData,
          prices: { ...formData["prices"], ...new_adult_obj },
        };
      }
    } else {
      new_adult_obj = {
        adult: {
          ...formData["prices"]["adult"],
          age_from: parseInt(value) + 1,
          age_to: formData["prices"]["adult"].age_to,
        },
        [item[0]]: {
          ...formData["prices"][item[0]],
          age_from: formData["prices"][item[0]].age_from,
          age_to: parseInt(value),
        },
      };

      new_obj = {
        ...formData,
        prices: { ...formData["prices"], ...new_adult_obj },
      };
    }

    setFormData(new_obj);
  };

  const removePriceTier = (item, index) => {
    let maxLimit = formData.prices[item[0]].no_of_people.length;
    let from = formData.prices[item[0]].no_of_people[index].from;
    let to = formData.prices[item[0]].no_of_people[index].to;

    for (let i = index + 1; i < maxLimit; i++) {
      formData.prices[item[0]].no_of_people[i].from = from;
      formData.prices[item[0]].no_of_people[i].to = to;
      from = from + 51;
      to = to + 51;
    }

    formData.prices[item[0]].commission.splice(index, 1);
    formData.prices[item[0]].no_of_people.splice(index, 1);
    formData.prices[item[0]].payout_per_person.splice(index, 1);
    formData.prices[item[0]].retail_price.splice(index, 1);

    setFormData((prev) => ({ ...prev, formData }));
  };

  const removeComplete = (item, index) => {
    if (item[0] == "infant") {
      if (formData.prices["child"] !== undefined) {
        formData.prices["child"].age_from = 0;
        formData.prices["child"].to = 18;
        formData.prices["adult"].age_from = 19;
        formData.prices["adult"].to = 99;
      } else {
        formData.prices["adult"].age_from = 0;
        formData.prices["adult"].to = 99;
      }
    } else {
      if (formData.prices["infant"] !== undefined) {
        formData.prices["adult"].age_from =
          formData.prices["infant"].age_to + 1;
        formData.prices["adult"].to = 99;
      } else {
        formData.prices["adult"].age_from = 0;
        formData.prices["adult"].to = 99;
      }
    }
    delete formData.prices[item[0]];
    setFormData((prev) => ({ ...prev, formData }));
    setAgeGroup((prev) => [...prev, item[0]]);
  };

  // console.log();

  const cancelOption = () => {
    // if (setisopen !== "") {
    //   setisopen(false);
    // }
    // navigate(`/tours-files/option-pricing/${tour_id}/${option_id}`);
  };

  // =============scroll on step change starts=======
  const infantRef = useRef(null);
  const childRef = useRef(null);
  const newRef = useRef(null);

  const scrollToDiv = (dynamicRef) => {
    if (dynamicRef == "infant") {
      if (infantRef.current) {
        infantRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    } else if (dynamicRef == "child") {
      if (childRef.current) {
        childRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    } else {
      // Do nothing
    }
  };
  // =============scroll on step change ends=======

  return (
    <div>
      <>
        {isShow == false && (
          <div className="newpricing_section new-main-pricing">
            <div className="newpricing">
              <p>{t("_name")} </p>
              <div className="tourfile_input">
                <input
                  type="text"
                  name="price_name"
                  value={formData.price_name}
                  onChange={handleChanges}
                />
              </div>
            </div>

            <div className="number_pricing">
              <p>{t("_minimum_participants_per_booking")}</p>
              <input
                className="form-control w-10"
                type="number"
                name="minimum_participant"
                value={formData.minimum_participant}
                onChange={handleChanges}
              />
            </div>

            <div className="group_box">
              <h6>{t("_prices_per_group")}</h6>
              {formData.prices &&
                Object.entries(formData.prices).map((item, index) => {
                  return (
                    <section
                      className={`group_main_inner_box ${
                        (ageGroupSelected == "infant" && item[0] == "infant") ||
                        (ageGroupSelected == "child" && item[0] == "child")
                          ? "highligt-section"
                          : ""
                      }`}
                      key={index}
                      ref={
                        item[0] == "infant"
                          ? infantRef
                          : item[0] == "child"
                          ? childRef
                          : null
                      }
                      id={item[0]}
                    >
                      {item[0] !== "adult" && (
                        <div className="entry_cross_btn">
                          <span onClick={() => removeComplete(item, index)}>
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="red"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.27916 12.7208C1.45797 12.8996 1.70044 13 1.95327 13C2.2061 13 2.44857 12.8996 2.62737 12.7208L7 8.34821L11.3726 12.7208C11.5514 12.8996 11.7939 13 12.0467 13C12.2996 13 12.542 12.8996 12.7208 12.7208C12.8996 12.542 13 12.2996 13 12.0467C13 11.7939 12.8996 11.5514 12.7208 11.3726L8.34821 7L12.7208 2.62737C12.8996 2.44857 13 2.2061 13 1.95327C13 1.70044 12.8996 1.45797 12.7208 1.27916C12.542 1.10041 12.2996 1 12.0467 1C11.7939 1 11.5514 1.10041 11.3726 1.27916L7 5.65179L2.62737 1.27916C2.44857 1.10041 2.2061 0.999999 1.95327 0.999999C1.70044 0.999999 1.45797 1.10041 1.27916 1.27916C1.10042 1.45796 1 1.70044 1 1.95327C1 2.20609 1.10042 2.44857 1.27916 2.62737L5.65179 7L1.27916 11.3726C1.10041 11.5514 1 11.7939 1 12.0467C1 12.2996 1.10041 12.542 1.27916 12.7208Z"
                                fill="white"
                                stroke="white"
                                strokeWidth="0.5"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      )}
                      <h5>{item[0]}</h5>
                      <div className="group_main_inner_main new-group-main">
                        <div className="group_main_box">
                          <div className="group_heading">
                            <p>{t("_age_range")}</p>
                            <div className="search_minutes_option_setup">
                              <span>
                                {item[1].age_from} {"-"}
                              </span>
                              <select
                                name="hr"
                                onChange={(e) => getAgeReangeChange(e, item)}
                              >
                                <GetAgeRange value={item} />
                              </select>
                            </div>
                          </div>
                          <div className="booking_category">
                            <div className="radio_btn_transpoetantion">
                              <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                  <p>{t("_booking_category")} </p>
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="booking_category"
                                  onChange={(e) => HandleChangeData(e, item[0])}
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label={t("_Standard")}
                                    value="standard"
                                    checked={
                                      item[1].booking_category === "standard"
                                    }
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label={t("_Free_ticket")}
                                    value="free"
                                    checked={
                                      item[1].booking_category === "free"
                                    }
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label={t("_Free_no_ticket_required")}
                                    value="free_no_ticket"
                                    checked={
                                      item[1].booking_category ===
                                      "free_no_ticket"
                                    }
                                  />
                                  <FormControlLabel
                                    value="not_permitted"
                                    control={<Radio />}
                                    label={t("_Not_permitted")}
                                    checked={
                                      item[1].booking_category ===
                                      "not_permitted"
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        <div className="no_of_people new-people-inner">
                          <div className="newprd-price-main">
                            <div
                              className={
                                item[1].booking_category === "free"
                                  ? " header_number_price_main d-flex display-new-head"
                                  : "header_number_price_main d-flex"
                              }
                            >
                              {item[1].booking_category == "free" ||
                              item[1].booking_category == "standard" ? (
                                <>
                                  <div className="header_number_price new-dis-box text-upper-head ">
                                    <p>{t("_number_of_people")}</p>
                                  </div>
                                  <div className="header_number_heading_text new-dis-box text-upper-head">
                                    <p> {t("_retail_price")}</p>
                                  </div>
                                  {item[1].booking_category == "standard" ? (
                                    <>
                                      <div className="header_Commission_text new-dis-box text-upper-head">
                                        <p> {t("_commission")}</p>
                                      </div>
                                      <div className="header_payout new-dis-box text-upper-head">
                                        <p> {t("_payout_per_person")}</p>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            {item[1].no_of_people.length > 0 &&
                              item[1].no_of_people.map((value, index) => {
                                return item[1].booking_category == "free" ||
                                  item[1].booking_category == "standard" ? (
                                  <div
                                    className="number_price_main mb-10 new-number-price position-relative"
                                    key={index}
                                  >
                                    <div
                                      className={
                                        item[1].booking_category === "free"
                                          ? " number_price display-new-number"
                                          : "number_price"
                                      }
                                    >
                                      {/* <header className="number_heading"> */}
                                      <div className="number_heading_text new-dis-box">
                                        {/* Number of People */}

                                        <div className="mobile-heading-text">
                                          <p>{t("_number_of_people")}</p>
                                        </div>
                                        <div className="number_text_product_new">
                                          <span>
                                            {item[1].no_of_people[index].from}-
                                          </span>

                                          <div className="box-data-show">
                                            <div className="data-text">
                                              <span>
                                                {item[1].no_of_people[index].to}
                                              </span>
                                            </div>
                                            <div className="data-btn">
                                              <div className="btn-fir">
                                                <button
                                                  onClick={(e) =>
                                                    HandleNoChange(
                                                      e,
                                                      index,
                                                      item[0],
                                                      "increment"
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                              <div className="btn-sec">
                                                <button
                                                  onClick={(e) =>
                                                    HandleNoChange(
                                                      e,
                                                      index,
                                                      item[0],
                                                      "decrement"
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="number_heading_text new-dis-box">
                                        <div className="mobile-heading-text">
                                          <p>{t("_retail_price")}</p>
                                        </div>
                                        <div className="number_text_product">
                                          <input
                                            className="form-control "
                                            type="number"
                                            onChange={(e) =>
                                              CalculatePayout(e, index, item[0])
                                            }
                                            value={item[1].retail_price[index]}
                                          />
                                          {/* <span>MRP</span> */}
                                        </div>
                                      </div>

                                      {item[1].booking_category ==
                                      "standard" ? (
                                        <>
                                          <div className="number_heading_commission_text new-dis-box">
                                            <div className="mobile-heading-text">
                                              <p>{t("_commission")}</p>
                                            </div>
                                            <div className="Commission_text">
                                              <div className="number_text_product">
                                                <span>
                                                  {item[1].commission[index]}%
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="number_heading_commission_text new-dis-box">
                                            <div className="mobile-heading-text">
                                              <p>{t("_payout_per_person")}</p>
                                            </div>
                                            <div className="Commission_text">
                                              <div className="number_text_product">
                                                <input
                                                  className="form-control "
                                                  type="number"
                                                  disabled
                                                  value={
                                                    item[1].payout_per_person[
                                                      index
                                                    ]
                                                  }
                                                />
                                                {/* <span>INR</span> */}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {index > 0 && (
                                        <span
                                          className="cursor-pointer remove_price_tier"
                                          onClick={() =>
                                            removePriceTier(
                                              item,
                                              index,
                                              item[0]
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTimesCircle}
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                );
                              })}
                            {item[1].booking_category == "free" ||
                            item[1].booking_category == "standard" ? (
                              <div className="number_navlink">
                                {/* <NavLink>set up price tiers</NavLink> */}
                                <button
                                  onClick={(e) => setupPriceTier(e, item[0])}
                                >
                                  {t("_set_up_price_tiers")}
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  );
                })}

              {ageGroup.length > 0 && (
                <div className="search_minutes_option_setup_main">
                  <div className="search_minutes_option_setup">
                    <h4>{t("_add_age_group")}</h4>
                    <Select
                      className="input_username new-edit-main-all"
                      id="product_status"
                      name="product_status"
                      placeholder="Select your gender"
                      onChange={AppendPriceType}
                    >
                      {ageGroup.map((value, index) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {/* {
                                                                value == 'child' ? t("_child") : t("_infant")
                                                            } */}
                            {value[0].toUpperCase() + value.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* {optionPricingData && (
                    <div className="save_continue_btn">
                        <button onClick={submitData}>{t("_save_and_continue")}</button>
                        <button onClick={() => cancelOption()}>{t("_cancel")}</button>{" "}
                    </div>
                )} */}
      </>

      <div className="checkbox_product_title">
        <input
          className="form-check-input"
          type="checkbox"
          name="is_discount"
          checked={formData.is_discount === "yes" ? true : false}
          onChange={handleChanges}
        />
        <span>{t("_Is_Discount_available")}</span>
      </div>
      {formData.is_discount == "yes" && <EditDiscount />}

      {/* <div className='pricebelow_allbutnsection'>
                <button
                    className="remove-allcancel mr-4"
                    onClick={() => {
                        setSlidePages(2);
                        scrollToDiv()
                    }}
                >
                    Back
                </button>

                <div className='slide_circles'>
                    <div className='circle_one'></div>
                    <div className='circle_one'></div>
                    <div className='circle_one darkcircle'></div>
                </div>
                <button
                    onClick={submitData}
                    className="btnall-savenext " >Submit
                </button>
            </div> */}
    </div>
  );
};
export default IndividualAddPricing;
