import { useEffect, useState, useRef } from "react";
import { HeaderSticky, LoadingBar } from "../components";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CountdownTimer, MetaData, images } from "../actions/customFn";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  getLastCartProduct,
} from "../actions/orderDetailAction";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/fontawesome-free-regular";
import {  faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import { reservationTimeEndsAPI } from "../actions/cartAction";
import TravelAlsoBoughtCard from "../components/LastCartProduct/TravelAlsoBoughtCard";

const LastCartProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [cartDetails, setCartDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const homeData = useSelector((state) => state.home);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (languageReducer !== "") {
      dispatch(
        getLastCartProduct(
          languageReducer,
          navigate,
          setIsLoading,
          setCartDetails
        )
      );
    }
  }, [languageReducer]);

  let metaData = {
    title: "Cart Details",
  };

  function CalculateTotal(val) {
    var subTotal = 0;
    var totalTax = 0;
    var couponAmount = 0;
    // totalAmount
    // total_tax
    // coupon_amount

    if (val.totalAmount !== undefined && val.totalAmount > 0) {
      subTotal = val.totalAmount;
    }

    if (val.total_tax !== undefined && val.total_tax > 0) {
      totalTax = val.total_tax;
    }
    if (val.coupon_amount !== undefined && val.coupon_amount > 0) {
      couponAmount = val.coupon_amount;
    }

    var getTotal = (subTotal + totalTax).toFixed(2);
    if (getTotal <= 0) {
      getTotal = 1;
    }

    return homeData?.currency + " " + getTotal;
  }


  const reservationTimeEnds = (cart_id) => {
    dispatch(
      reservationTimeEndsAPI(cart_id, languageReducer, navigate, setToggle)
    );
    navigate('/cart');
  };

  const experience = {
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  const containerRef = useRef(null);
  const [leftSpace, setLeftSpace] = useState(0);
   // Function to get and set the left padding and margin of the container
   const updateLeftSpace = () => {
    if (containerRef.current) {
      const computedStyle = getComputedStyle(containerRef.current);
      const leftPadding = parseFloat(computedStyle.paddingLeft);
      const leftMargin = parseFloat(computedStyle.marginLeft);
      setLeftSpace(leftPadding + leftMargin);
    }
  };
 
  useEffect(() => {
    updateLeftSpace();

    window.addEventListener('resize', updateLeftSpace);
    return () => {
      window.removeEventListener('resize', updateLeftSpace);
    };
  }, []);
  
  // console.log("cartDetails=",cartDetails?.detail
  // )

  return (
    <>
      {metaData && <MetaData data={metaData} />}
      {isLoading === true ? (
        <div className="blinking-bar-loader-order-detail ">
          <LoadingBar />
        </div>
      ) : (
        Object.keys(cartDetails).length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >
            <HeaderSticky />
            {/* <div className="container"  ref={containerRef}  >

</div> */}
            <div className="upcoming_detail">
            <div className="container"  ref={containerRef}  >

</div>
              {/* <div className="container mt-4"> */}
              <div className="">
                <div className="booking_table_info">
                  <div className="">
                    {cartDetails?.detail.map(function (value, index) {
                      return (
                        <> 
                        <div className=" order_details_table_order cart_order_sectionmaindiv">
                          <div className="thanku_card1 thankyou-new" style={{
          paddingLeft: `${leftSpace}px`, 
        }}>
                            <div className="top_section">
                              <div className="last_cardmaincardflex">
                                <div className="fist_imagemaindiv">
                                  <NavLink
                                    to={`/culture-details/${value.slug}`}
                                    target="_blank"
                                  >
                                    <div className="cart_leftimagemain">  
                                    <div
                                      className="cartone_img"
                                      style={{
                                        backgroundImage: `url(${value.image})`,
                                      }}
                                    ></div>
                                    </div>
                                  </NavLink>
                                </div>
                                <div className="second_detail_mainmiddiv">
                                  <div className="cartname_leftpart">
                                    <div className="row">
                                      <div className="col-lg-8 col-md-8 col-sm-12">
                                        {/* <h6>
                                                {orderDetails.booking_refrence}{" "}
                                                <span className="booking-pin-top">
                                                  ({orderDetails.booking_pin})
                                                </span>
                                              </h6> */}
                                        <NavLink
                                          to={`/culture-details/${value.slug}`}
                                          target="_blank"
                                        >
                                          <h1>{value.title}</h1>
                                        </NavLink>

                                        <p className="locat">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} />{" "} {value.location}
                                        </p> 
                                      </div>
                                    </div>
                                    {}
                                    <p>
                                      <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                                      {new Date(
                                        value.date
                                      ).toDateString()}
                                      {value.time_slot
                                        ? " at " + value.time_slot
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                                <div className="third_lastcardmaindiv">
                                  <div className="cartyour_bookdetail your-booking">
                                    {/* <h4> {t("_Your_Booking")}</h4> */}
                                    <div className="">
                                      <div className="">
                                        <div className="right_bookingsectioncard">  
                                        <div className=" booking_topics">
                                          <p className="your-booking-heading booking_simpltetimer">
                                          <CountdownTimer
                                targetTime={value.reservation_end_time}
                                onTimerEnd={() => reservationTimeEnds(value.id)}
                              />
                                          </p>
                                        </div>

                                        <div className="flex_cardcheckbtn">
                                          <NavLink to="/cart" className="cart1_btn" >Go To Cart</NavLink>
                                          <NavLink to="/checkout" className="check1_btn" >Check out</NavLink>
                                        </div>

                                        </div>
                                        {/* <div className="col-8 ">
                                          <p className="your-booking-value">
                                            {CalculateTotal(value)}
                                          </p>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <TravelAlsoBoughtCard related_products={value.related_products} />
                        </>
                      );
                    })}
                  </div>
                  
                </div>
              </div>



            </div>
          </motion.div>
        )
      )}
    </>
  );
};

export default LastCartProduct;
