import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ProductBuilderContext } from "../../context";
import { startSpinLoader } from "../../actions/customFn";
import { uplodProductNew } from "../../actions/productBuilderAction";

const TicketBuilder = (props) => {
  const { t } = useTranslation();
  const { tour_id, option_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    setTourId,
    getData,
    productType,
    setSpinLoader,
    productInfo,
    saveAndExit,
    exitRoute,
    languageIdSelected
  } = useContext(ProductBuilderContext);

  const [ticketBuilderData, setData] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [formData, setFormData] = useState({
    tourId: tour_id,
    product_type: productType,
    step: "fifteen",
    language: productInfo.productLanguageId,
    ticket_type: "MOBILE_OR_PAPER",
    // tickets_per_booking: ""
  });

  const handleChange = (e, key = "") => {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  useEffect(() => {
    if (languageReducer != "") {
      getData("fifteen", setData);
    }
  }, [languageReducer,languageIdSelected]);

  useEffect(() => {
    if (languageReducer && ticketBuilderData) {
      setFormData(() => ({
        ...ticketBuilderData.product_data,
        tourId: tour_id,
        step: "fifteen",
        product_type: ticketBuilderData.ProductType,
        language: productInfo.productLanguageId,
      }));
    }
  }, [ticketBuilderData]);

  const submit = (exit) => {
    let nextRoute = exit || "ticket-redemption";
    if (languageReducer !== "") {
      startSpinLoader("cover-spin");
      dispatch(
        uplodProductNew(
          languageReducer,
          setTourId,
          navigate,
          formData,
          nextRoute,
          "",
          "",
          "",
          "",
          "",
          "",
          setSpinLoader
        )
      );
    }
  };

  useEffect(() => {
    if (isFirstRender == false) {
      submit(exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  return (
    <>
      <div className="meet-pickup-main">
        <div className="traveller_reqiredpage activity_productdetail">
          <h1>{t("_Let's_set_up_your_tickets")}</h1>
          <p>
            {t(
              "_We_issue_tickets_for_every_booking_so_travelers_have_easy_access_to_important_information_on_the_day_of_travel."
            )}
          </p>

          <h6>{t("_Select_a_ticket_type")}</h6>
          <div className="product_selectorradio ">
            <input
              type="radio"
              className="productradio cursor-pointer"
              name="ticket_type"
              value="MOBILE_OR_PAPER"
              checked={formData.ticket_type == "MOBILE_OR_PAPER" ? true : false}
              onChange={handleChange}
            />
            <span>
              {t("_Mobile_or_paper_ticket_accepted")}
              <div className="recommended">{t("_RECOMMENDED")}</div>
            </span>
          </div>

          <div className="product_selectorradio ">
            <input
              type="radio"
              className="productradio cursor-pointer"
              name="ticket_type"
              value="PAPER_ONLY"
              checked={formData.ticket_type == "PAPER_ONLY" ? true : false}
              onChange={handleChange}
            />
            <span>{t("_Paper_ticket_only_accepted")}</span>
          </div>

          {/* <h6>How many tickets do you want us to create per booking?</h6>
          <div className="product_selectorradio ">
            <input
              type="radio"
              className="productradio"
              name="tickets_per_booking"
              value='ONE_PER_BOOKING'
              checked={formData.tickets_per_booking == "ONE_PER_BOOKING" ? true : false}
              onChange={handleChange}
            />
            <span>
              One per booking  <div className='recommended'>RECOMMENDED</div>
            </span>
          </div>

          <div className="product_selectorradio ">
            <input
              type="radio"
              className="productradio"
              name="tickets_per_booking"
              value='ONE_PER_TRAVELER'
              checked={formData.tickets_per_booking == "ONE_PER_TRAVELER" ? true : false}
              onChange={handleChange}
            />
            <span>
              One per traveler
            </span>
          </div> */}

          <div className="btn-last-save">
            <button className="btn-all-save" onClick={() => submit()}>
              {t("_Save_&_continue")}
            </button>
            {/* <button
              onClick={() => navigate(`/product-bulider/ticket-redemption/${tour_id}`)}
              className="btn-all-save"
            >
              Save & continue
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketBuilder;
