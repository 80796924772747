import { useContext, useEffect, useRef } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ProductInformation } from "../../context";
import ReactStars from "react-rating-stars-component";
import { useState } from "react";
import { t } from "i18next";
import { useLocation, useParams } from "react-router-dom";
import { handleScroll } from "../../actions/customFn";
// import useHistory from 'use-history'
import { useHistory } from "react-router-use-history";
import FsLightbox from "fslightbox-react";
import { useSelector } from "react-redux";
import { getReviewList } from "../../actions/detailsAction";
import ReactPaginate from "react-paginate";
import { ShimmerText } from "react-shimmer-effects";
import ReviewImageModal from "../Modal/ReviewImageModal";

const CustomerReviews = () => {
  const { DetailsData } = useContext(ProductInformation);
  const { id } = useParams();
  let location = useLocation();
  const history = useHistory();
  const [limit, setLimit] = useState(2);
  const [seeMore, setSeeMore] = useState(false);
  const [seefullDescription, setSeeFullDescription] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [reviewData, setReviewData] = useState([]);
  const [reviewCount, setReviewCount] = useState([]);
  const languageReducer = useSelector((state) => state.languageReducer);

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  useEffect(() => {
    if (location.state) {
      handleScroll("review-div", -280);
      location = { ...location, state: null };
    }
  }, []);

  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(0);
  let images = DetailsData.traveller_photos;
  const [isLoading, setIsLoading] = useState(false);

  // get Review APi
  useEffect(() => {
    if (languageReducer !== "") {
      setIsLoading(true);
      console.log(" reviewData?.count", reviewData?.count)
      getReviewList(
        id,
        languageReducer,
        setReviewData,
        setPageCount,
        currentPage,
        setIsLoading,
        reviewCount,setReviewCount
      );
    }
  }, [languageReducer, currentPage]);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
    executeScroll();
  };

  // lightBox
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);

  const showImage = (image) => {
    //set imageToShow to be the one that's been clicked on
    setImageToShow(image);
    //set lightbox visibility to true
    setLightBoxDisplay(true);
  };

  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  let length = DetailsData.traveller_photos.length;

  const closeReviewModal = () => {
    setToggler(false);
  };

  return (
    <>
      {DetailsData && (
        <>
          <div className="customer-reviews-main" id="review-div" data-section>
            <h2>{t("_customer_reviews")}</h2>
            <div className="customer-top-info">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                  <div className="customer-rateing_main">
                    <h2 className="title_rateing">{t("_overall_rating")}</h2>
                    <span>
                      {DetailsData.ratings} / {t("_5.0")}
                    </span>
                    <div className="star_list">
                      <ReactStars
                        style={{ cursor: "pointer !important" }}
                        classNames="star-class"
                        size={16}
                        value={Number(DetailsData.ratings)}
                        {...reactRatings}
                      />
                      <p>
                        {t("_based_on")} {DetailsData.total_review}{" "}
                        {t("_reviews")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                  <h2 className="title_rateing">{t("_review_summary")}</h2>
                  <div className="row" ref={myRef}>
                    {DetailsData.ratings_count.length > 0 &&
                      DetailsData.ratings_count.map((value, index) => {
                        return (
                          <div
                            className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                            key={index}
                          >
                            <div className="progress_bar_rateing">
                              <div className="star_list">
                                <ReactStars
                                  style={{ cursor: "pointer !important" }}
                                  size={16}
                                  value={Number(value.ratings_round)}
                                  {...reactRatings}
                                />
                                <span>
                                  {value.ratings_round} {t("_star")}
                                </span>

                                {/* <ul>
                      <span>{value.ratings_round} Star</span>
                    </ul> */}
                                <p>{`${Math.round(
                                  value.rating_calcualtion
                                )}%`}</p>
                              </div>
                              <div className="progress_div_inner">
                                <ProgressBar now={value.rating_calcualtion} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            {isLoading == false ? (
              reviewData.length > 0 &&
              reviewData.map((value, index) => {
                return (
                  <div className="client_reviwes" key={index}>
                    <div className="client_info">
                      <div className="user_info">
                        <img src={value.image} alt="" />
                        <div className="user_details">
                          <span>{value.name}</span>
                          <p>{value.country}</p>
                        </div>
                      </div>
                      <div className="star_list">
                        <ReactStars
                          style={{ cursor: "pointer !important" }}
                          size={16}
                          value={Number(value.rating)}
                          {...reactRatings}
                        />
                      </div>
                    </div>

                    {value.time_ago && (
                      <div className="reviwe_hour_time">
                        <p>{value.time_ago}</p>
                      </div>
                    )}
                    <div className="mt-2 show_inner_review_image">
                      <h6 className="review-title">{value.title}</h6>
                      <p className="review-description">
                        {seefullDescription == index ? (
                          <>
                            <span>{value.description}</span>

                            <button
                              onClick={() => {
                                setSeeFullDescription(null);
                              }}
                            >
                              {t("_see_less")}
                            </button>
                          </>
                        ) : (
                          <>
                            <span>{value.description.slice(0, 400)}</span>
                            {value.description.length > 400 && (
                              <>
                                <span>...</span>
                                <button
                                  onClick={() => {
                                    setSeeFullDescription(index);
                                  }}
                                >
                                  {t("_see_more")}
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </p>

                      {value.review_image.length > 0 &&
                        value.review_image.map((reviewImg, key) => {
                          return (
                            <>
                              <img
                                className="travelPic1 review_img"
                                src={reviewImg.image}
                                alt={key}
                                onClick={() => {
                                  setToggler(!toggler);
                                  setProductIndex(reviewImg?.key);
                                }}
                                // onClick={() => showImage(reviewImg.image)}
                                // onClick={(e) => {
                                //   setToggler(!toggler);
                                //   setProductIndex(
                                //     parseInt(e.target.getAttribute("alt"))
                                //   );
                                // }}
                              ></img>
                              
                            </>
                          );
                        })}
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
                <ShimmerText line={5} gap={10} />
              </>
            )}
            <div className="paginate_all">
              {pageCount > 1 && reviewData.length > 0 && (
                <ReactPaginate
                  pageCount={pageCount}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"active"}
                  pageRange={2}
                  marginPagesDisplayed={2}
                  nextLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                        transform="translate(7.172) rotate(90)"
                        fill="#000"
                      />
                    </svg>
                  }
                  previousLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                        transform="translate(15.086 -5) rotate(90)"
                        fill="#232a35"
                      />
                    </svg>
                  }
                />
              )}
            </div>
          </div>
          {/* <div className="fslight_box_main">
            <FsLightbox
              toggler={toggler}
              sources={images}
              sourceIndex={productIndex}
            />
          </div> */}

          <ReviewImageModal
            onCloseModal={closeReviewModal}
            showModal={toggler}
            productIndex={productIndex}
            activeKey="traveller"
            // reviewData={reviewData}
          />
        </>
      )}
    </>
  );
};

export default CustomerReviews;
