import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import LoadingBar from "../Loading/LoadingBar";
import RecordNotFound from "../../pages/RecordNotFound";
import moment from "moment";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { getHotelUserCommissionList } from "../../actions/hotelUserAction";
import { useTranslation } from "react-i18next";
import { MetaData, getUrlParams, handleScroll } from "../../actions/customFn";
import { useHistory } from "react-router-use-history";
import { useUrlFilter } from "react-filter-by-url";

const CommissionHistory = () => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);
  const handleShowDate = () => setIsShow((wasOpened) => !wasOpened);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();                               // keep it let as it is changing later
  const locationParams = getUrlParams(location.search);
  const history = useHistory();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [commiossionHistory, setCommiossionHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recordStatus, setRecordStatus] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const profileReducer = useSelector((state) => state.profileReducer);
  const [toggle, setToggle] = useState(false);
  const [filterData, setFilterData] = useState({
    order_id: "",
    from_date: "",
    last_month: "",
    search_by: ""
  });
  const [isFilter, setIsFilter] = useState(0);
  const [lastMonth, setLastMonth] = useState("");

  const params = ["order_id", "currentPage", "last_month", "from_date", "to_date", "search_by", "isShow"];
  const { apiQuery, getDefaultParamValue, handleSelectFilter } = useUrlFilter(params);

  useEffect(() => {
    setFilterData(locationParams);
    if (locationParams.currentPage) {
      setCurrentPage(Number(locationParams.currentPage))
    } else {
      setCurrentPage(0)
    }
    setIsShow(true);
    if (locationParams.last_month) {
      setLastMonth(moment(locationParams.last_month).format('ddd MMMM DD YYYY 00:00:00 [GMT]ZZ') + ` (India Standard Time)`)
    }
  }, []);


  useEffect(() => {
    if (languageReducer !== "" && profileReducer) {
      setIsLoading(true);
      let hotel_slug = profileReducer.slug
      dispatch(
        getHotelUserCommissionList(
          languageReducer,
          setCommiossionHistory,
          navigate,
          setIsLoading,
          setRecordStatus,
          setPageCount,
          currentPage,
          filterData,
          isFilter,
          hotel_slug,
        )
      );
    }
  }, [languageReducer, currentPage, profileReducer, toggle]);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
    handleSelectFilter("currentPage", selectedObject.selected);
    handleScroll("scroll", 150)
  };

  // const statusOption = [
  //   { label: "Pending", value: "Pending" },
  //   { label: "Failed", value: "Failed" },
  //   { label: "Success", value: "Success" },
  //   { label: "Cancelled", value: "Cancelled" },
  // ];

  // const handleFilter = (e, item, key) => {
  //   if (item) {
  //     setFilterData((prev) => ({ ...prev, [key]: item.value }));
  //   }
  // };

  const timeOption = [
    { label: t("_date"), value: "Date" },
    { label: t("_month"), value: "Month" },
  ];

  // const handleStatus = (e, item, key) => {
  //   setCurrentPage(0);
  //   if (item) {
  //     setFilterData((prev) => ({ ...prev, [key]: item.value }));
  //   } else {
  //     setFilterData((prev) => ({ ...prev, [key]: "" }));
  //   }
  // };

  const handleSearchBy = (e, item, key) => {
    setCurrentPage(0);
    if (item) {
      if (item.value == "Date") {
        setFilterData((prev) => ({ ...prev, [key]: item.value, "last_month": "" }));
        setLastMonth("");
        handleSelectFilter(key, item.value)
        // handleSelectFilter("last_month", "")
      }
      if (item.value == "Month") {
        setFilterData((prev) => ({ ...prev, [key]: item.value, "from_date": "", "to_date": "" }));
        handleSelectFilter(key, item.value)
        // handleSelectFilter("from_date", "")
        // handleSelectFilter("to_date", "")
      }

    } else {
      setFilterData((prev) => ({ ...prev, [key]: "", "last_month": "", "from_date": "", "to_date": "" }));
      setLastMonth("");
      // handleSelectFilter("from_date", "")
      // handleSelectFilter("to_date", "")
      // handleSelectFilter("last_month", "")
    }
  };

  const handleDate = (date, name) => {
    if (name == "last_month") {
      setLastMonth(date);             // date saved in original formate
    };
    date = moment(date).format("yyyy-MM-DD");
    setCurrentPage(0);
    setFilterData((prev) => ({ ...prev, [name]: date }));
    handleSelectFilter(name, date)
  };

  const handleChange = (e) => {
    setCurrentPage(0);
    const { name, value } = e.target;
    setFilterData((prev) => ({ ...prev, [name]: value }));
    handleSelectFilter(name, value)
  };

  // console.log("filterData",filterData);

  useEffect(() => {
    if (
      filterData.order_id !== "" ||
      filterData.from_date !== "" ||
      filterData.to_date !== "" ||
      filterData.last_month !== ""
    ) {
      setIsFilter(1);
    } else {
      setIsFilter(0);
    }
  }, [filterData]);

  const resetFilter = () => {
    setFilterData({
      order_id: "",
      from_date: "",
      to_date: "",
      last_month: "",
      search_by: ""
    });
    setLastMonth("");
    setIsFilter(0);
    setCurrentPage(0);
    history.push({
      pathname: window.location.pathname,
      search: '',
    });
  };

  const currentUrl = window.location.href;


  let metaData = {
    title: t("_commission_history")
  }

  return (
    <div className="userbooking-history">
      {
        metaData &&
        <MetaData data={metaData} />
      }
      <div className="provider-booking-main">
        <div className="provider_upcomimg_main">
          <div className="provider_my_tour">
            <div className="text_banner">
              <div className="text_banner">
                <span>{t("_commission_history")}</span>
              </div>
              <div className="banner_button_main">
                <button className="filter_btn" onClick={handleShowDate}>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g id="filter">
                        <path
                          id="Vector"
                          d="M21 5.46258C20.9998 5.08433 20.853 4.72089 20.5904 4.44865C20.3278 4.17641 19.9699 4.01656 19.5919 4.0027V4H4.53993V4.00405C4.51383 4.0027 4.48795 4 4.46162 4C4.15915 4.0002 3.86417 4.09417 3.61732 4.26898C3.37047 4.44379 3.18389 4.69083 3.08328 4.97608C2.98267 5.26134 2.97297 5.57077 3.05553 5.86176C3.13808 6.15275 3.30883 6.41099 3.54425 6.60091L10.3261 13.3825L10.3259 17.5675V17.6022H10.3293C10.3349 17.7312 10.3733 17.8567 10.4407 17.9669C10.5081 18.0771 10.6024 18.1684 10.7147 18.2322L10.7127 18.2358L12.484 19.2585C12.4988 19.2689 12.5153 19.2763 12.5308 19.2855L12.5371 19.2891C12.65 19.3544 12.7796 19.3944 12.9196 19.3944C13.0205 19.3944 13.1205 19.3746 13.2138 19.336C13.307 19.2973 13.3918 19.2407 13.4632 19.1694C13.5346 19.098 13.5912 19.0132 13.6298 18.92C13.6684 18.8267 13.6883 18.7267 13.6882 18.6258C13.6882 18.6055 13.6837 18.5864 13.6824 18.5666H13.6882V13.3888L20.5324 6.54488L20.5257 6.53813C20.6751 6.40166 20.7945 6.23553 20.8762 6.05034C20.9578 5.86515 21 5.66497 21 5.46258ZM8.00534 6.92515H8.00849L8.00871 6.92853L8.00534 6.92515Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            {isShow && (
              <div className="wishlist_filter Wishlist_main_componemt Upcoming_booking_main ">
                <div className="row ">
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="customer-name filter_drop_down">
                      <p>{t("_order_id")} </p>
                      <input
                        type="text"
                        name="order_id"
                        value={filterData.order_id}
                        placeholder={t("_enter_order_id")}
                        className="form-control"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div className="customer-name">
                  <p>Customer Name{" "}</p>
                  <input
                    type="text"
                    name="customer_name"
                    value={filterData.customer_name}
                    placeholder="Enter First Name"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div> */}
                  {/* <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="filter_drop_down">
                      <p>Status</p>
                      <Autocomplete
                        className="new_filter_select"
                        disablePortal
                        options={statusOption}
                        value={filterData.status}
                        name="status"
                        renderInput={(params) => (
                          <TextField {...params} label="Select status" />
                        )}
                        onChange={(e, value) =>
                          handleFilter(e, value, "status")
                        }
                      />
                    </div>
                  </div> */}

                  <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="filter_drop_down">
                      <p>{t("_searach_by")}</p>
                      <Autocomplete
                        className="new_filter_select"
                        disablePortal
                        options={timeOption}
                        // value={filterData.search_by}
                        value={filterData.search_by
                          ? timeOption.find((item) => item.value == filterData.search_by)
                          : ""}
                        renderInput={(params) => (
                          <TextField {...params} label={t("_searach_by")} />
                        )}
                        onChange={(e, value) => handleSearchBy(e, value, "search_by")}
                      />
                    </div>
                  </div>
                  {
                    filterData.search_by == "Date" &&
                    <>
                      <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12 mt-3">
                        <div className="filter_drop_down input_location">
                          <p>{t("_from_date")}</p>
                          <button className="btn_inner_upcoming">
                            <div className="adult_check">
                              <span className="text_availabillity">
                                <Flatpickr
                                  //   data-enable-time
                                  id="check_in"
                                  className="date_picker"
                                  value={filterData.from_date}
                                  placeholder={t("_select_from_date")}
                                  name="from_date"
                                  options={{
                                    // minDate: "today",
                                    dateFormat: "Y-m-d",
                                    // maxDate: "23:59"
                                  }}
                                  onChange={(value) =>
                                    handleDate(value[0], "from_date")
                                  }
                                />
                              </span>
                            </div>
                            <div className="icon_drop_down">
                              <span className="icon_availabillity">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_518_177)">
                                    <path
                                      d="M15.375 10.3125C15.375 10.1633 15.4343 10.0202 15.5398 9.91475C15.6452 9.80926 15.7883 9.75 15.9375 9.75H17.0625C17.2117 9.75 17.3548 9.80926 17.4602 9.91475C17.5657 10.0202 17.625 10.1633 17.625 10.3125V11.4375C17.625 11.5867 17.5657 11.7298 17.4602 11.8352C17.3548 11.9407 17.2117 12 17.0625 12H15.9375C15.7883 12 15.6452 11.9407 15.5398 11.8352C15.4343 11.7298 15.375 11.5867 15.375 11.4375V10.3125ZM12 10.3125C12 10.1633 12.0593 10.0202 12.1648 9.91475C12.2702 9.80926 12.4133 9.75 12.5625 9.75H13.6875C13.8367 9.75 13.9798 9.80926 14.0852 9.91475C14.1907 10.0202 14.25 10.1633 14.25 10.3125V11.4375C14.25 11.5867 14.1907 11.7298 14.0852 11.8352C13.9798 11.9407 13.8367 12 13.6875 12H12.5625C12.4133 12 12.2702 11.9407 12.1648 11.8352C12.0593 11.7298 12 11.5867 12 11.4375V10.3125ZM6.375 13.6875C6.375 13.5383 6.43426 13.3952 6.53975 13.2898C6.64524 13.1843 6.78832 13.125 6.9375 13.125H8.0625C8.21168 13.125 8.35476 13.1843 8.46025 13.2898C8.56574 13.3952 8.625 13.5383 8.625 13.6875V14.8125C8.625 14.9617 8.56574 15.1048 8.46025 15.2102C8.35476 15.3157 8.21168 15.375 8.0625 15.375H6.9375C6.78832 15.375 6.64524 15.3157 6.53975 15.2102C6.43426 15.1048 6.375 14.9617 6.375 14.8125V13.6875ZM9.75 13.6875C9.75 13.5383 9.80926 13.3952 9.91475 13.2898C10.0202 13.1843 10.1633 13.125 10.3125 13.125H11.4375C11.5867 13.125 11.7298 13.1843 11.8352 13.2898C11.9407 13.3952 12 13.5383 12 13.6875V14.8125C12 14.9617 11.9407 15.1048 11.8352 15.2102C11.7298 15.3157 11.5867 15.375 11.4375 15.375H10.3125C10.1633 15.375 10.0202 15.3157 9.91475 15.2102C9.80926 15.1048 9.75 14.9617 9.75 14.8125V13.6875Z"
                                      fill="#232A35"
                                    />
                                    <path
                                      d="M6.9375 3C7.08668 3 7.22976 3.05926 7.33525 3.16475C7.44074 3.27024 7.5 3.41332 7.5 3.5625V4.125H16.5V3.5625C16.5 3.41332 16.5593 3.27024 16.6648 3.16475C16.7702 3.05926 16.9133 3 17.0625 3C17.2117 3 17.3548 3.05926 17.4602 3.16475C17.5657 3.27024 17.625 3.41332 17.625 3.5625V4.125H18.75C19.3467 4.125 19.919 4.36205 20.341 4.78401C20.7629 5.20597 21 5.77826 21 6.375V18.75C21 19.3467 20.7629 19.919 20.341 20.341C19.919 20.7629 19.3467 21 18.75 21H5.25C4.65326 21 4.08097 20.7629 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V6.375C3 5.77826 3.23705 5.20597 3.65901 4.78401C4.08097 4.36205 4.65326 4.125 5.25 4.125H6.375V3.5625C6.375 3.41332 6.43426 3.27024 6.53975 3.16475C6.64524 3.05926 6.78832 3 6.9375 3ZM4.125 7.5V18.75C4.125 19.0484 4.24353 19.3345 4.4545 19.5455C4.66548 19.7565 4.95163 19.875 5.25 19.875H18.75C19.0484 19.875 19.3345 19.7565 19.5455 19.5455C19.7565 19.3345 19.875 19.0484 19.875 18.75V7.5H4.125Z"
                                      fill="#232A35"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_518_177">
                                      <rect
                                        width="18"
                                        height="18"
                                        fill="white"
                                        transform="translate(3 3)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12 mt-3">
                        <div className="filter_drop_down input_location">
                          <p>{t("_to_date")}</p>
                          <button className="btn_inner_upcoming">
                            <div className="adult_check">
                              <span className="text_availabillity">
                                <Flatpickr
                                  //   data-enable-time
                                  className="date_picker"
                                  value={filterData.to_date}
                                  placeholder={t("_select_to_date")}
                                  name="to_date"
                                  options={{
                                    minDate: filterData.from_date,
                                    dateFormat: "Y-m-d",
                                  }}
                                  onChange={(value) =>
                                    handleDate(value[0], "to_date")
                                  }
                                />
                              </span>
                            </div>
                            <div className="icon_drop_down">
                              <span className="icon_availabillity">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_518_177)">
                                    <path
                                      d="M15.375 10.3125C15.375 10.1633 15.4343 10.0202 15.5398 9.91475C15.6452 9.80926 15.7883 9.75 15.9375 9.75H17.0625C17.2117 9.75 17.3548 9.80926 17.4602 9.91475C17.5657 10.0202 17.625 10.1633 17.625 10.3125V11.4375C17.625 11.5867 17.5657 11.7298 17.4602 11.8352C17.3548 11.9407 17.2117 12 17.0625 12H15.9375C15.7883 12 15.6452 11.9407 15.5398 11.8352C15.4343 11.7298 15.375 11.5867 15.375 11.4375V10.3125ZM12 10.3125C12 10.1633 12.0593 10.0202 12.1648 9.91475C12.2702 9.80926 12.4133 9.75 12.5625 9.75H13.6875C13.8367 9.75 13.9798 9.80926 14.0852 9.91475C14.1907 10.0202 14.25 10.1633 14.25 10.3125V11.4375C14.25 11.5867 14.1907 11.7298 14.0852 11.8352C13.9798 11.9407 13.8367 12 13.6875 12H12.5625C12.4133 12 12.2702 11.9407 12.1648 11.8352C12.0593 11.7298 12 11.5867 12 11.4375V10.3125ZM6.375 13.6875C6.375 13.5383 6.43426 13.3952 6.53975 13.2898C6.64524 13.1843 6.78832 13.125 6.9375 13.125H8.0625C8.21168 13.125 8.35476 13.1843 8.46025 13.2898C8.56574 13.3952 8.625 13.5383 8.625 13.6875V14.8125C8.625 14.9617 8.56574 15.1048 8.46025 15.2102C8.35476 15.3157 8.21168 15.375 8.0625 15.375H6.9375C6.78832 15.375 6.64524 15.3157 6.53975 15.2102C6.43426 15.1048 6.375 14.9617 6.375 14.8125V13.6875ZM9.75 13.6875C9.75 13.5383 9.80926 13.3952 9.91475 13.2898C10.0202 13.1843 10.1633 13.125 10.3125 13.125H11.4375C11.5867 13.125 11.7298 13.1843 11.8352 13.2898C11.9407 13.3952 12 13.5383 12 13.6875V14.8125C12 14.9617 11.9407 15.1048 11.8352 15.2102C11.7298 15.3157 11.5867 15.375 11.4375 15.375H10.3125C10.1633 15.375 10.0202 15.3157 9.91475 15.2102C9.80926 15.1048 9.75 14.9617 9.75 14.8125V13.6875Z"
                                      fill="#232A35"
                                    />
                                    <path
                                      d="M6.9375 3C7.08668 3 7.22976 3.05926 7.33525 3.16475C7.44074 3.27024 7.5 3.41332 7.5 3.5625V4.125H16.5V3.5625C16.5 3.41332 16.5593 3.27024 16.6648 3.16475C16.7702 3.05926 16.9133 3 17.0625 3C17.2117 3 17.3548 3.05926 17.4602 3.16475C17.5657 3.27024 17.625 3.41332 17.625 3.5625V4.125H18.75C19.3467 4.125 19.919 4.36205 20.341 4.78401C20.7629 5.20597 21 5.77826 21 6.375V18.75C21 19.3467 20.7629 19.919 20.341 20.341C19.919 20.7629 19.3467 21 18.75 21H5.25C4.65326 21 4.08097 20.7629 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V6.375C3 5.77826 3.23705 5.20597 3.65901 4.78401C4.08097 4.36205 4.65326 4.125 5.25 4.125H6.375V3.5625C6.375 3.41332 6.43426 3.27024 6.53975 3.16475C6.64524 3.05926 6.78832 3 6.9375 3ZM4.125 7.5V18.75C4.125 19.0484 4.24353 19.3345 4.4545 19.5455C4.66548 19.7565 4.95163 19.875 5.25 19.875H18.75C19.0484 19.875 19.3345 19.7565 19.5455 19.5455C19.7565 19.3345 19.875 19.0484 19.875 18.75V7.5H4.125Z"
                                      fill="#232A35"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_518_177">
                                      <rect
                                        width="18"
                                        height="18"
                                        fill="white"
                                        transform="translate(3 3)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </>
                  }
                  {
                    filterData.search_by == "Month" &&
                    <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12 mt-3">
                      <div className="filter_drop_down input_location">
                        <p>{t("_select_month_and_year")}</p>
                        <button className="btn_inner_upcoming">
                          <div className="adult_check">
                            <span className="text_availabillity">
                              <Flatpickr
                                data-enable-time={false}
                                className="date_picker"
                                value={lastMonth}
                                placeholder={t("_select_month_and_year")}
                                name="last_month"
                                options={{
                                  // maxDate: "today",
                                  dateFormat: "F Y",
                                  plugins: [
                                    new monthSelectPlugin({
                                      shorthand: true,
                                      dateFormat: "F Y",
                                      altInput: true,
                                      theme: "light",
                                    })
                                  ],
                                }}
                                onChange={(value) =>
                                  handleDate(value[0], "last_month")
                                }
                              />
                            </span>
                          </div>
                          <div className="icon_drop_down">
                            <span className="icon_availabillity">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_518_177)">
                                  <path
                                    d="M15.375 10.3125C15.375 10.1633 15.4343 10.0202 15.5398 9.91475C15.6452 9.80926 15.7883 9.75 15.9375 9.75H17.0625C17.2117 9.75 17.3548 9.80926 17.4602 9.91475C17.5657 10.0202 17.625 10.1633 17.625 10.3125V11.4375C17.625 11.5867 17.5657 11.7298 17.4602 11.8352C17.3548 11.9407 17.2117 12 17.0625 12H15.9375C15.7883 12 15.6452 11.9407 15.5398 11.8352C15.4343 11.7298 15.375 11.5867 15.375 11.4375V10.3125ZM12 10.3125C12 10.1633 12.0593 10.0202 12.1648 9.91475C12.2702 9.80926 12.4133 9.75 12.5625 9.75H13.6875C13.8367 9.75 13.9798 9.80926 14.0852 9.91475C14.1907 10.0202 14.25 10.1633 14.25 10.3125V11.4375C14.25 11.5867 14.1907 11.7298 14.0852 11.8352C13.9798 11.9407 13.8367 12 13.6875 12H12.5625C12.4133 12 12.2702 11.9407 12.1648 11.8352C12.0593 11.7298 12 11.5867 12 11.4375V10.3125ZM6.375 13.6875C6.375 13.5383 6.43426 13.3952 6.53975 13.2898C6.64524 13.1843 6.78832 13.125 6.9375 13.125H8.0625C8.21168 13.125 8.35476 13.1843 8.46025 13.2898C8.56574 13.3952 8.625 13.5383 8.625 13.6875V14.8125C8.625 14.9617 8.56574 15.1048 8.46025 15.2102C8.35476 15.3157 8.21168 15.375 8.0625 15.375H6.9375C6.78832 15.375 6.64524 15.3157 6.53975 15.2102C6.43426 15.1048 6.375 14.9617 6.375 14.8125V13.6875ZM9.75 13.6875C9.75 13.5383 9.80926 13.3952 9.91475 13.2898C10.0202 13.1843 10.1633 13.125 10.3125 13.125H11.4375C11.5867 13.125 11.7298 13.1843 11.8352 13.2898C11.9407 13.3952 12 13.5383 12 13.6875V14.8125C12 14.9617 11.9407 15.1048 11.8352 15.2102C11.7298 15.3157 11.5867 15.375 11.4375 15.375H10.3125C10.1633 15.375 10.0202 15.3157 9.91475 15.2102C9.80926 15.1048 9.75 14.9617 9.75 14.8125V13.6875Z"
                                    fill="#232A35"
                                  />
                                  <path
                                    d="M6.9375 3C7.08668 3 7.22976 3.05926 7.33525 3.16475C7.44074 3.27024 7.5 3.41332 7.5 3.5625V4.125H16.5V3.5625C16.5 3.41332 16.5593 3.27024 16.6648 3.16475C16.7702 3.05926 16.9133 3 17.0625 3C17.2117 3 17.3548 3.05926 17.4602 3.16475C17.5657 3.27024 17.625 3.41332 17.625 3.5625V4.125H18.75C19.3467 4.125 19.919 4.36205 20.341 4.78401C20.7629 5.20597 21 5.77826 21 6.375V18.75C21 19.3467 20.7629 19.919 20.341 20.341C19.919 20.7629 19.3467 21 18.75 21H5.25C4.65326 21 4.08097 20.7629 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V6.375C3 5.77826 3.23705 5.20597 3.65901 4.78401C4.08097 4.36205 4.65326 4.125 5.25 4.125H6.375V3.5625C6.375 3.41332 6.43426 3.27024 6.53975 3.16475C6.64524 3.05926 6.78832 3 6.9375 3ZM4.125 7.5V18.75C4.125 19.0484 4.24353 19.3345 4.4545 19.5455C4.66548 19.7565 4.95163 19.875 5.25 19.875H18.75C19.0484 19.875 19.3345 19.7565 19.5455 19.5455C19.7565 19.3345 19.875 19.0484 19.875 18.75V7.5H4.125Z"
                                    fill="#232A35"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_518_177">
                                    <rect
                                      width="18"
                                      height="18"
                                      fill="white"
                                      transform="translate(3 3)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                  }
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-3">
                    <div className="row">
                      <div className="btn_search">
                        <button onClick={() => setToggle(!toggle)}>
                          {t("_search")}
                        </button>{" "}
                      </div>
                      <button
                        className="btn_erase_one"
                        onClick={() => resetFilter()}
                      >
                        {" "}
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <g id="erase">
                              <path
                                id="Vector"
                                d="M3.9375 15.1875H16.875V16.3125H3.9375V15.1875Z"
                                fill="#FF1E1E"
                              />
                              <path
                                id="Vector_2"
                                d="M15.4015 5.9119L10.9409 1.4569C10.8364 1.3523 10.7123 1.26932 10.5757 1.21271C10.4392 1.15609 10.2928 1.12695 10.1449 1.12695C9.99709 1.12695 9.85069 1.15609 9.71412 1.21271C9.57755 1.26932 9.45347 1.3523 9.34899 1.4569L1.47399 9.3319C1.36939 9.43638 1.28641 9.56046 1.2298 9.69703C1.17318 9.8336 1.14404 9.97999 1.14404 10.1278C1.14404 10.2757 1.17318 10.4221 1.2298 10.5586C1.28641 10.6952 1.36939 10.8193 1.47399 10.9238L4.01087 13.5H9.40524L15.4015 7.50378C15.5061 7.39929 15.5891 7.27522 15.6457 7.13865C15.7023 7.00207 15.7314 6.85568 15.7314 6.70784C15.7314 6.56 15.7023 6.4136 15.6457 6.27703C15.5891 6.14046 15.5061 6.01638 15.4015 5.9119ZM8.93837 12.375H4.50024L2.25024 10.125L5.79962 6.57565L10.2602 11.0307L8.93837 12.375ZM11.0534 10.26L6.59837 5.7994L10.1252 2.25003L14.6252 6.71065L11.0534 10.26Z"
                                fill="#FF1E1E"
                              />
                            </g>
                          </svg>
                        </span>{" "}
                        {t("_erase")}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="Upcoming_booking_table table-responsive" id="scroll">
              {isLoading === true ? (
                <div className="blinking-bar-loader">
                  <LoadingBar />
                </div>
              ) : commiossionHistory.length > 0 ? (
                <>
                  <table className="table affilliate_commission">
                    <thead>
                      <tr>
                        <th>{t("_order_id")}</th>
                        <th>{t("_booking_date")}</th>
                        <th>{t("_order_total")}</th>
                        <th>{t("_commission_total")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {commiossionHistory.map(function (value, index) {
                        return (
                          <tr key={index}>
                            <td>{value.order_id}</td>
                            <td>{value.date}</td>
                            <td>
                              {" "}
                              <span className="icon_price_data">
                                {value.product_total}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <span className="icon_price_data">
                                {value.total}
                              </span>
                            </td>
                            {/* <td>
                              <span
                                className={
                                  value.status == "Pending"
                                    ? "status_pending"
                                    : value.status == "Failed"
                                    ? "status_failed"
                                    : value.status == "Cancelled"
                                    ? "status_cancelled"
                                    : value.status == "Success"
                                    ? "status_success"
                                    : "default-status-background"
                                }
                              >
                                {value.status}
                              </span>
                            </td> */}
                            <td>
                              <NavLink to={`/hotel-user-commission-details/${value.id}`}
                                state={{
                                  prevPath: currentUrl,
                                }}
                              >
                                {" "}
                                <span className="bg_color_span">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="14"
                                    viewBox="0 0 8 14"
                                    fill="none"
                                  >
                                    <path
                                      id="Vector"
                                      d="M0.28142 12.3023C0.0989415 12.4912 -0.00203005 12.7443 0.000252377 13.0069C0.0025348 13.2696 0.107888 13.5209 0.293623 13.7066C0.479357 13.8923 0.730611 13.9977 0.993268 14C1.25593 14.0022 1.50897 13.9013 1.69791 13.7188L6.29197 9.12473C6.85537 8.56116 7.17188 7.79689 7.17188 7C7.17188 6.20311 6.85537 5.43884 6.29197 4.87527L1.69791 0.281206C1.50897 0.0987274 1.25593 -0.00224415 0.993268 3.82748e-05C0.730611 0.0023207 0.479357 0.107675 0.293623 0.293409C0.107888 0.479144 0.0025348 0.730397 0.000252377 0.993055C-0.00203005 1.25571 0.0989415 1.50876 0.28142 1.69769L4.87849 6.29176C5.06629 6.47961 5.17179 6.73437 5.17179 7C5.17179 7.26563 5.06629 7.52039 4.87849 7.70824L0.28142 12.3023Z"
                                      fill="#FC5301"
                                    />
                                  </svg>
                                </span>
                              </NavLink>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="paginate_all">
                    {pageCount > 1 && commiossionHistory.length > 0 && (
                      <ReactPaginate
                        pageCount={pageCount}
                        forcePage={currentPage}
                        onPageChange={handlePageChange}
                        containerClassName={"navigationButtons"}
                        previousLinkClassName={"previousButton"}
                        nextLinkClassName={"nextButton"}
                        disabledClassName={"navigationDisabled"}
                        activeClassName={"active"}
                        pageRange={2}
                        marginPagesDisplayed={2}
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.172"
                            height="14"
                            viewBox="0 0 7.172 14"
                          >
                            <path
                              id="fi-rr-angle-small-down"
                              d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                              transform="translate(7.172) rotate(90)"
                              fill="#000"
                            />
                          </svg>
                        }
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.172"
                            height="14"
                            viewBox="0 0 7.172 14"
                          >
                            <path
                              id="fi-rr-angle-small-down"
                              d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                              transform="translate(15.086 -5) rotate(90)"
                              fill="#232a35"
                            />
                          </svg>
                        }
                      />
                    )}
                  </div>
                </>
              ) : (
                recordStatus === false && <RecordNotFound />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionHistory;
