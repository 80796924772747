import React from "react";
import { ShimmerTitle } from "react-shimmer-effects";
import { useTranslation } from "react-i18next";
import { ShimmerButton } from "react-shimmer-effects";
const TextMid = ({ textMidData, isLoading }) => {
  const { t } = useTranslation();
  return isLoading ? (
    <>
      <div className="shimmer-title">
        <ShimmerTitle line={5} gap={20} variant="primary" />
        <ShimmerTitle line={5} gap={20} variant="primary" />
      </div>
      <div className="shimmer-bt">
        <ShimmerButton size="lg" mode="light" center />
      </div>
    </>
  ) : (
    <>
      <div className="new-text-data-mid">
        <div className="container">
          <div className="main-text-data">
            <h2>{textMidData?.who_new_title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: textMidData?.who_new_description,
              }}
            ></p>
          </div>

          <div className="main-text-data">
            <h2>{textMidData?.our_new_vision}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: textMidData?.our_new_vision_description,
              }}
            ></p>
          </div>

          <div className="btn-bottom-data">
            <button> {t("_Start_Making_Memeries")}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TextMid;
