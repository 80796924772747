import React, { useContext } from "react";
import { handleScroll, images } from "../../actions/customFn";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { ProductListingInfo } from "../../context";
import moment from "moment";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useTranslation } from "react-i18next";

const ListingBanner = () => {
  const { t } = useTranslation();
  const {
    bannerTitle, setDate, date, bannerImage, isDataLoad,bannerDescription,
  } = useContext(ProductListingInfo);

  const handleDate = (date) => {
    date = moment(date).format("yyyy-MM-DD");
    setDate(date);
    handleScroll("scroll", 150)
  };

  return (
    <div className="listing-banner-new">
      {
        isDataLoad == true ?
          <ShimmerThumbnail rounded /> :
          <div className="listing__Page-banner">
            <div
              className="info__page_bg_img"
              style={{
                backgroundImage: `url(${bannerImage})`,
              }}
            >
              <div className="container">
                <div className="banner_listing_text">
                  {/* <div className="text_one_banner">
                  <h2>{t("_Thing_to_do_in")}</h2> 
                </div> */}
                  {/* <div
                    className="text_image_loc"
                  >

                  </div> */}
                </div>

                <div className="info_date_button">
                  <div className="info__date_inner">
                    <div className="icon__date">
                      <img src={images["icon_new.png"]} alt="" />
                    </div>
                    <Flatpickr
                      //   data-enable-time
                      className="date_picker"
                      id="date"
                      name="date"
                      value={date}
                      placeholder={t("_select_date")}
                      options={{
                        minDate: "today",
                        dateFormat: "Y-m-d",
                        monthSelectorType: "static",
                      }}
                      onChange={(value) => handleDate(value[0])}
                    />
                    <div className="icon_down_svg">
                      {date == "" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                        >
                          <path
                            d="M8.85916 2.18275C9.09377 1.93983 9.22359 1.61449 9.22066 1.27678C9.21772 0.939081 9.08227 0.616041 8.84347 0.37724C8.60467 0.138439 8.28162 0.00298324 7.94392 4.86886e-05C7.60622 -0.00288586 7.28087 0.126935 7.03796 0.36155L1.13131 6.2682C0.406931 6.9928 0 7.97543 0 9C0 10.0246 0.406931 11.0072 1.13131 11.7318L7.03796 17.6385C7.28087 17.8731 7.60622 18.0029 7.94392 18C8.28162 17.997 8.60467 17.8616 8.84347 17.6228C9.08227 17.384 9.21772 17.0609 9.22066 16.7232C9.22359 16.3855 9.09377 16.0602 8.85916 15.8173L2.94864 9.9106C2.70718 9.66907 2.57154 9.34152 2.57154 9C2.57154 8.65847 2.70718 8.33093 2.94864 8.0894L8.85916 2.18275Z"
                            fill="#232A35"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="18"
                          viewBox="0 0 384 512"
                          fill="none"
                          onClick={() => setDate("")}
                        >
                          <path
                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            fill="#232A35"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className="text_one_banner">
                    <h2>{t("_Thing_to_do_in")}</h2>
                  </div>
                  <div className="heading_search_inner">
                    <h2>{bannerTitle}</h2>
                    <h4 className="heading_search_inner">
                     {bannerDescription ? bannerDescription : " "}
                    </h4>
                </div>
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
};

export default ListingBanner;
