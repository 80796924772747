import React from "react";
import { useTranslation } from "react-i18next";
import { ShimmerSectionHeader,ShimmerSimpleGallery,ShimmerButton } from "react-shimmer-effects";
const GrowMedia = ({ growMediaData, isLoading }) => {
  const { t } = useTranslation();

  return isLoading ? (
    <>
      <div className="shimmer-header">
        <ShimmerSectionHeader center />
      </div>
      <div className="shimmer-header shimmer-header-card">
        <ShimmerSimpleGallery col={3} row={1} imageHeight={300} />
      </div>
      <div className="shimmer-header shimmer-bt">
        <ShimmerButton size="lg" mode="light" center />
      </div>
    </>
  ) : (
    <div className="grow-media-section">
      <div className="sell-tourysta-section">
        <div className="container">
          <div className="card-data-main">
            <h2>{growMediaData?.grow_your_social_media_title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: growMediaData?.grow_your_social_media_description,
              }}
            ></p>

            <div className="card-data-list">
              <div className="card-data-grid">
                {growMediaData?.grow_social_media?.length > 0 &&
                  growMediaData?.grow_social_media.map((data, index) => (
                    <div className="card-one card-main" key={index}>
                      <img src={data?.icon} alt={data?.icon_title || "Icon"} />
                      <p>{data?.icon_title}</p>
                    </div>
                  ))}
              </div>
              <div className="btn-bottom-data">
                <button>{t("_Let's_Start")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowMedia;
