import React, { useContext, useState } from "react";
import LoginModal from "../Modal/LoginModal";
import LocaleContext from "../LocaleContext";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
const SupplierNewBanner = ({ supplierData, isLoading }) => {
  const { SetLoginKey, loginKey } = useContext(LocaleContext);
  const [ModalLogin, setModalLogin] = useState(false);
  const checklogin = useSelector((state) => state.login);
  const { t } = useTranslation();
  // Show Login Modal
  const showModal = (status) => {
    SetLoginKey(status);
    setModalLogin(true);
  };
  const closeLoginModal = () => {
    setModalLogin(false);
  };
  return isLoading ? (
    <ShimmerSimpleGallery col="1" row="1" imageHeight={600} />
  ) : (
    <>
      <div className="new-banner-supplier">
        <div
          className="bg-new-image"
          style={{
            backgroundImage: `url(${supplierData?.suppliers_baner_image})`,
          }}
        >
          <div className="container">
            <div className="text-data-grid">
              <div className="div-blank"></div>
              <div className="text-data-new">
                <p>{supplierData?.suppliers_baner_title || "Banner Title"}</p>
                {checklogin === false && (
                  <button
                    onClick={() => {
                      showModal("login");
                    }}
                    aria-label="Become a Supplier"
                  >
                     {t("_Become_a_Supplier")}
                    
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        loginInfo={loginKey}
        SetLoginKey={SetLoginKey}
        LoginModal={ModalLogin}
        LogincloseModal={closeLoginModal}
        userType={"Partner"}
      />
    </>
  );
};

export default SupplierNewBanner;
