import React, { useContext, useEffect, useState } from "react";
import { ProductBuilderContext } from "../../../context";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uplodProductNew } from "../../../actions/productBuilderAction";
import TicketAttractionModal from "./TicketAttractionModal";
import {
  getArray,
  startSpinLoader,
  validateField,
} from "../../../actions/customFn";
import { useTranslation } from "react-i18next";
import Geosuggest from "react-geosuggest";

export const TicketComponent = (props) => {
  const { t } = useTranslation();
  const { tour_id } = useParams();
  const {
    googleKey,
    productType,
    setTourId,
    getData,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo,
    languageIdSelected
  } = useContext(ProductBuilderContext);

  const [formErrors, setFormError] = useState({});
  const [ticketModal, setTicketmodel] = useState(false);
  const errors = {};
  const [ticketAttraction, setTicketAttraction] = useState([]);
  const [ticketAttractionKey, setTicketAttractionKey] = useState("");
  const [editTicketKey, setEditTicketData] = useState("");
  const [ticketData, setTicketData] = useState({
    duration_type: "SET_DURATION",
    time_from: "",
    time_type: "minutes",
    time_to: "",
    is_more_than_one_attraction: "no",
    activity_exact_location: "",
    activity_exact_location_latitude: "",
    activity_exact_location_longitude: "",
    description: "",
    tourId: tour_id,
    product_type: productType,
    step: "six",
    language: productInfo.productLanguageId,
    attraction_transport_list: []
  });

  console.log("ticketData", ticketData);

  const languageReducer = useSelector((state) => state.languageReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [newAttractionKey, setNewAttractionKey] = useState(0);

  useEffect(() => {
    if (languageReducer != "") {
      getData("six", setEditTicketData);
    }
  }, [languageReducer, languageIdSelected]);

  useEffect(() => {
    if (editTicketKey != undefined && editTicketKey.product_data != undefined) {
      const { product_data } = editTicketKey;
      setTicketData({
        duration_type: product_data?.duration_type ? product_data?.duration_type : "SET_DURATION",
        time_from: product_data?.time_from,
        time_type: product_data?.time_type,
        time_to: product_data?.time_to,
        is_more_than_one_attraction: product_data?.is_more_than_one_attraction,
        activity_exact_location: product_data?.activity_exact_location,
        activity_exact_location_latitude:
          product_data?.activity_exact_location_latitude,
        activity_exact_location_longitude:
          product_data?.activity_exact_location_longitude,
        description: product_data?.description,
        tourId: tour_id,
        product_type: productType,
        step: "six",
        language: productInfo.productLanguageId,
        attraction_transport_list: product_data?.attraction_transport_list

      });
      setTicketAttraction(product_data.attraction);
    }
  }, [editTicketKey]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyNumbers = /^[0-9]*$/;
    if (name === "time_to" || name === "time_from") {
      if (onlyNumbers.test(value) === true) {
        setTicketData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setTicketData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handlePlaceOnChange = async (place) => {
    if(place){
 const { lat, lng } = place.location
    const address = place.label

    setTicketData((prev) => ({
      ...prev,
      activity_exact_location: address,
      activity_exact_location_latitude: lat,
      activity_exact_location_longitude: lng,
    }));
    }
   
  };

  const handleSubmit = (e, exit) => {
    if (e) {
      e.preventDefault();
    }

    let validate = {};

    if (ticketData.time_from == "" || ticketData.time_from == null) {
      validate = { ...validate, duration: "RequiredNoBorder|" };
    } else {
      validate = { ...validate, time_from: "min:0" };
    }
    if (ticketData.duration_type == "SET_FLEXIBLE") {
      validate = { ...validate, time_to: "min:" + ticketData.time_from };
    }

    console.log("validate", validate);

    validateField(validate, { ...ticketData }).then((res) => {
      startSpinLoader("cover-spin");
      if (res == true) {
        const sendData = {
          ...ticketData,
          attraction_id: getArray(ticketAttraction, "attraction_id"),
          attraction_description: getArray(
            ticketAttraction,
            "attraction_description"
          ),
          attraction_title: getArray(ticketAttraction, "attraction_title"),
          attraction_time: getArray(ticketAttraction, "attraction_time"),
          attraction_time_type: getArray(
            ticketAttraction,
            "attraction_time_type"
          ),

          attraction_location_obj: getArray(ticketAttraction, "attraction_location_obj"),
          attraction_latitude: getArray(ticketAttraction, "attraction_latitude"),
          attraction_longitude: getArray(ticketAttraction, "attraction_longitude"),

          attraction_transport_by: getArray(ticketAttraction, "attraction_transport_by"),
          attraction_transport_time: getArray(ticketAttraction, "attraction_transport_time"),
          attraction_transport_time_type: getArray(ticketAttraction, "attraction_transport_time_type"),
        };
        let nextRoute = exit || "guide-activity-info";
        if (languageReducer !== "") {
          dispatch(
            uplodProductNew(
              languageReducer,
              setTourId,
              navigate,
              sendData,
              nextRoute,
              "",
              "",
              "",
              "",
              "",
              "",
              setSpinLoader
            )
          );
        }
      } else {
        setSpinLoader((status) => !status);
      }
    });
  };

  useEffect(() => {
    if (isFirstRender == false) {
      handleSubmit("", exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  const handleRemoveAttraction = (key) => {
    const newData = [...ticketAttraction];
    newData.splice(key, 1);
    setTicketAttraction(newData);
  };

  return (
    <div className="meet-pickup-main ticket-detail">
      <form onSubmit={handleSubmit}>
        <div className="activity_productdetail">
          <h1>{t("_Tell_us_about_your_ticket")}</h1>
          <p>
            {t(
              "_Be_sure_to_enter_all_key_attractions_in_your_itinerary_This_helps_travelers_find_and_book_your_experience."
            )}
          </p>

          <h6> {t(
            "_Does_your_ticket_offer_admission_to_more_than_one_attraction?"
          )}</h6>
          <div class="product_selectorradio">
            <input
              type="radio"
              className="productradio"
              value="yes"
              checked={ticketData?.is_more_than_one_attraction === "yes"}
              name="is_more_than_one_attraction"
              id="is_more_than_one_attraction1"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <label htmlFor="is_more_than_one_attraction1">Yes</label>
          </div>

          <div class="product_selectorradio">
            <input
              type="radio"
              className="productradio"
              value="no"
              checked={ticketData?.is_more_than_one_attraction === "no"}
              name="is_more_than_one_attraction"
              id="is_more_than_one_attraction2"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <label htmlFor="is_more_than_one_attraction2">No</label>
          </div>

          {ticketData.is_more_than_one_attraction !== "yes" && (
            <div>
              <h6>{t("_What_attraction_does_this_ticket_offer_admission_to?")}</h6>
              <label>{t("_enter_the_full_address_here_if_possible.")} <span className="required-field">*</span></label>
              <div>
                <Geosuggest
                  placeholder="Search Place here"
                  onSuggestSelect={(place) => handlePlaceOnChange(place)}
                  initialValue={ticketData?.activity_exact_location}
                />
              </div>
              {/* <div className="search_autocomplete mb-4">
                <Autocomplete
                  libraries={["places"]}
                  apiKey={googleKey}
                  className={`form-control input-text search_input mt-2`}
                  defaultValue={ticketData?.activity_exact_location}
                  onChange={(event, newValue) => {
                    console.log("newValue", newValue);
                    // if (event && event?.target?.value) {
                    //   setTicketData((prev) => ({
                    //     ...prev,
                    //     activity_exact_location: event?.target?.value,
                    //   }));
                    // }
                  }}
                  onPlaceSelected={(place) => {
                    handlePlaceOnChange(place);
                  }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                />

                <span>
                  <svg
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                    class="LocationSelect__arrowIcon___3xu-b Icon__icon___2sbfU"
                  >
                    <path
                      d="M6.75 1.5a5.26 5.26 0 11-.01 10.51A5.26 5.26 0 016.75 1.5m0 12c1.6 0 3.07-.56 4.22-1.5l5.75 5.78a.75.75 0 101.06-1.06l-5.75-5.78a6.76 6.76 0 10-5.28 2.56"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div> */}
            </div>
          )}

          <div className="time_do_travelers">
            <h6> {t("_How_much_time_do_travelers_typically_spend_here?")}</h6>
            <div class="product_selectorradio">
              <input
                type="radio"
                className="productradio"
                name="duration_type"
                checked={ticketData?.duration_type === "SET_DURATION"}
                id="duration_type1"
                onChange={(e) => {
                  handleChange(e);
                }}
                value="SET_DURATION"
              />
              <label htmlFor="duration_type1"> {t("_Set_duration")}</label>
            </div>

            {ticketData?.duration_type === "SET_DURATION" && (
              <div className="time_inputboxes" name="duration">
                <div className="tbox_one" name="time_from">
                  <input
                    type="text"
                    name="time_from"
                    value={ticketData?.time_from}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="0"
                  />
                  <select
                    id="time_type"
                    value={ticketData?.time_type}
                    name="time_type"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    <option value="minute">{t("_minute")}</option>
                    <option value="hour">{t("_hour")}</option>
                  </select>
                </div>
              </div>
            )}

            <div class="product_selectorradio">
              <input
                type="radio"
                className="productradio"
                id="duration_type2"
                name="duration_type"
                value="SET_FLEXIBLE"
                onChange={(e) => {
                  handleChange(e);
                }}
                checked={ticketData?.duration_type === "SET_FLEXIBLE"}
              />
              <label htmlFor="duration_type2">  {t("_Flexible_duration")}</label>
            </div>

            {ticketData?.duration_type === "SET_FLEXIBLE" && (
              <div className="time_inputboxes" name="duration">
                <div className="tbox_one" name="time_from">
                  <input
                    type="text"
                    value={ticketData.time_from}
                    name="time_from"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="0"
                  />
                </div>
                <span>{t("_to")}</span>
                <div className="tbox_one" name="time_to">
                  <input
                    type="text"
                    name="time_to"
                    value={ticketData.time_to}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="0"
                  />
                  <select
                    id="time_type"
                    value={ticketData.time_type}
                    name="time_type"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    <option value="minute">{t("_minute")}</option>
                    <option value="hour"> {t("_hour")}</option>
                  </select>
                </div>
              </div>
            )}
          </div>

          {ticketData.is_more_than_one_attraction === "yes" ? (
            <div class="attraction-travelers">
              <h6> {t("_Add_all_the_attractions_this_ticket_offers_admission_to")}</h6>

              {ticketAttraction.length > 0 &&
                ticketAttraction.map((value, index) => {
                  return (
                    <div className="inclueded_partsection" key={index}>
                      <div className="attraction_multiple">
                        <h3>{value?.attraction_title}</h3>
                        <p>{value?.attraction_description}</p>
                      </div>

                      <div>
                        <span
                          className="cursor-pointer"
                          onClick={(e) => {
                            setTicketAttractionKey(index);
                            setNewAttractionKey(index)
                            setTicketmodel(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M20.6626 6.64062L17.3762 3.33737C17.159 3.1213 16.8651 3 16.5588 3C16.2524 3 15.9585 3.1213 15.7413 3.33737L4.09038 14.9715L3.02662 19.5625C2.98992 19.7303 2.99118 19.9042 3.0303 20.0715C3.06942 20.2388 3.14542 20.3952 3.25273 20.5294C3.36005 20.6635 3.49598 20.772 3.65059 20.8469C3.80519 20.9217 3.97458 20.9611 4.14637 20.9622C4.22641 20.9703 4.30707 20.9703 4.38711 20.9622L9.02846 19.8984L20.6626 8.27545C20.8787 8.05828 21 7.76439 21 7.45804C21 7.15169 20.8787 6.8578 20.6626 6.64062ZM8.46859 18.8907L4.11837 19.8032L5.10935 15.537L13.8266 6.85338L17.1858 10.2126L8.46859 18.8907ZM17.936 9.4008L14.5768 6.04156L16.5252 4.1044L19.8284 7.46364L17.936 9.4008Z"
                              fill="#fc5301"
                            />
                          </svg>
                        </span>

                        <span
                          className="cursor-pointer"
                          onClick={(e) => {
                            handleRemoveAttraction(index);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                              fill="#fc5301"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  );
                })}

              <button
                name="attraction_title.0"
                className="trasnparent_buton mt-2"
                onClick={(event) => {
                  setTicketmodel(true);
                  setNewAttractionKey(ticketAttraction.length)
                }}
                type="button"
              >
                +  {t("_add_an_attraction")}
              </button>
            </div>
          ) : (
            <div>
              <div>
                <h6> {t("_Describe_what_travelers_will_see_and_do_here")}</h6>
                <textarea
                  name="description"
                  id="description"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={ticketData?.description}
                  rows="3"
                  placeholder="Describe what travelers will see and do here"
                ></textarea>
              </div>
            </div>
          )}

          <div className="btn-last-save">
            <button type="submit" className="btn-all-save">
              {t("_Save_&_continue")}
            </button>
          </div>
        </div>
      </form>
      <TicketAttractionModal
        setTicketmodel={setTicketmodel}
        ticketModal={ticketModal}
        ticketAttraction={ticketAttraction}
        setTicketAttraction={setTicketAttraction}
        ticketAttractionKey={ticketAttractionKey}
        setTicketAttractionKey={setTicketAttractionKey}
        newAttractionKey={newAttractionKey}
        ticketData={ticketData}
      />
    </div>
  );
};

export default TicketComponent;
