import { useRef, useState, useEffect } from "react";
import { MetaData, images } from "../../actions/customFn";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import {
  getProviderData,
  uploadProviderData,
} from "../../actions/providerAction";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import validator from "validator";
import LoadingBar from "../Loading/LoadingBar";
import { useTranslation } from "react-i18next";

const SupplierPage = () => {
  const { t } = useTranslation();
  const bannerRef = useRef();
  const logoRef = useRef();
  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const profileReducer = useSelector((state) => state.profileReducer);
  const [providerData, setProviderData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [banner, setBanner] = useState(false);
  const [logo, setLogo] = useState(false);
  const [formData, setFormData] = useState({
    banner: "",
    logo: "",
    partner_title: "",
    partner_short_description: "",
    company_name: "",
    company_address: "",
    company_email: "",
    company_phone: "",
    company_information: "",
    company_description: "",
    slug: "",
  });
  const [formErrors, setFormError] = useState({});
  const errors = {};

  useEffect(() => {
    if (languageReducer !== "" && profileReducer !== "") {
      setIsLoading(true);
      let data = {
        slug: profileReducer.slug,
      };
      dispatch(
        getProviderData(
          languageReducer,
          setProviderData,
          navigate,
          setIsLoading,
          data
        )
      );
    }
  }, [languageReducer, profileReducer]);

  useEffect(() => {
    if (providerData) {
      const {
        banner,
        logo,
        title,
        company_name,
        short_description,
        company_information,
        company_email,
        company_description,
        company_address,
        company_phone,
        slug,
      } = providerData;

      setFormData((prev) => ({
        ...prev,
        banner: banner,
        logo: logo,
        partner_title: title,
        partner_short_description: short_description,
        company_name: company_name,
        company_address: company_address,
        company_email: company_email,
        company_phone: company_phone,
        company_information: company_information,
        company_description: company_description,
        slug: slug,
      }));
      setLogo(logo);
      setBanner(banner);
    }
  }, [providerData]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "banner" || name == "logo") {
      setFormData((prev) => ({ ...prev, [name]: e.target.files[0] }));
      if (name === "banner") {
        setBanner(URL.createObjectURL(e.target.files[0]));
      }
      if (name === "logo") {
        setLogo(URL.createObjectURL(e.target.files[0]));
      }
    } else if (name === "company_phone") {
      const onlyNumbers = /^[0-9]*$/;
      if (onlyNumbers.test(value) === true) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDescription = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const submit = () => {
    try {
      validateFormData();
      if (Object.keys(errors).length === 0) {
        const data = new FormData();
        const currency = JSON.parse(localStorage.getItem("currency"));
        const token = localStorage.getItem("_token");

        data.append("user_id", localStorage.getItem("userId"));
        data.append("password", localStorage.getItem("password"));
        data.append("language", languageReducer.id);
        data.append("currency", currency.id);
        data.append("token", token.id);

        data.append("company_banner_image", formData.banner);
        data.append("company_logo_image", formData.logo);
        data.append("partner_title", formData.partner_title);
        data.append(
          "partner_short_description",
          formData.partner_short_description
        );
        data.append("company_name", formData.company_name);
        data.append("company_address", formData.company_address);
        data.append("company_email", formData.company_email);
        data.append("company_description", formData.company_description);
        data.append("company_phone", formData.company_phone);
        data.append("company_information", formData.company_information);

        dispatch(uploadProviderData(data, navigate));
      }
    } catch (error) {
      // errorMsg(error.response.data.message);
      console.log(error);
    }
  };

  //=================Form Validation starts===============
  const validateFormData = () => {
    if (formData.partner_title === "") {
      errors.partner_title = "Enter title";
    }

    if (formData.company_name === "") {
      errors.company_name = "Enter company name";
    }

    if (formData.partner_short_description === "") {
      errors.partner_short_description = "Enter short description";
    }

    if (formData.company_phone === "") {
      errors.company_phone = "Enter phone number";
    } else if (!validator.isNumeric(formData.company_phone)) {
      errors.company_phone = "Phone number must be numeric";
    }

    if (formData.company_address === "") {
      errors.company_address = "Enter company address";
    }

    if (formData.company_description === "") {
      errors.company_description = "Enter company description";
    }

    if (formData.company_information === "") {
      errors.company_information = "Enter company information";
    }

    setFormError(errors);
  };
  //=================Form Validation ends===============

  // console.log("providerData", providerData);

  let metaData = {
    title: t("_supplier_page"),
  };

  return (
    <>
      {metaData && <MetaData data={metaData} />}
      <div className="provider_info_page">
        <div className="profile-provider-main">
          <div className="text_banner">
            <span>{t("_supplier_page")}</span>{" "}
            <span className="view-detail-btn">
              <NavLink to={`/activity-supplier/${formData.slug}`}>
                {t("_view_my_page")}
              </NavLink>
            </span>
          </div>
          {isLoading === true ? (
            <div className="blinking-bar-loader">
              <LoadingBar />
            </div>
          ) : (
            <div className="user_edit_main partner_edit_main">
              <div className="input_all_edit">
                <div className="row">
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                        <div className="file_selection_box">
                          <div className="file_inner_box ">
                            <img src={images["file_main.png"]} alt="" />
                            <p>{t("_upload_banner")}</p>
                            <button
                              onClick={() => bannerRef.current.click()}
                              className="btn_select"
                            >
                              {t("_select_image")}
                            </button>
                            <input
                              ref={bannerRef}
                              type="file"
                              className="d-none"
                              name="banner"
                              id="banner"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                        <div className="provider_upload_img">
                          <img src={banner} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                        <div className="file_selection_box">
                          <div className="file_inner_box ">
                            <img src={images["file_main.png"]} alt="" />
                            <p>{t("_upload_company_logo")}</p>
                            <button
                              onClick={() => logoRef.current.click()}
                              className="btn_select"
                            >
                              {t("_select_image")}
                            </button>
                            <input
                              ref={logoRef}
                              type="file"
                              className="d-none"
                              name="logo"
                              id="logo"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                        <div className="provider_upload_img_logo">
                          <img src={logo} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label className="label_all mb-2">
                      {" "}
                      {t("_supplier_title")}{" "}
                    </label>
                    <input
                      type="text"
                      id="partner_title"
                      name="partner_title"
                      placeholder={t("_enter_supplier_title")}
                      className="input_first"
                      value={formData.partner_title}
                      onChange={handleChange}
                    />
                    {formErrors.partner_title && (
                      <div className="invalid-feedback">
                        {formErrors.partner_title}
                      </div>
                    )}
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                    <label className="label_all mb-2">
                      {t("_short_description")}{" "}
                    </label>
                    <input
                      type="text"
                      id="partner_short_description"
                      name="partner_short_description"
                      placeholder={t("_enter_short_description")}
                      className="input_first"
                      value={formData.partner_short_description}
                      onChange={handleChange}
                    />
                    {formErrors.partner_short_description && (
                      <div className="invalid-feedback">
                        {formErrors.partner_short_description}
                      </div>
                    )}
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label className="label_all mb-2">
                      {t("_company_name")}{" "}
                    </label>
                    <input
                      type="text"
                      id="company_name"
                      name="company_name"
                      placeholder={t("_enter_company_name")}
                      className="input_first"
                      value={formData.company_name}
                      onChange={handleChange}
                    />
                    {formErrors.company_name && (
                      <div className="invalid-feedback">
                        {formErrors.company_name}
                      </div>
                    )}
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label className="label_all mb-2">
                      {t("_company_address")}
                    </label>
                    <input
                      type="text"
                      id="company_address"
                      name="company_address"
                      placeholder={t("_enter_company_address")}
                      className="input_first"
                      value={formData.company_address}
                      onChange={handleChange}
                    />
                    {formErrors.company_address && (
                      <div className="invalid-feedback">
                        {formErrors.company_address}
                      </div>
                    )}
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label className="label_all mb-2">
                      {t("_company_email_address")}{" "}
                    </label>
                    <input
                      type="text"
                      id="company_email"
                      name="company_email"
                      placeholder={t("_enter_company_email_address")}
                      className="input_first"
                      defaultValue={formData.company_email}
                      // onChange={handleChange}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label className="label_all mb-2">
                      {t("_company_phone_number")}{" "}
                    </label>
                    <input
                      type="text"
                      id="company_phone"
                      name="company_phone"
                      placeholder={t("_enter_your_company_phone_number")}
                      className="input_first"
                      value={formData.company_phone}
                      onChange={handleChange}
                    />
                    {formErrors.company_phone && (
                      <div className="invalid-feedback">
                        {formErrors.company_phone}
                      </div>
                    )}
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label className="label_all mb-2">
                      {t("_supplier_information")}{" "}
                    </label>
                    <input
                      type="text"
                      id="company_information"
                      name="company_information"
                      placeholder={t("_enter_supplier_information")}
                      className="input_first"
                      value={formData.company_information}
                      onChange={handleChange}
                    />
                    {formErrors.company_information && (
                      <div className="invalid-feedback">
                        {formErrors.company_information}
                      </div>
                    )}
                  </div>

                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label className="label_all mb-2">
                      {t("_long_description")}{" "}
                    </label>
                    <div className="editor_inner">
                      <ReactQuill
                        className="text-black"
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={formData.company_description}
                        onChange={(value) =>
                          handleDescription("company_description", value)
                        }
                        style={{ height: "250px", color: "#fff" }}
                      ></ReactQuill>
                    </div>
                    {formErrors.company_description && (
                      <div className="invalid-feedback">
                        {formErrors.company_description}
                      </div>
                    )}
                  </div>
                  <div className="save_chnages_btn">
                    <button onClick={submit}>{t("_save_details")}</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SupplierPage;
