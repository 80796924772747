import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { ProductBuilderContext } from "../../context";
import {
  checkInputLength,
  startSpinLoader,
  validateField,
} from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/fontawesome-free-regular";
import { useTranslation } from "react-i18next";

const MakeProductUnique = (props) => {
  const { t } = useTranslation();
  const [hightlightdata, setData] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tour_id } = useParams();
  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    setTourId,
    getData,
    setSpinLoader,
    saveAndExit,
    productInfo,
    exitRoute,
    languageIdSelected,
  } = useContext(ProductBuilderContext);
  const [formErrors, setFormError] = useState({});
  const errors = {};
  const [HighLightLengthMsg, setHighLightLengthMsg] = useState([]);
  let MIN_CHAR_LENGTH = 150;
  let MAX_CHAR_LENGTH = 1200;
  const [cursorPosition, setCursorPosition] = useState(0);

  // ----------Get Unique Product Details------------
  useEffect(() => {
    if (languageReducer != "") {
      getData("nine", setData);
    }
  }, [languageReducer, languageIdSelected]);

  const [formData, setFormData] = useState({
    highlight: [],
    highlight_id: [],
    step: "nine",
    language: productInfo.productLanguageId,
  });

  useEffect(() => {
    if (hightlightdata) {
      setFormData({
        highlight: hightlightdata.product_data.highlight,
        highlight_id: hightlightdata.product_data.highlight_id,
        step: "nine",
        tourId: tour_id,
        language: productInfo.productLanguageId,
      });
    }
  }, [hightlightdata]);

  const highlightChange = (key, e) => {
    const { value } = e.target;

    const newPosition = e.target.selectionStart; // Get the new cursor position where user wants
    setCursorPosition(newPosition);

    const newstate = [...formData.highlight];
    newstate[key] = value.substring(0, MAX_CHAR_LENGTH);
    const errorMsg = [...HighLightLengthMsg];
    checkInputLength(
      value.substring(0, MAX_CHAR_LENGTH),
      MIN_CHAR_LENGTH,
      MAX_CHAR_LENGTH,
      t
    )
      .then((response) => {
        errorMsg[key] = response;
        setHighLightLengthMsg(errorMsg);
      })
      .catch((err) => {
        errorMsg(err);
      });
    setFormData((prev) => ({ ...prev, ["highlight"]: newstate }));
  };

  const duplicatehighlight = () => {
    setFormData((prev) => ({
      ...prev,
      highlight: [...prev.highlight, ""],
      highlight_id: [...prev.highlight_id, ""],
    }));
  };

  const removehighlight = (key) => {
    const newhighlightData = formData.highlight.filter((item, itemIndex) => {
      return itemIndex !== key;
    });

    const newHighlightErr = HighLightLengthMsg.filter((item, itemIndex) => {
      return itemIndex !== key;
    });

    const newhighlightIdData = formData.highlight_id.filter(
      (item, itemIndex) => {
        return itemIndex !== key;
      }
    );

    setFormData((prev) => ({
      ...prev,
      highlight: newhighlightData,
      highlight_id: newhighlightIdData,
    }));
    setHighLightLengthMsg(newHighlightErr);
  };

  const submit = (exit) => {
    var validate = {
      arr_highlight: "array|Required",
    };

    validateField(validate, { ...formData }).then((res) => {
      if (res == true) {
        startSpinLoader("cover-spin");
        let nextRoute = exit || "physical-restriction";
        if (languageReducer !== "") {
          dispatch(
            uplodProductNew(
              languageReducer,
              setTourId,
              navigate,
              formData,
              nextRoute,
              "",
              "",
              "",
              "",
              "",
              "",
              setSpinLoader
            )
          );
          // }
        }
      } else {
        setSpinLoader((status) => !status);
      }
    });
  };

  useEffect(() => {
    if (isFirstRender == false) {
      submit(exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  return (
    <>
      <div className="meet-pickup-main">
        <div className="makeproduct_unique activity_productdetail">
          <h1> {t("_What_sets_your_activity_apart?")}</h1>
          <h6>
            {t(
              "_Encourage_travelers_to_book_your_activity_by_highlighting_what_makes_it_unique_and _interesting"
            )}
          </h6>

          {formData.highlight?.map((value, index) => {
            return (
              <div className="unique-product-react-quill" key={index}>
                <div className="position-relative">
                  {index > 0 && (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="text-danger cursor-pointer"
                      onClick={(e) => removehighlight(index)}
                    />
                  )}

                  <textarea
                    rows="8"
                    value={value}
                    id={`highlight.${index}`}
                    name={`highlight.${index}`}
                    onChange={(e) => highlightChange(index, e)}
                    className=""
                    maxLength={MAX_CHAR_LENGTH}
                    ref={(textarea) => {
                      if (textarea !== null) {
                        textarea.selectionStart = cursorPosition;
                        textarea.selectionEnd = cursorPosition;
                      }
                    }}
                  ></textarea>
                </div>
                <div className="invalid-feedback title-length-error-msg">
                  {HighLightLengthMsg[index]?.message}
                </div>
              </div>
            );
          })}

          <button
            className="trasnparent_buton"
            onClick={() => duplicatehighlight()}
          >
            + {t("_Add_another")}
          </button>
          <div className="btn-last-save">
            <button onClick={() => submit()} className="btn-all-save">
              {t("_Save_&_continue")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeProductUnique;
