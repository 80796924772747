import React, { useContext } from "react";
import { HomeContext } from "../../context";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CategoriesNew = () => {
  const navigate = useNavigate();
  const { setSlug, slug, setIsCategoryLoading } = useContext(HomeContext);
  const homeData = useSelector((state) => state.home);

  return (
    <>
      <div className="list__page_inner">
        <div className="container"> 
          <div className="box__list_dis">
            {/* <div className="box__list_main not_active_image"> */}

            {
              homeData.categories.length > 0 &&
              homeData.categories.map((value, index) => {
                return (
                  <div
                    key={index}
                    className={`box__list_main ${slug == "recommended_tours" ? "" : slug == value.slug ? "active__image" : "not_active_image"}`}
                  >
                    <button
                      onClick={() => { setSlug(value.slug) }}
                    >
                      <div
                        className="box_list_img "
                        style={{
                          backgroundImage: `url(${value.image})`,
                        }}
                      >
                        {/* <img src={images["Ct-1.jpg"]} /> */}
                      </div>
                      <div className="text-btn-div">
                        <p>{value.title} </p>
                      </div>
                    </button>
                  </div>
                )
              })
            }

            {/* <span
              className="bg_color_span cursor-pointer"
              onClick={() => navigate(`/culture-listing`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width="8" height="14" fill="none"
              >
                <path
                 fill="#FC5301"
                  d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
              </svg>
            </span> */}

            <span
              className="bg_color_span cursor-pointer"
              onClick={() => navigate(`/culture-listing`)}
            >
              <svg xmlns="http://www.w3.org/2000/svg"
                width="8" height="14" viewBox="0 0 8 14" fill="none">
                  <path id="Vector" d="M0.28142 12.3023C0.0989415 12.4912 -0.00203005 12.7443 0.000252377 13.0069C0.0025348 13.2696 0.107888 13.5209 0.293623 13.7066C0.479357 13.8923 0.730611 13.9977 0.993268 14C1.25593 14.0022 1.50897 13.9013 1.69791 13.7188L6.29197 9.12473C6.85537 8.56116 7.17188 7.79689 7.17188 7C7.17188 6.20311 6.85537 5.43884 6.29197 4.87527L1.69791 0.281206C1.50897 0.0987274 1.25593 -0.00224415 0.993268 3.82748e-05C0.730611 0.0023207 0.479357 0.107675 0.293623 0.293409C0.107888 0.479144 0.0025348 0.730397 0.000252377 0.993055C-0.00203005 1.25571 0.0989415 1.50876 0.28142 1.69769L4.87849 6.29176C5.06629 6.47961 5.17179 6.73437 5.17179 7C5.17179 7.26563 5.06629 7.52039 4.87849 7.70824L0.28142 12.3023Z"
                  fill="#FC5301">
                </path>
              </svg>
            </span>
            {/* 
            <div className="box__list_main not_active_image">
              <button>
                <div
                  className="box_list_img "
                  style={{
                    backgroundImage: `url(${images["Ct-2.png"]})`,
                  }}
                >
                </div>
                <div className="text-btn-div">
                  <p>Adventure </p>
                </div>
              </button>
            </div>

            <div className="box__list_main  active__image">
              <button>
                <div
                  className="box_list_img"
                  style={{
                    backgroundImage: `url(${images["Ct-3.png"]})`,
                  }}
                >
                </div>
                <div className="text-btn-div">
                  <p>Cultural </p>
                </div>
              </button>
            </div>

            <div className="box__list_main not_active_image">
              <button>
                <div
                  className="box_list_img "
                  style={{
                    backgroundImage: `url(${images["Ct-4.png"]})`,
                  }}
                >
                </div>
                <div className="text-btn-div">
                  <p>Themed Parks </p>
                </div>
              </button>
            </div>

            <div className="box__list_main not_active_image">
              <button>
                <div
                  className="box_list_img "
                  style={{
                    backgroundImage: `url(${images["Ct-5.png"]})`,
                  }}
                >
                </div>
                <div className="text-btn-div">
                  <p>Food </p>
                </div>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesNew;
