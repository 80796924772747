import { useEffect } from "react";
import {
  AllTags,
  FilterBox,
  Footer,
  HeaderSticky,
  LoadingBar,
  Partner,
  Testimonial,
} from "../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import ProductList from "../components/CultureListing/ProductList";
import "react-modern-drawer/dist/index.css";
import { ProductListingInfo } from "../context";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllsearchAllData,
  getFilterListing,
} from "../actions/listingAction";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
import { MetaData, handleScroll, images } from "../actions/customFn";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Modal from "react-bootstrap/Modal";
import { TextField, Autocomplete } from "@mui/material";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

const AllSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [isDataLoad, setIsDataLoading] = useState(true);
  const [listingData, setListingData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [productCount, setProductCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [filterListing, setFilterListing] = useState({});
  const [bannerData, setBannerData] = useState({});
  const checklogin = useSelector((state) => state.login);
  const [metaData, setMetaData] = useState("");
  const [filterOn, setFilterOn] = useState(false);
  // Modal Filter
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [locationModelOpen, setLocationModelOpen] = useState(false);
  const [filterQuery, setFilterQuery] = useState({
    time_filter: [],
    interest_filter: [],
    category_filter: [],
    service_filter: [],
    min_price_filter: "",
    max_price_filter: "",
    sort: "",
    is_filter: 0,
  });

  const [formData, setFormData] = useState(filterQuery);
  const [bannerTitle, setBannerTitle] = useState("");
  const queryParams = new URLSearchParams(location.search); //to read query from url
  // const [isIntrest, setIsIntrest] = useState(queryParams.get('intrest') ? queryParams.get('intrest') : 0);
  const [isIntrest, setIsIntrest] = useState(0);
  const [date, setDate] = useState("");
  let productCityData = JSON.parse(
    localStorage.getItem("my_tiket_product_city")
  );
  const [productCityOBject, setProductCityObject] = useState(
    productCityData ? productCityData : { travelling_city_id: "", name: "" }
  );
  const [filterApplied, setFilterAppleid] = useState(0);

  const handleDate = (date) => {
    date = moment(date).format("yyyy-MM-DD");
    setDate(date);
    handleScroll("scroll", 150);
  };

  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(
        getFilterListing(
          languageReducer,
          setFilterListing,
          setBannerData,
          navigate,
          setMetaData
        )
      );
    }
  }, [languageReducer]);

  useEffect(() => {
    if (languageReducer != "") {
      let exrtraData = {
        is_interest: isIntrest,
        date: date,
        ...productCityOBject,
      };

      setIsDataLoading(true);
      getAllsearchAllData(
        languageReducer,
        setListingData,
        navigate,
        setIsDataLoading,
        setPageCount,
        currentPage,
        slug,
        location,
        setIsLoading,
        formData,
        setProductCount,
        setBannerTitle,
        exrtraData
      );
    }
  }, [
    currentPage,
    languageReducer,
    checklogin,
    slug,
    filterOn,
    formData.sort,
    isIntrest,
    date,
    productCityOBject,
  ]);

  useEffect(() => {
    if (formData.is_filter == 1) {
      setCurrentPage(0);
    }
  }, [formData]);

  const handleCity = (option) => {
    console.log("option", option);
    if (option) {
      let data = {
        travelling_city_id: option.id,
        name: option.name,
      };
      setProductCityObject(data);
      localStorage.setItem("my_tiket_product_city", JSON.stringify(data));
      setLocationModelOpen(false);
    }
  };

  const handleChange = (event) => {
    setCurrentPage(0);
    setFormData((prev) => ({
      ...prev,
      sort: event.target.value,
      is_filter: 1,
    }));
    setFilterQuery((prev) => ({
      ...prev,
      sort: event.target.value,
      is_filter: 1,
    }));
    // handleScroll("scroll", 100);
  };

  const resetFilterData = () => {
    if (formData.is_filter) {
      setCurrentPage(0);
      setFormData({
        time_filter: [],
        interest_filter: [],
        category_filter: [],
        service_filter: [],
        min_price_filter: "",
        max_price_filter: "",
        sort: "",
        is_filter: 0,
      });
      setFilterQuery({
        time_filter: [],
        interest_filter: [],
        category_filter: [],
        service_filter: [],
        min_price_filter: "",
        max_price_filter: "",
        sort: "",
        is_filter: 0,
      });
      setFilterOn((status) => !status); //to call API
      handleScroll("scroll", 150);
    }
  };

  useEffect(() => {
    let count =
      formData.category_filter.length +
      formData.service_filter.length +
      formData.time_filter.length;
    setFilterAppleid(count);
  }, [formData.category_filter, formData.service_filter, formData.time_filter]);

  return (
    <>
      <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
        {metaData && <MetaData data={metaData} />}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        >
          <ProductListingInfo.Provider
            value={{
              isDataLoad,
              setIsDataLoading,
              listingData,
              setPageCount,
              pageCount,
              currentPage,
              setCurrentPage,
              filterQuery,
              setFilterQuery,
              setFormData,
              setFilterOn,
              setShow,
              filterListing,
              isIntrest,
              setIsIntrest,
              bannerTitle,
              setDate,
              date,
              filterApplied
            }}
          >
            <HeaderSticky />
            <>
              {/* <ListingBanner />
                            <div className="Culture__page_list new__inner_culture">
                                <CategoriesListing />
                            </div> */}

              <div className="culture_listing_fillter mt-4">
                <div className="container">
                  <div className="culture_listing_main">
                    <div className="filter__sort__bar">
                      <div className="list_top_bar sort__innerInfo">
                        {/* <div className="location-div">

                                                    <div
                                                        className="icon_loc"
                                                        onClick={() => setLocationModelOpen(true)}
                                                    >
                                                        {" "}


                                                        <button
                                                            className="selected-location-btn"
                                                        >
                                                            {" "}
                                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                            <span> {productCityOBject.name ? productCityOBject.name : "Where you want to go ?"}</span>
                                                        </button>
                                                    </div>
                                                    {
                                                        productCityOBject.name != "" &&
                                                        <span className="remove-city-btn">
                                                            <FontAwesomeIcon icon={faTimes}
                                                                onClick={() => removeCity()}
                                                            />
                                                        </span>
                                                    }
                                                </div> */}
                        <div className="info__date_inner">
                          <div className="icon__date">
                            <img src={images["icon_new.png"]} alt="" />
                          </div>
                          <Flatpickr
                            //   data-enable-time
                            className="date_picker"
                            id="date"
                            name="date"
                            value={date}
                            placeholder={t("_select_date")}
                            options={{
                              minDate: "today",
                              dateFormat: "Y-m-d",
                              monthSelectorType: "static",
                            }}
                            onChange={(value) => handleDate(value[0])}
                          />
                          <div className="icon_down_svg">
                            {date == "" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="18"
                                viewBox="0 0 10 18"
                                fill="none"
                              >
                                <path
                                  d="M8.85916 2.18275C9.09377 1.93983 9.22359 1.61449 9.22066 1.27678C9.21772 0.939081 9.08227 0.616041 8.84347 0.37724C8.60467 0.138439 8.28162 0.00298324 7.94392 4.86886e-05C7.60622 -0.00288586 7.28087 0.126935 7.03796 0.36155L1.13131 6.2682C0.406931 6.9928 0 7.97543 0 9C0 10.0246 0.406931 11.0072 1.13131 11.7318L7.03796 17.6385C7.28087 17.8731 7.60622 18.0029 7.94392 18C8.28162 17.997 8.60467 17.8616 8.84347 17.6228C9.08227 17.384 9.21772 17.0609 9.22066 16.7232C9.22359 16.3855 9.09377 16.0602 8.85916 15.8173L2.94864 9.9106C2.70718 9.66907 2.57154 9.34152 2.57154 9C2.57154 8.65847 2.70718 8.33093 2.94864 8.0894L8.85916 2.18275Z"
                                  fill="#232A35"
                                />
                              </svg>
                            ) : (
                              <svg
                                className="cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="18"
                                viewBox="0 0 384 512"
                                fill="none"
                                onClick={() => setDate("")}
                              >
                                <path
                                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                  fill="#232A35"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                        <div className="sorting-option-div">
                          <div className="sort_one">
                            <div className="prd_Sort_by">
                              <span className="new_sort_title">
                                {t("_sort_by")}
                              </span>
                              <div className="sort_select">
                                <Select
                                  labelId="demo-simple-select-label"
                                  className="new_sort_list"
                                  id="demo-simple-select"
                                  label="Sort by"
                                  value={formData.sort}
                                  onChange={handleChange}
                                >
                                  <MenuItem value="atz">
                                    {t("_a_to_z")}
                                  </MenuItem>
                                  <MenuItem value="zta">
                                    {t("_z_to_a")}
                                  </MenuItem>
                                  <MenuItem value="htl">
                                    {t("_high_to_low")} ({t("_price")})
                                  </MenuItem>
                                  <MenuItem value="lth">
                                    {t("_low_to_high")} ({t("_price")})
                                  </MenuItem>
                                </Select>
                              </div>
                            </div>
                          </div>

                          <div className="sort_two">
                            {filterApplied == 0 ? (
                              <button
                                type="button"
                                onClick={() => handleShow()}
                              >
                                {t("_Apply_Filter")}
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => handleShow()}
                              >
                                {t("_Filter_Applied")}({filterApplied})
                              </button>
                            )}
                          </div>
                          {(filterApplied > 0 || formData.sort != "") && (
                            <div className="sort_two">
                              <div className="filter_mobile">
                                <button
                                  type="button"
                                  onClick={() => resetFilterData()}
                                >
                                  {t("_Reset")}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {
                      // productCityOBject &&
                      <div className="modal-filter-new">
                        <Modal
                          className="filter__modalMain new_location_modal"
                          show={locationModelOpen}
                          onHide={() => setLocationModelOpen(false)}
                          centered
                        >
                          <div className="btn-close-inner">
                            <button onClick={() => setLocationModelOpen(false)}>
                              <img src={images["close_01.png"]} />
                            </button>
                          </div>
                          <Modal.Body>
                            <div className="Loaction_box_text">
                              <p> {t("_Where_you_want_to_go_?")}</p>
                              <div>
                                <div className="dashboard_searchbar">
                                  <Autocomplete
                                    className="product-city-input"
                                    disablePortal
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, option) => handleCity(option)}
                                    id="combo-box-demo"
                                    options={filterListing?.product_city}
                                    value={
                                      productCityOBject
                                        ? filterListing?.product_city?.find(
                                            (item) =>
                                              item.id ==
                                              productCityOBject.travelling_city_id
                                          )
                                        : { travelling_city_id: "", name: "" }
                                    }
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={t("_select_your_travellin_city")}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    }

                    <div className="modal-filter-new">
                      <Modal
                        className="filter__modalMain"
                        show={show}
                        size="md"
                        onHide={handleClose}
                      >
                        <div className="btn-close-inner">
                          <button onClick={handleClose}>
                            <img src={images["close_01.png"]} />
                          </button>
                        </div>
                        <Modal.Body>
                          <div className="page_filter_box">
                            <FilterBox filterListing={filterListing} />
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>

                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 desktop_grid_new">
                        <div className="activities_all_prd" id="scroll">
                          <ProductList />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <AllTags />
              <Testimonial />
              <Partner />
              <Footer />
            </>
          </ProductListingInfo.Provider>
        </motion.div>
      </LoadingOverlay>
    </>
  );
};

export default AllSearch;
