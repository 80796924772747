import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PrincingScheduleContext, ProductBuilderContext } from "../../../context";
import { useDispatch, useSelector } from "react-redux";
import {
    removeProductOption
} from "../../../actions/tourFilesAction";
import { useTranslation } from "react-i18next";

const OptionList = () => {
    const { t } = useTranslation();
    // const { getData, setDataLoad, dataLoad } = useContext(TourFilesContext);
    const { setTourId, getData, setDataLoad, dataLoad, runningDtep, productType, } = useContext(ProductBuilderContext);

    const {
        setFormData,
        ShowAddOPtionModel,
        isUpload,
        optionData,
        setOptionList,
        formDataNew,
        setFormDataNew
    } = useContext(PrincingScheduleContext);

    // const [optionData, setData] = useState("");
    const [pricingList, setPricingList] = useState([]);
    const [optionType, setOptionType] = useState("");
    // const { setOptionId } = useContext(TourFilesContext);
    const languageReducer = useSelector((state) => state.languageReducer);
    const { tour_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [formDataNew, setFormDataNew] = useState({
    //     option_type: "",
    //     option_list: [],
    //     step: "create_option",
    //     tourId: tour_id,
    // });

    useEffect(() => {
        if (optionData) {
            setFormDataNew({
                option_type: optionData.product_data.option_type,
                option_list: optionData.product_data.option_list,
                step: "create_option",
                tourId: tour_id,
            });
            setOptionType(optionData.product_data.option_type);
            // setDataLoad(false);
            setPricingList(optionData.product_data.option_list);

        }
    }, [optionData]);

    const [listLoading, setListLoading] = useState(true);
    // useEffect(() => {
    //     getData("option_list", setOptionList);
    // }, [isUpload]);


    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     console.log("data", name, value);

    //     if (name == "option_type") {
    //         if (value == "single" && formDataNew.option_list.length > 1) {
    //             swal({
    //                 title: "Oops...",
    //                 text: "keep only 1 option for single product option",
    //                 icon: "error",
    //                 //   button: "Aww yiss!",
    //             });
    //             setOptionType("multiple");
    //             setFormDataNew((prev) => ({ ...prev, [name]: "multiple" }));
    //         } else {
    //             setFormDataNew((prev) => ({ ...prev, [name]: value }));
    //             setOptionType(value);
    //         }
    //     } else {
    //         setFormDataNew((prev) => ({ ...prev, [name]: value }));
    //         setOptionType(value);
    //     }
    // };

    // const handleButtonClick = () => {
    //     // setisopen(true);
    //     // setShowButton(false);
    //     let OptionformData = {
    //         option_type: formDataNew.option_type,
    //         tourId: tour_id,
    //         step: "create_option",
    //     };
    //     let nextRoute = "option-setup";
    //     if (languageReducer !== "") {
    //         dispatch(
    //             uplodProduct(
    //                 languageReducer,
    //                 "",
    //                 navigate,
    //                 OptionformData,
    //                 nextRoute,
    //                 setOptionId
    //             )
    //         );
    //     }
    // };

    const removeOption = (value, key) => {
        let data = {
            tourId: tour_id,
            optionId: value.option_id,
        };
        dispatch(removeProductOption(languageReducer, navigate, data));
        formDataNew.option_list.splice(key, 1);
        setFormDataNew((prev) => ({ ...prev, formDataNew }));
    };


    const editOptionNew = (value) => {
        setFormData((prev) => ({
            ...prev,
            option_id: value.option_id,
            pricing_id: value.pricing_id,

        }));
        // getData("eleven", setData);
        ShowAddOPtionModel();
    }

    return (
        <>
            {/* {dataLoad === true ? (
                <div className="blinking-bar-loader-tour_files">
                    <LoadingBar />
                </div>
            ) : ( */}
            <>
                <div className="product-option">
                    <div className="product-option_box">
                        <h1>{t("_options")}</h1>
                        {/* <p>
                            Options are different variations of the same activity that can
                            be booked by your customers. For example, different activity
                            options could contain different:
                        </p> */}
                    </div>

                    {/* <div className="option_list">
                        <li>title to help customers short descriptive title to</li>
                        <li>title to help customers short descriptive title to</li>
                        <li>title to help customers short descriptive title to</li>
                        <li>title to help customers short descriptive title to</li>
                    </div>

                    <div className="option_paragraph">
                        <p>
                            You’ll always need to create at least one “default” option for
                            new activities. However, you only create multiple options only
                            if your activity can be booked with tailored differences.
                        </p>
                        <p>
                            Variations that include very different itineraries should be
                            configured as a separate activity.
                        </p>
                    </div>

                    <div className="learn_more_text">
                        <NavLink>{t("_learn_more")}</NavLink>
                    </div> */}

                    {/* <div className="search_txteditor_option">
                        <div className="search_txteditor">
                            <h6>{t("option_type")}</h6>
                            <div>
                                <Select
                                    sx={{
                                        width: "100%",
                                    }}
                                    className="input_username new-edit-main-all"
                                    id="outlined-basic"
                                    // label="singler"
                                    name="option_type"
                                    placeholder="Select your gender"
                                    value={formDataNew.option_type}
                                    onChange={(e) => handleChange(e)}
                                >
                                    <MenuItem value="single">{t("_single")}</MenuItem>
                                    <MenuItem value="multiple">{t("_multiple")}</MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div> */}
                    {formDataNew.option_list.length > 0 &&
                        formDataNew.option_list.map((value, key) => {
                            return (
                                <div className="entry_ticket_section" key={key}>
                                    <div className="entry_cross_btn cursor-pointer">
                                        <span onClick={() => removeOption(value, key)}>
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="red"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.27916 12.7208C1.45797 12.8996 1.70044 13 1.95327 13C2.2061 13 2.44857 12.8996 2.62737 12.7208L7 8.34821L11.3726 12.7208C11.5514 12.8996 11.7939 13 12.0467 13C12.2996 13 12.542 12.8996 12.7208 12.7208C12.8996 12.542 13 12.2996 13 12.0467C13 11.7939 12.8996 11.5514 12.7208 11.3726L8.34821 7L12.7208 2.62737C12.8996 2.44857 13 2.2061 13 1.95327C13 1.70044 12.8996 1.45797 12.7208 1.27916C12.542 1.10041 12.2996 1 12.0467 1C11.7939 1 11.5514 1.10041 11.3726 1.27916L7 5.65179L2.62737 1.27916C2.44857 1.10041 2.2061 0.999999 1.95327 0.999999C1.70044 0.999999 1.45797 1.10041 1.27916 1.27916C1.10042 1.45796 1 1.70044 1 1.95327C1 2.20609 1.10042 2.44857 1.27916 2.62737L5.65179 7L1.27916 11.3726C1.10041 11.5514 1 11.7939 1 12.0467C1 12.2996 1.10041 12.542 1.27916 12.7208Z"
                                                    fill="white"
                                                    stroke="white"
                                                    strokeWidth="0.5"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="ticket_main_box">
                                        <div className="ticket_heading">
                                            <h3>
                                                {value.refrence_code} | {value.title}
                                                <p>{t("_option_id")}: {value.option_id}</p>
                                            </h3>
                                        </div>
                                        <div className="option_active">
                                            {/* <NavLink
                                                // to={`/tours-files/option-setup/${tour_id}/${value.option_id}`}
                                                onClick={ShowAddOPtionModel}
                                            >
                                                {t("_edit_option")}
                                            </NavLink> */}
                                            <button className='onlyborder_btn'

                                                // onClick={ShowAddOPtionModel} 
                                                onClick={() => editOptionNew(value)}
                                            >{t("_Edit_Option")}</button>

                                        </div>
                                    </div>

                                    <div className="table_ticket_main">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>{t("_status")}:</th>
                                                    <td> {" "} &nbsp; {value.status}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th>{t("_booking_engine")}</th>
                                                    <td> {value.booking_engine}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t("_cut_off_time")}:</th>
                                                    <td>{value.cut_off_time}</td>
                                                </tr> */}
                                                <tr>
                                                    <th>{t("_type")}:</th>
                                                    <td>{value.type}</td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            );
                        })}
                </div>

                {/* {optionType == "multiple" ||
                    (optionType === "single" && formDataNew.option_list.length === 0) ? (
                    <div className="create_new_option">
                        {
                            <button onClick={() => handleButtonClick()}>
                                {t("_create_new_option")}
                            </button>
                        }
                    </div>
                ) : (
                    ""
                )} */}

                {/* <div className="save_continue_btn">
                    <button onClick={handleClick}>{t("_save_and_continue")}</button>
                </div> */}
            </>
            {/* )} */}
        </>
    );
};
export default OptionList;
