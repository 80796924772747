import React from "react";
import { images } from "../../actions/customFn";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { ShimmerSectionHeader,ShimmerSimpleGallery,ShimmerButton } from "react-shimmer-effects";

const AfiliateSlider = ({afiliateSliderData,isLoading}) => {
  const testimonial = {
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };
  const { t } = useTranslation();
  const systemData = [
    { image: images["mobile-1.png"] },
    { image: images["mobile-2.png"] },
    { image: images["mobile-3.png"] },
    { image: images["mobile-1.png"] },
  ];
  return isLoading ? (
    <>
      <div className="shimmer-header">
        <ShimmerSectionHeader center />
      </div>
      <div className="shimmer-bt">
      <ShimmerButton size="lg" mode="light" center/>
      </div>
      <div className="shimmer-header">
        <ShimmerSectionHeader center />
      </div>
      <div className="shimmer-bt">
      <ShimmerButton size="lg" mode="light" center/>
      </div>

    </>
  ) : (
    <div className="AfiliateSlider-new-page">
      <div className="system-slider-main">
        <div className="container">
          <div className="data-top-main">
            <h2>{afiliateSliderData?.new_affiliate_baner_title_add_our_new_marketing_tool}</h2>
          </div>
          <div className="slider-inner-data">
            <OwlCarousel
              key={`carousel_${Date.now()}`}
              {...testimonial}
              className="owl-carousel owl-theme slider_main"
            >
              {systemData?.length > 0 &&
                systemData.map((value, index) => {
                  return (
                    <div className="systm-data-main" key={index}>
                      <div className="system_img">
                        <img src={value.image} alt="" />
                      </div>
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
          <div className="btn-bottom-data">
            <button>Learn More </button>
          </div>

          <div className="new-data-bottom-text">
            <div className="card-data-main">
              <h2>{afiliateSliderData?.new_affiliate_ready_to_get_started_title}</h2>
              <p
                  dangerouslySetInnerHTML={{
                    __html: afiliateSliderData?.new_affiliate_ready_to_get_started_description,
                  }}
                ></p>
            </div>
            <div className="btn-bottom-data">
              <button> {t("_join_now")}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfiliateSlider;
