import React, { useContext, useEffect, useState } from "react";
import { ProductBuilderContext } from "../../context";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  images,
  startSpinLoader,
  validateField
} from "../../actions/customFn";
import { useTranslation } from "react-i18next";

const ProductCategories = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tour_id } = useParams();
  const { getData, setTourId, setProductType, setSpinLoader, saveAndExit, exitRoute, productInfo, languageIdSelected,runningStep } =
    useContext(ProductBuilderContext);
  const languageReducer = useSelector((state) => state.languageReducer);
  const [categoryData, setData] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [formData, setFormData] = useState({
    product_type: "",
    language: productInfo.productLanguageId,
    step: "two",
    tourId: tour_id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // ==========Get Product Details=======
  useEffect(() => {
    if (languageReducer != "") {
      getData("two", setData);
    }
  }, [languageReducer, languageIdSelected]);

  useEffect(() => {
    if (categoryData) {
      setProductType(categoryData.product_data.product_type);
      setFormData((formData) => ({
        ...formData,
        product_type: categoryData.product_data.product_type,
        // language: productInfo.productLanguageId,
      }));
    }
  }, [categoryData]);
  // ==========Get Product Details=======

  const submit = (exit) => {
    let validate = {
      product_type: "Required|",
    };
    validateField(validate, { ...formData }).then((res) => {
      startSpinLoader("cover-spin");
      if (res == true) {
        let nextRoute = exit || "photos";
        if (languageReducer !== "") {
          dispatch(
            uplodProductNew(
              languageReducer,
              setTourId,
              navigate,
              formData,
              nextRoute,
              "",
              "",
              "",
              "",
              "",
              "",
              setSpinLoader
            )
          );
        }
      } else {
        setSpinLoader((status) => !status);
      }
    });
  };

  useEffect(() => {
    if (isFirstRender == false) {
      submit(exitRoute);
    } else {
      setIsFirstRender(false)
    }
  }, [saveAndExit]);

  return (
    <>
      <div className="meet-pickup-main">
        <div className="product_categories">
          <h1> {t("_What_type_of_product_are_you_creating?")}</h1>
          <p>
            {t(
              "_Please_choose_carefully_as_it_impacts_the_following_sections_and_you_won't_be_able_to _edit_this_later."
            )}
          </p>
          <div className="top-header-info-box">
            <div className="icon-svg-info">
            <img src={images['excl.svg']} alt="" />
            </div>

            <div className="meet-box-content">
              <h2>{t("_category_cant_changed")}</h2>
              <p>
                {t(
                  "_this_happen_because"
                )}
              </p>
            </div>
          </div>
          <div name="product_type">
            <div className={`category_checkbox ${formData.product_type == "tour" && 'category_checkbox_checked' }`}>
              <input
                className="productradio cursor-pointer"
                type="radio"
                name="product_type"
                id="tour"
                value="tour"
                onChange={(e) => handleChange(e)}
                checked={formData.product_type == "tour" ? true : false}
              disabled={Number(runningStep) > 2 ? true : false}
              />
              <p>
                <svg
                  viewBox="0 0 55 55"
                  xmlns="http://www.w3.org/2000/svg"
                  className="TaxonomyTypeSelection__typeIcon___3G9NL Icon__icon___2sbfU"
                >
                  <g fillRule="nonzero">
                    <path d="M25.67 21.6c.94-1.22 1.9-2.51 2.78-3.82.64-.95 1.23-1.88 1.75-2.76 1.42-2.42 2.2-4.39 2.2-5.66a7.42 7.42 0 00-14.84 0c0 1.27.79 3.24 2.2 5.66.52.88 1.1 1.8 1.76 2.77a75.22 75.22 0 003.46 4.68l.69-.87zM33.9 9.36c0 1.62-.86 3.78-2.4 6.42-.54.91-1.15 1.86-1.81 2.85a76.76 76.76 0 01-4.13 5.52.75.75 0 01-1.15 0 39.05 39.05 0 01-1.3-1.63 76.7 76.7 0 01-2.84-3.9 50.7 50.7 0 01-1.8-2.84c-1.54-2.64-2.41-4.8-2.41-6.42a8.92 8.92 0 1117.84 0z"></path>
                    <path d="M24.98 13.18a3.82 3.82 0 110-7.63 3.82 3.82 0 010 7.63zm0-1.5a2.32 2.32 0 100-4.63 2.32 2.32 0 000 4.63zM45.76 35.25c.85-1.1 1.7-2.26 2.5-3.44.59-.86 1.12-1.69 1.58-2.48 1.27-2.17 1.97-3.93 1.97-5.05a6.6 6.6 0 00-13.2 0c0 1.12.7 2.88 1.96 5.04.46.8 1 1.63 1.58 2.5a67.48 67.48 0 003.05 4.14l.56-.71zm7.55-10.97c0 1.47-.78 3.42-2.18 5.8-.48.83-1.03 1.69-1.63 2.58a69.01 69.01 0 01-3.72 4.97.75.75 0 01-1.15 0 35.12 35.12 0 01-1.17-1.46 68.95 68.95 0 01-2.55-3.51 45.6 45.6 0 01-1.64-2.58c-1.39-2.38-2.17-4.33-2.17-5.8a8.1 8.1 0 1116.2 0z"></path>
                    <path d="M45.2 27.78a3.5 3.5 0 110-7.01 3.5 3.5 0 010 7.01zm0-1.5a2 2 0 100-4.01 2 2 0 000 4.01zM15.09 43.35l.4-.63c3.15-4.79 4.16-6.56 4.46-8.35.94-5.64-2.5-9.96-8.46-9.96-5.94 0-9.46 4.42-8.45 9.95.35 1.93 1.47 4.05 3.8 7.57l.63.94C9.55 46 10.8 48 11.5 49.6a42.08 42.08 0 013.59-6.25zm6.34-8.73c-.35 2.1-1.38 3.9-4.68 8.93l-.4.62c-2.9 4.43-4.1 6.8-4.1 8.7 0 1-1.5 1-1.5 0 0-2-1.07-3.98-4.53-9.17l-.63-.94c-2.44-3.7-3.62-5.94-4.02-8.13C.39 28.19 4.6 22.9 11.49 22.9c6.9 0 11.03 5.17 9.94 11.7z"></path>
                    <path d="M11.5 37.4a4.96 4.96 0 110-9.91 4.96 4.96 0 010 9.91zm0-1.5a3.46 3.46 0 100-6.91 3.46 3.46 0 000 6.91zM26.63 27.66L37.65 35a.75.75 0 00.83-1.25L27.46 26.4a.75.75 0 10-.83 1.25zM17.55 50.88L39.9 40.45a.75.75 0 00-.63-1.36L16.92 49.52a.75.75 0 00.63 1.36z"></path>
                  </g>
                </svg>{" "}
              </p>

              <div>
                <h4> {t("_Tour")}</h4>
                <p> {t("_a_guided_visit_to_one_or_more_sites")}{" "} </p>
              </div>
            </div>
            <div className={`category_checkbox ${formData.product_type == "activity" && 'category_checkbox_checked' }`}>
              <input
                className="productradio  cursor-pointer"
                type="radio"
                name="product_type"
                id="activity"
                value="activity"
                onChange={(e) => handleChange(e)}
                checked={formData.product_type == "activity" ? true : false}
              disabled={Number(runningStep) > 2 ? true : false}
              />

              <p>
                <svg
                  viewBox="0 0 55 55"
                  xmlns="http://www.w3.org/2000/svg"
                  className="TaxonomyTypeSelection__typeIcon___3G9NL Icon__icon___2sbfU"
                >
                  <g fillRule="nonzero">
                    <path d="M19.08 18.33c0-.25.02-.28.1-.31.18-.07.44-.09 1.14-.08a35.2 35.2 0 00.61 0c.7-.01.96 0 1.14.08.08.03.1.06.1.31a.75.75 0 101.5 0c0-.84-.36-1.43-1.05-1.7-.43-.18-.77-.2-1.7-.2a34.02 34.02 0 01-.59 0c-.93 0-1.27.02-1.7.2-.68.27-1.05.86-1.05 1.7a.75.75 0 001.5 0zM32.83 18.33c0-.25.02-.28.1-.31.18-.07.44-.09 1.14-.08a35.2 35.2 0 00.61 0c.7-.01.96 0 1.14.08.08.03.1.06.1.31a.75.75 0 101.5 0c0-.84-.36-1.43-1.05-1.7-.43-.18-.77-.2-1.7-.2a34.02 34.02 0 01-.59 0c-.93 0-1.27.02-1.7.2-.68.27-1.05.86-1.05 1.7a.75.75 0 001.5 0zM1.9 53.1V45.7c0-3.5 2.17-6.65 5.46-7.88l14.2-4.2a.75.75 0 00-.42-1.44L6.88 36.4a9.93 9.93 0 00-6.48 9.3v8.16c0 .42.33.75.75.75h52.7c.42 0 .76-.33.76-.75v-7.94c0-4.18-2.6-8.07-6.44-9.5l-14.31-4.24a.75.75 0 00-.43 1.44l14.26 4.21a8.7 8.7 0 015.42 8.09v7.2H1.9zM39.7 12.75C39.7 5.98 34.23.4 27.5.4c-6.72 0-12.2 5.58-12.2 12.35a.75.75 0 101.5 0C16.8 6.8 21.61 1.9 27.5 1.9c5.88 0 10.7 4.9 10.7 10.85a.75.75 0 001.5 0z"></path>
                    <path d="M18.33 27.1c-3.32 0-6.48-2.69-6.48-5.85V17.2a5.33 5.33 0 015.34-5.34H37.8a5.33 5.33 0 015.34 5.34v4.06c0 3.16-3.16 5.85-6.48 5.85a9.75 9.75 0 01-9.17-6.18 9.63 9.63 0 01-9.17 6.18zM17.2 13.35a3.83 3.83 0 00-3.84 3.84v4.06c0 2.28 2.44 4.35 4.98 4.35 4.11 0 7.47-2.6 8.43-7.41.17-.8 1.31-.8 1.48 0 .95 4.77 4.4 7.41 8.43 7.41 2.54 0 4.98-2.07 4.98-4.35V17.2a3.83 3.83 0 00-3.84-3.84H17.2z"></path>
                    <path d="M32.48 33.23a4.98 4.98 0 10-9.96 0 4.98 4.98 0 009.96 0zm1.5 0a6.48 6.48 0 11-12.96 0 6.48 6.48 0 0112.96 0z"></path>
                    <path d="M15.57 26.2c.73 2.98 2.87 5.62 5.79 7.58a.75.75 0 10.83-1.25c-2.63-1.76-4.54-4.11-5.17-6.7a.75.75 0 10-1.45.36zM33.64 33.78c2.92-1.96 5.07-4.6 5.8-7.59a.75.75 0 00-1.46-.35c-.63 2.58-2.54 4.93-5.17 6.7a.75.75 0 00.83 1.24zM39.34 34.76c-.25 5.08-5.45 9.18-11.84 9.18-6.4 0-11.6-4.1-11.84-9.18l-1.5.07c.3 5.95 6.2 10.6 13.34 10.6s13.05-4.65 13.34-10.6l-1.5-.07z"></path>
                    <path d="M20.81 32.34C11.67 31.01 4.2 28.21 4.2 24.06c0-2.82 1.87-3.83 6.12-3.83h2.3a.75.75 0 100-1.5h-2.3c-4.97 0-7.62 1.43-7.62 5.33 0 3.13 2.84 5.48 7.65 7.27a51.5 51.5 0 0010.26 2.5.75.75 0 10.21-1.49zM46.23 28.65a1.54 1.54 0 10-3.08 0 1.54 1.54 0 003.08 0zm1.5 0a3.04 3.04 0 11-6.09 0 3.04 3.04 0 016.09 0zM48.52 19.48a.4.4 0 00-.4-.4.4.4 0 00-.4.4c0 .22.19.4.4.4a.4.4 0 00.4-.4zm1.5 0a1.9 1.9 0 11-3.8 0 1.9 1.9 0 013.8 0zM50.81 10.31a.4.4 0 00-.4-.4.4.4 0 00-.39.4.4.4 0 10.8 0zm1.5 0a1.9 1.9 0 11-3.8 0 1.9 1.9 0 013.8 0z"></path>
                  </g>
                </svg>{" "}
              </p>

              <div>
                <h4>{t("_Activity")}</h4>
                <p>{t("_an_instructed_or_interactive_experience")} </p>
              </div>
            </div>

            <div className={`category_checkbox ${formData.product_type == "ticket_and_pass" && 'category_checkbox_checked' }`}>
              <input
                className="productradio  cursor-pointer"
                type="radio"
                name="product_type"
                id="ticket_and_pass"
                value="ticket_and_pass"
                onChange={(e) => handleChange(e)}
                checked={
                  formData.product_type == "ticket_and_pass" ? true : false
                }
              disabled={Number(runningStep) > 2 ? true : false}
              />
              <p>
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="TaxonomyTypeSelection__typeIcon___3G9NL Icon__icon___2sbfU"
                >
                  <g fillRule="nonzero">
                    <path d="M11.34 23.62l-.36-.35a2.4 2.4 0 00-3.4 0l-.34.35-6.86-6.85.35-.36a2.4 2.4 0 000-3.4l-.35-.35L12.7.34l.36.35a2.4 2.4 0 003.4 0l.35-.35 6.85 6.86-.35.35a2.4 2.4 0 000 3.4l.35.35-12.32 12.32zm10.93-12.35a3.4 3.4 0 010-4.05l-5.49-5.5c-1.2.9-2.85.9-4.05 0L1.76 12.7c.9 1.2.9 2.85 0 4.05l5.5 5.5a3.4 3.4 0 014.05 0l10.96-10.97z"></path>
                    <path d="M10.98 18.46l7.52-7.52c.56-.55.56-1.47 0-2.02L15.08 5.5a1.44 1.44 0 00-2.02 0l-7.52 7.52a1.44 1.44 0 000 2.02l3.42 3.42c.55.56 1.47.56 2.02 0zm.7.7c-.94.96-2.49.96-3.43 0l-3.42-3.4a2.44 2.44 0 010-3.45l7.52-7.52c.95-.95 2.5-.95 3.44 0l3.42 3.42c.94.95.94 2.5 0 3.44l-7.52 7.52z"></path>
                    <path d="M13.72 7.52l-6.15 6.16.7.7 6.15-6.15zM14.06 11.28l-2.74 2.74.71.7L14.77 12z"></path>
                  </g>
                </svg>{" "}
              </p>

              <div>
                <h4> {t("_Ticket_or_Pass")}</h4>
                <p>
                  {t(
                    "_Independent_entry_and/or_discount_to_one_or_more_attractions"
                  )}
                </p>
              </div>
            </div>
          </div>

        </div>
        <div className="btn-last-save">
          <button onClick={() => submit()} className="btn-all-save">
            {" "}
            {t("_Save_&_continue")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductCategories;
