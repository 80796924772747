import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { asyncAddNewCategoryOrSubCategory } from "../../../actions/myToursAction";
import { useSelector } from "react-redux";
import { FormHelperText } from "@mui/material";

const AddNewCategoryModal = (props) => {
  const { t } = useTranslation();
  const languageReducer = useSelector((state) => state.languageReducer);
  const { categoryAddModal, setCategoryAddModal } = props;
  const [formErrors, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  const errors = {};
  const handleModalClose = () => {
    setCategoryAddModal(false);
    setFormError({});
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };



  const validateFormData = () => {
    let errorMessage = t("_parent_field_required");
    if (formData?.category != "") {
      errors.category = errorMessage;
    }
    console.log(formData,errors);
    setFormError(errors);
  };

  console.log("formData", formData);

  const handelSubmit = (e) => {
    e.preventDefault();
    validateFormData();
    if (Object.keys(formErrors).length === 0) {
        asyncAddNewCategoryOrSubCategory(formData,languageReducer).then((res)=>{
            console.log("res",res);
        })
     
    }

  };
  return (
    <Modal
      show={categoryAddModal}
      onHide={() => {
        setCategoryAddModal(false);
      }}
      className="inclusion_pagemodels"
    >
      <Modal.Body>
        <div className="close_modelbutn">
          <button
            onClick={() => {
              handleModalClose();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                fill="#4f4d4d"
              />
            </svg>
          </button>{" "}
        </div>
        <form
          onSubmit={(e) => {
            handelSubmit(e);
          }}
        >
          <div className="inclusion_models add_new_category">
            <div>
              <h5>{t("_category")}</h5>
              <input
                type="text"
                name="category"
                className="normal_input"
                value={formData.category}
                onChange={handleChange}
              />
               <FormHelperText className="form_error">
                  {formErrors?.category}
                </FormHelperText>
            </div>
            <div className="mt-2">
              <h5>{t("_sub_category")}</h5>
              <input
                type="text"
                name="sub_category"
                className="normal_input"
                value={formData.sub_category}
                onChange={handleChange}
              />
            </div>
            <div className="model_lastendbtns">
              <button
                className="remove-allsave mr-4"
                type="button"
                onClick={() => {
                  handleModalClose();
                }}
              >
                {t("_cancel")}
              </button>

              <button type="submit" className="btnall-save ">
                {t("_Add")}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewCategoryModal;
