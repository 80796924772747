import { useContext, useEffect, useRef, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { useTranslation } from "react-i18next";
import { ProductBuilderContext } from "../../context";
import { startSpinLoader, validateField } from "../../actions/customFn";
import { TextField, Autocomplete, Select, MenuItem } from "@mui/material";

const GuideActivityInfo = () => {
  const { t } = useTranslation();
  const [guidData, setData] = useState({});
  const { getData, setDataLoad, dataLoad, setSpinLoader, saveAndExit,
    exitRoute, productInfo, languageIdSelected } = useContext(ProductBuilderContext);
  const { tour_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const languageList = useSelector((state) => state.languageList);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [formData, setFormData] = useState({
    interact: "",
    customers_sleep_overnight: "",
    accommodation_included_price: "",
    language_accomodate: [],
    tourId: tour_id,
    step: "seven",
    language: productInfo.productLanguageId,
  });

  useEffect(() => {
    if (Object.keys(guidData).length > 0) {
      setFormData((prev) => ({
        ...prev,
        interact: guidData.product_data.interact,
        customers_sleep_overnight: guidData.product_data.customers_sleep_overnight,
        accommodation_included_price: guidData.product_data.accommodation_included_price,
        language_accomodate: guidData.product_data.language_accomodate,
        language: productInfo.productLanguageId,
      }));
      setDataLoad(false);
    }
  }, [guidData]);

  useEffect(() => {
    getData("seven", setData);
  }, [languageReducer, languageIdSelected]);

  const handleChange = (e, data = "", key = "") => {
    if (key == "language_accomodate") {
      let IdArray = [];
      data.forEach((item) => {
        IdArray.push(item.id);
      });
      setFormData((prev) => ({ ...prev, [key]: IdArray }));
    }
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  const submit = (exit) => {
    if (languageReducer !== "") {
      startSpinLoader("cover-spin");
      let nextRoute = exit || "inclusion-exclusion";
      let validate = {}

      if (formData.interact == "TOUR_GUIDE" || formData.interact == "INSTRUCTOR" || formData.interact == "HOST_GREETER") {
        validate = {
          arr_language_accomodate: "array|Required",
        };
      }

      validateField(validate, { ...formData }).then((res) => {
        if (res == true) {
          dispatch(
            uplodProductNew(languageReducer, "", navigate, formData, nextRoute,
              "",
              "",
              "",
              "",
              "",
              "",
              setSpinLoader)
          );
          setDataLoad(true);                                      // Need to remove later after proper validation for ARRAY
          setSpinLoader((status) => !status);
        } else {
          setDataLoad(true);
          setSpinLoader((status) => !status);
        }
      });
    }
  };

  useEffect(() => {
    if (isFirstRender == false) {
      submit(exitRoute);
    } else {
      setIsFirstRender(false)
    }
  }, [saveAndExit]);

  function containsAny(source, target) {
    var result = source.filter(function (item) {
      return target.indexOf(item.id) > -1;
    });
    return result;
  }

  return (
    <>
      {Object.keys(guidData).length > 0 && (
        <>
          <div className="meet-pickup-main">
            <div className="language_offered activity_productdetail">
              <h1>{t("_guide_activity")}</h1>

              <h6>
                {" "}
                {t(
                  "_who_will_your_customers_mainly_interact_with_during_your_activity?"
                )}
              </h6>
              <div className="activity_info_list">
                <ul>
                  <div className="radio_btn_transpoetantion">
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="interact"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          className="radio-btn-placement"
                          value="NONE"
                          control={<Radio />}
                          checked={formData.interact === "NONE" ? true : false}
                          label={t("_Nobody")}
                        />
                        <FormControlLabel
                          className="radio-btn-placement"
                          value="TOUR_GUIDE"
                          control={<Radio />}
                          checked={
                            formData.interact === "TOUR_GUIDE" ? true : false
                          }
                          label={t(
                            "_Tour_guide_leads_the_group_of_customers_throughout_the_tour_and_explains_destination/attraction."
                          )}
                        />
                        <FormControlLabel
                          className="radio-btn-placement"
                          value="HOST_GREETER"
                          control={<Radio />}
                          checked={
                            formData.interact === "HOST_GREETER" ? true : false
                          }
                          // labelPlacement="top" 
                          label={t(
                            "_Host_or_greeter_provides_guidance_in_the_form_of_purchasing_a_ticket_and_waiting_in_line_with_customers,_but_doesn't_provide_a_full_guided_tour_of_the_attraction._A_greeter_might_give_an_introduction_to_an_activity."
                          )}
                        />
                        <FormControlLabel
                          className="radio-btn-placement"
                          value="INSTRUCTOR"
                          control={<Radio />}
                          checked={
                            formData.interact === "INSTRUCTOR" ? true : false
                          }
                          label={t(
                            "Instructor_shows_customers_how_to_use_equipment_or_teaches_them_how_to_do_something"
                          )}
                        />
                        <FormControlLabel
                          className="radio-btn-placement"
                          value="DRIVER"
                          control={<Radio />}
                          checked={
                            formData.interact === "DRIVER" ? true : false
                          }
                          label={t(
                            "Driver_only_drives_the_customer_but_doesn’t_explain_anything_along_the_way"
                          )}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {
                    (formData.interact == "TOUR_GUIDE" || formData.interact == "INSTRUCTOR" || formData.interact == "HOST_GREETER") &&
                    <div className="icon_search_peoduct_title">
                      <h6> {t("_Choose_the_language_you_can_accomodate")}</h6>
                      <div>
                        <Autocomplete
                          multiple
                          name="language_accomodate"
                          options={languageList}
                          onChange={(e, data) => handleChange(e, data, "language_accomodate")}
                          // disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          value={containsAny(languageList, formData.language_accomodate)}
                          renderInput={(params) => <TextField {...params} />}
                          className="input_username"
                        />
                      </div>
                    </div>
                  }
                </ul>
              </div>
              <div className="product_transport_text">
                <h6>
                  {t(
                    "_Will_customers_sleep_overnight_somewhere_as_part_of_the_activity?"
                  )}
                </h6>
                <div className="activity_info_list">
                  <ul>
                    <div className="radio_btn_transpoetantion">
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="customers_sleep_overnight"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label={t("_No")}
                            checked={
                              formData.customers_sleep_overnight === "no"
                                ? true
                                : false
                            }
                          />
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label={t("_Yes")}
                            checked={
                              formData.customers_sleep_overnight === "yes"
                                ? true
                                : false
                            }
                          />

                          {formData.customers_sleep_overnight == "yes" && (
                            <div className="product_activity_info">
                              <h6>{t("_Is_accommodation_included_in_the_price?")}</h6>
                              <div className="radio_btn_transpoetantion">
                                <FormControl>
                                  <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="accommodation_included_price"
                                    onChange={handleChange}
                                  >
                                    <FormControlLabel
                                      value="no"
                                      control={<Radio />}
                                      label={t("_No")}
                                      checked={
                                        formData.accommodation_included_price ===
                                          "no"
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormControlLabel
                                      value="yes"
                                      control={<Radio />}
                                      label={t("_Yes")}
                                      checked={
                                        formData.accommodation_included_price ===
                                          "yes"
                                          ? true
                                          : false
                                      }
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </div>
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </ul>
                </div>
              </div>

              <div className="btn-last-save">
                <button onClick={() => submit()} className="btn-all-save">
                  {t("_Save_&_continue")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default GuideActivityInfo;
