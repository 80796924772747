import { useEffect } from "react";
import { MetaData, images, successMsg } from "../../actions/customFn";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getMakeMoneyData } from "../../actions/affiliateAction";
import { t } from "i18next";
import LoadingBar from "../Loading/LoadingBar";
import Swal from "sweetalert2";
import { becomeAffiliate } from "../../actions/becomeAffiliateAction";

const BecomeAffiliate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const languageReducer = useSelector((state) => state.languageReducer);

  const [makeMoneyData, setMakeMoneyData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (languageReducer) {
      setIsLoading(true);
      dispatch(
        getMakeMoneyData(
          setMakeMoneyData,
          languageReducer,
          navigate,
          location,
          setIsLoading
        )
      );
    }
  }, [languageReducer]);

  const applyForAffiliate = () => {
    Swal.fire({
      title: "Are your sure ?",
      text: "You will become an affiliate user and your data as customer will be no longer available...!!!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(155 176 199)",
      cancelButtonColor: "rgb(227 46 46)",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed === true) {
        dispatch(becomeAffiliate(languageReducer, navigate))
      }
    });
  }

  let metaData = {
    title: t("_Make_Money")
  }

  return (
    <>
      {
        metaData &&
        <MetaData data={metaData} />
      }
      {
        makeMoneyData &&
        <div className="affiliate_profile_main">
          <div className="text_banner">
            <span>{t("_Make_Money")} </span>
          </div>
          {
            isLoading === true ?
              <div className="blinking-bar-loader">
                <LoadingBar />
              </div>
              :
              <div className="user_edit_main">
                <div className="input_all_edit">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="affiliate_money_hedding">
                        {
                          makeMoneyData.title &&
                          <h2>{makeMoneyData.title}</h2>
                        }
                        {
                          makeMoneyData.description &&
                          <p dangerouslySetInnerHTML={{ __html: makeMoneyData.description }}>
                          </p>
                        }
                      </div>

                      <div className="row">
                        {
                          makeMoneyData.middle_section !== undefined &&
                          makeMoneyData.middle_section.length > 0 &&
                          makeMoneyData.middle_section.map((value, index) => {
                            return (
                              <div
                                className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                key={index}
                              >
                                <div className="affiliate_money_box">
                                  <span>
                                    <img src={value.image} alt="" />
                                  </span>

                                  <p>{value.heading}</p>
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>

                  </div>
                  {/* <div className="row"> */}
                  <div className="search_header_main become-aff">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <button
                            className="btn_search become-affiliate-submit-btn"
                            onClick={() => applyForAffiliate()}
                          >
                            {t("_Become_Affiliate")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
          }
        </div>
      }
    </>
  );
};

export default BecomeAffiliate;
